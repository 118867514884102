import all from '../../img/all/*.png';

//const all = import.meta.glob('public/assets/images/all/*.png')

let items = document.querySelectorAll('.crafting__item')
let clickedItem

for (let i = 0; i < items.length; i++) {
    items[i].onclick = function () { craftingItem(items[i].firstElementChild.firstElementChild.id) }
}

let craftingItemsContainer = document.querySelector('.crafting__show--container--grid')
let craftingSlots = []
let craftedItem = document.getElementById('craftedItem')
for (let i = 0; i < craftingItemsContainer.children.length; i++) {
    for (let j = 0; j < craftingItemsContainer.children[i].children.length; j++) {
        craftingSlots[i] = craftingItemsContainer.children[i].children[j].firstChild
    }
}

let furnaceContainer = document.querySelector('.crafting__show--container--smelt')
let furnaceSlots = []
furnaceSlots[0] = furnaceContainer.children[0].children[0].firstChild
furnaceSlots[1] = furnaceContainer.children[2].children[0].firstChild


export function craftingItem(itemName) {

    switch (itemName) {
        //#region Shaped

        case "acacia_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.acacia_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.acacia_planks
            craftingSlots[6].src = all.acacia_planks
            craftingSlots[7].src = all.acacia_planks
            craftingSlots[8].src = all.acacia_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "acacia planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "acacia planks"
            craftingSlots[6].parentElement.parentElement.title = "acacia planks"
            craftingSlots[7].parentElement.parentElement.title = "acacia planks"
            craftingSlots[8].parentElement.parentElement.title = "acacia planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[6].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[7].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[8].parentElement.parentElement.alt = "acacia planks"
            break;
            
        case "acacia_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.acacia_planks
            craftingSlots[1].src = all.acacia_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.acacia_planks
            craftingSlots[4].src = all.acacia_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.acacia_planks
            craftingSlots[7].src = all.acacia_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "acacia planks"
            craftingSlots[1].parentElement.parentElement.title = "acacia planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "acacia planks"
            craftingSlots[4].parentElement.parentElement.title = "acacia planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "acacia planks"
            craftingSlots[7].parentElement.parentElement.title = "acacia planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[1].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[4].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[7].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "acacia_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.acacia_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.acacia_planks
            craftingSlots[6].src = all.acacia_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.acacia_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "acacia planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "acacia planks"
            craftingSlots[6].parentElement.parentElement.title = "acacia planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "acacia planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[6].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "acacia planks"
            break;
            
        case "acacia_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.acacia_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.acacia_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "acacia planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "acacia planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "acacia_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_acacia_log
            craftingSlots[4].src = all.stripped_acacia_log
            craftingSlots[5].src = all.stripped_acacia_log
            craftingSlots[6].src = all.stripped_acacia_log
            craftingSlots[7].src = all.stripped_acacia_log
            craftingSlots[8].src = all.stripped_acacia_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[4].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[5].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[6].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[7].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[8].parentElement.parentElement.title = "stripped acacia log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped acacia log"
            break;
            
        case "acacia_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.acacia_planks
            craftingSlots[7].src = all.acacia_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "acacia planks"
            craftingSlots[7].parentElement.parentElement.title = "acacia planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[7].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "acacia_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.acacia_planks
            craftingSlots[1].src = all.acacia_planks
            craftingSlots[2].src = all.acacia_planks
            craftingSlots[3].src = all.acacia_planks
            craftingSlots[4].src = all.acacia_planks
            craftingSlots[5].src = all.acacia_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "acacia planks"
            craftingSlots[1].parentElement.parentElement.title = "acacia planks"
            craftingSlots[2].parentElement.parentElement.title = "acacia planks"
            craftingSlots[3].parentElement.parentElement.title = "acacia planks"
            craftingSlots[4].parentElement.parentElement.title = "acacia planks"
            craftingSlots[5].parentElement.parentElement.title = "acacia planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[1].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[2].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[3].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[4].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[5].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "acacia_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.acacia_planks
            craftingSlots[7].src = all.acacia_planks
            craftingSlots[8].src = all.acacia_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "acacia planks"
            craftingSlots[7].parentElement.parentElement.title = "acacia planks"
            craftingSlots[8].parentElement.parentElement.title = "acacia planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[7].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[8].parentElement.parentElement.alt = "acacia planks"
            break;
            
        case "acacia_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.acacia_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.acacia_planks
            craftingSlots[4].src = all.acacia_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.acacia_planks
            craftingSlots[7].src = all.acacia_planks
            craftingSlots[8].src = all.acacia_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "acacia planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "acacia planks"
            craftingSlots[4].parentElement.parentElement.title = "acacia planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "acacia planks"
            craftingSlots[7].parentElement.parentElement.title = "acacia planks"
            craftingSlots[8].parentElement.parentElement.title = "acacia planks"

            craftingSlots[0].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[4].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[7].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[8].parentElement.parentElement.alt = "acacia planks"
            break;
            
        case "acacia_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.acacia_planks
            craftingSlots[4].src = all.acacia_planks
            craftingSlots[5].src = all.acacia_planks
            craftingSlots[6].src = all.acacia_planks
            craftingSlots[7].src = all.acacia_planks
            craftingSlots[8].src = all.acacia_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "acacia planks"
            craftingSlots[4].parentElement.parentElement.title = "acacia planks"
            craftingSlots[5].parentElement.parentElement.title = "acacia planks"
            craftingSlots[6].parentElement.parentElement.title = "acacia planks"
            craftingSlots[7].parentElement.parentElement.title = "acacia planks"
            craftingSlots[8].parentElement.parentElement.title = "acacia planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[4].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[5].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[6].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[7].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[8].parentElement.parentElement.alt = "acacia planks"
            break;
            
        case "acacia_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.acacia_log
            craftingSlots[4].src = all.acacia_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.acacia_log
            craftingSlots[7].src = all.acacia_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "acacia log"
            craftingSlots[4].parentElement.parentElement.title = "acacia log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "acacia log"
            craftingSlots[7].parentElement.parentElement.title = "acacia log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "acacia log"
            craftingSlots[4].parentElement.parentElement.alt = "acacia log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "acacia log"
            craftingSlots[7].parentElement.parentElement.alt = "acacia log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "activator_rail":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.stick
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.redstone_torch
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "stick"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "redstone torch"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "stick"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "redstone torch"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "amethyst_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.amethyst_shard
            craftingSlots[4].src = all.amethyst_shard
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.amethyst_shard
            craftingSlots[7].src = all.amethyst_shard
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[4].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[7].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[4].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[7].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "andesite_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.andesite
            craftingSlots[7].src = all.andesite
            craftingSlots[8].src = all.andesite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "andesite"
            craftingSlots[7].parentElement.parentElement.title = "andesite"
            craftingSlots[8].parentElement.parentElement.title = "andesite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "andesite"
            craftingSlots[7].parentElement.parentElement.alt = "andesite"
            craftingSlots[8].parentElement.parentElement.alt = "andesite"
            break;
            
        case "andesite_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.andesite
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.andesite
            craftingSlots[4].src = all.andesite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.andesite
            craftingSlots[7].src = all.andesite
            craftingSlots[8].src = all.andesite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "andesite"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "andesite"
            craftingSlots[4].parentElement.parentElement.title = "andesite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "andesite"
            craftingSlots[7].parentElement.parentElement.title = "andesite"
            craftingSlots[8].parentElement.parentElement.title = "andesite"

            craftingSlots[0].parentElement.parentElement.alt = "andesite"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "andesite"
            craftingSlots[4].parentElement.parentElement.alt = "andesite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "andesite"
            craftingSlots[7].parentElement.parentElement.alt = "andesite"
            craftingSlots[8].parentElement.parentElement.alt = "andesite"
            break;
            
        case "andesite_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.andesite
            craftingSlots[4].src = all.andesite
            craftingSlots[5].src = all.andesite
            craftingSlots[6].src = all.andesite
            craftingSlots[7].src = all.andesite
            craftingSlots[8].src = all.andesite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "andesite"
            craftingSlots[4].parentElement.parentElement.title = "andesite"
            craftingSlots[5].parentElement.parentElement.title = "andesite"
            craftingSlots[6].parentElement.parentElement.title = "andesite"
            craftingSlots[7].parentElement.parentElement.title = "andesite"
            craftingSlots[8].parentElement.parentElement.title = "andesite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "andesite"
            craftingSlots[4].parentElement.parentElement.alt = "andesite"
            craftingSlots[5].parentElement.parentElement.alt = "andesite"
            craftingSlots[6].parentElement.parentElement.alt = "andesite"
            craftingSlots[7].parentElement.parentElement.alt = "andesite"
            craftingSlots[8].parentElement.parentElement.alt = "andesite"
            break;
            
        case "anvil":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_block
            craftingSlots[1].src = all.iron_block
            craftingSlots[2].src = all.iron_block
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron block"
            craftingSlots[1].parentElement.parentElement.title = "iron block"
            craftingSlots[2].parentElement.parentElement.title = "iron block"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron block"
            craftingSlots[1].parentElement.parentElement.alt = "iron block"
            craftingSlots[2].parentElement.parentElement.alt = "iron block"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
                case "anvil":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "armor_stand":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.stick
            craftingSlots[1].src = all.stick
            craftingSlots[2].src = all.stick
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.smooth_stone_slab
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stick"
            craftingSlots[1].parentElement.parentElement.title = "stick"
            craftingSlots[2].parentElement.parentElement.title = "stick"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "smooth stone slab"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "stick"
            craftingSlots[1].parentElement.parentElement.alt = "stick"
            craftingSlots[2].parentElement.parentElement.alt = "stick"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "smooth stone slab"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "arrow":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.flint
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.feather
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "flint"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "feather"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "flint"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "feather"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bamboo_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bamboo_planks
            craftingSlots[1].src = all.bamboo_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo_planks
            craftingSlots[4].src = all.bamboo_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo_planks
            craftingSlots[7].src = all.bamboo_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[1].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[1].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bamboo_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.bamboo_planks
            craftingSlots[6].src = all.bamboo_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.bamboo_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "bamboo planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo planks"
            break;
            
        case "bamboo_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.bamboo_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.bamboo_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "bamboo_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_bamboo_block
            craftingSlots[4].src = all.stripped_bamboo_block
            craftingSlots[5].src = all.stripped_bamboo_block
            craftingSlots[6].src = all.stripped_bamboo_block
            craftingSlots[7].src = all.stripped_bamboo_block
            craftingSlots[8].src = all.stripped_bamboo_block
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped bamboo block"
            craftingSlots[4].parentElement.parentElement.title = "stripped bamboo block"
            craftingSlots[5].parentElement.parentElement.title = "stripped bamboo block"
            craftingSlots[6].parentElement.parentElement.title = "stripped bamboo block"
            craftingSlots[7].parentElement.parentElement.title = "stripped bamboo block"
            craftingSlots[8].parentElement.parentElement.title = "stripped bamboo block"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped bamboo block"
            craftingSlots[4].parentElement.parentElement.alt = "stripped bamboo block"
            craftingSlots[5].parentElement.parentElement.alt = "stripped bamboo block"
            craftingSlots[6].parentElement.parentElement.alt = "stripped bamboo block"
            craftingSlots[7].parentElement.parentElement.alt = "stripped bamboo block"
            craftingSlots[8].parentElement.parentElement.alt = "stripped bamboo block"
            break;
            
        case "bamboo_mosaic":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bamboo_mosaic_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo_mosaic
            craftingSlots[7].src = all.bamboo_mosaic
            craftingSlots[8].src = all.bamboo_mosaic
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo mosaic"
            craftingSlots[7].parentElement.parentElement.title = "bamboo mosaic"
            craftingSlots[8].parentElement.parentElement.title = "bamboo mosaic"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo mosaic"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo mosaic"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo mosaic"
            break;
            
        case "bamboo_mosaic_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bamboo_mosaic
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo_mosaic
            craftingSlots[4].src = all.bamboo_mosaic
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo_mosaic
            craftingSlots[7].src = all.bamboo_mosaic
            craftingSlots[8].src = all.bamboo_mosaic
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bamboo mosaic"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo mosaic"
            craftingSlots[4].parentElement.parentElement.title = "bamboo mosaic"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo mosaic"
            craftingSlots[7].parentElement.parentElement.title = "bamboo mosaic"
            craftingSlots[8].parentElement.parentElement.title = "bamboo mosaic"

            craftingSlots[0].parentElement.parentElement.alt = "bamboo mosaic"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo mosaic"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo mosaic"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo mosaic"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo mosaic"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo mosaic"
            break;
            
        case "bamboo_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo_planks
            craftingSlots[7].src = all.bamboo_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bamboo_raft":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.bamboo_planks
            craftingSlots[6].src = all.bamboo_planks
            craftingSlots[7].src = all.bamboo_planks
            craftingSlots[8].src = all.bamboo_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.title = "bamboo planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo planks"
            break;
            
        case "bamboo_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bamboo_planks
            craftingSlots[1].src = all.bamboo_planks
            craftingSlots[2].src = all.bamboo_planks
            craftingSlots[3].src = all.bamboo_planks
            craftingSlots[4].src = all.bamboo_planks
            craftingSlots[5].src = all.bamboo_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[1].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[2].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[3].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[1].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[2].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bamboo_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo_planks
            craftingSlots[7].src = all.bamboo_planks
            craftingSlots[8].src = all.bamboo_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.title = "bamboo planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo planks"
            break;
            
        case "bamboo_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bamboo_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo_planks
            craftingSlots[4].src = all.bamboo_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo_planks
            craftingSlots[7].src = all.bamboo_planks
            craftingSlots[8].src = all.bamboo_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.title = "bamboo planks"

            craftingSlots[0].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo planks"
            break;
            
        case "bamboo_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo_planks
            craftingSlots[4].src = all.bamboo_planks
            craftingSlots[5].src = all.bamboo_planks
            craftingSlots[6].src = all.bamboo_planks
            craftingSlots[7].src = all.bamboo_planks
            craftingSlots[8].src = all.bamboo_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.title = "bamboo planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo planks"
            break;
            
                case "bamboo_trapdoor":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "barrel":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_slab
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_slab
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "beacon":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.nether_star
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.obsidian
            craftingSlots[7].src = all.obsidian
            craftingSlots[8].src = all.obsidian
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "nether star"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "obsidian"
            craftingSlots[7].parentElement.parentElement.title = "obsidian"
            craftingSlots[8].parentElement.parentElement.title = "obsidian"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "nether star"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "obsidian"
            craftingSlots[7].parentElement.parentElement.alt = "obsidian"
            craftingSlots[8].parentElement.parentElement.alt = "obsidian"
            break;
            
        case "beehive":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.honeycomb
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.honeycomb
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "honeycomb"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "honeycomb"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "birch_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.birch_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.birch_planks
            craftingSlots[6].src = all.birch_planks
            craftingSlots[7].src = all.birch_planks
            craftingSlots[8].src = all.birch_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "birch planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "birch planks"
            craftingSlots[6].parentElement.parentElement.title = "birch planks"
            craftingSlots[7].parentElement.parentElement.title = "birch planks"
            craftingSlots[8].parentElement.parentElement.title = "birch planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "birch planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "birch planks"
            craftingSlots[6].parentElement.parentElement.alt = "birch planks"
            craftingSlots[7].parentElement.parentElement.alt = "birch planks"
            craftingSlots[8].parentElement.parentElement.alt = "birch planks"
            break;
            
        case "birch_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.birch_planks
            craftingSlots[1].src = all.birch_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.birch_planks
            craftingSlots[4].src = all.birch_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.birch_planks
            craftingSlots[7].src = all.birch_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "birch planks"
            craftingSlots[1].parentElement.parentElement.title = "birch planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "birch planks"
            craftingSlots[4].parentElement.parentElement.title = "birch planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "birch planks"
            craftingSlots[7].parentElement.parentElement.title = "birch planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "birch planks"
            craftingSlots[1].parentElement.parentElement.alt = "birch planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "birch planks"
            craftingSlots[4].parentElement.parentElement.alt = "birch planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "birch planks"
            craftingSlots[7].parentElement.parentElement.alt = "birch planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "birch_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.birch_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.birch_planks
            craftingSlots[6].src = all.birch_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.birch_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "birch planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "birch planks"
            craftingSlots[6].parentElement.parentElement.title = "birch planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "birch planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "birch planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "birch planks"
            craftingSlots[6].parentElement.parentElement.alt = "birch planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "birch planks"
            break;
            
        case "birch_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.birch_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.birch_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "birch planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "birch planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "birch planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "birch planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "birch_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_birch_log
            craftingSlots[4].src = all.stripped_birch_log
            craftingSlots[5].src = all.stripped_birch_log
            craftingSlots[6].src = all.stripped_birch_log
            craftingSlots[7].src = all.stripped_birch_log
            craftingSlots[8].src = all.stripped_birch_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[4].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[5].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[6].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[7].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[8].parentElement.parentElement.title = "stripped birch log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped birch log"
            break;
            
        case "birch_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.birch_planks
            craftingSlots[7].src = all.birch_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "birch planks"
            craftingSlots[7].parentElement.parentElement.title = "birch planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "birch planks"
            craftingSlots[7].parentElement.parentElement.alt = "birch planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "birch_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.birch_planks
            craftingSlots[1].src = all.birch_planks
            craftingSlots[2].src = all.birch_planks
            craftingSlots[3].src = all.birch_planks
            craftingSlots[4].src = all.birch_planks
            craftingSlots[5].src = all.birch_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "birch planks"
            craftingSlots[1].parentElement.parentElement.title = "birch planks"
            craftingSlots[2].parentElement.parentElement.title = "birch planks"
            craftingSlots[3].parentElement.parentElement.title = "birch planks"
            craftingSlots[4].parentElement.parentElement.title = "birch planks"
            craftingSlots[5].parentElement.parentElement.title = "birch planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "birch planks"
            craftingSlots[1].parentElement.parentElement.alt = "birch planks"
            craftingSlots[2].parentElement.parentElement.alt = "birch planks"
            craftingSlots[3].parentElement.parentElement.alt = "birch planks"
            craftingSlots[4].parentElement.parentElement.alt = "birch planks"
            craftingSlots[5].parentElement.parentElement.alt = "birch planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "birch_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.birch_planks
            craftingSlots[7].src = all.birch_planks
            craftingSlots[8].src = all.birch_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "birch planks"
            craftingSlots[7].parentElement.parentElement.title = "birch planks"
            craftingSlots[8].parentElement.parentElement.title = "birch planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "birch planks"
            craftingSlots[7].parentElement.parentElement.alt = "birch planks"
            craftingSlots[8].parentElement.parentElement.alt = "birch planks"
            break;
            
        case "birch_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.birch_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.birch_planks
            craftingSlots[4].src = all.birch_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.birch_planks
            craftingSlots[7].src = all.birch_planks
            craftingSlots[8].src = all.birch_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "birch planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "birch planks"
            craftingSlots[4].parentElement.parentElement.title = "birch planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "birch planks"
            craftingSlots[7].parentElement.parentElement.title = "birch planks"
            craftingSlots[8].parentElement.parentElement.title = "birch planks"

            craftingSlots[0].parentElement.parentElement.alt = "birch planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "birch planks"
            craftingSlots[4].parentElement.parentElement.alt = "birch planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "birch planks"
            craftingSlots[7].parentElement.parentElement.alt = "birch planks"
            craftingSlots[8].parentElement.parentElement.alt = "birch planks"
            break;
            
        case "birch_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.birch_planks
            craftingSlots[4].src = all.birch_planks
            craftingSlots[5].src = all.birch_planks
            craftingSlots[6].src = all.birch_planks
            craftingSlots[7].src = all.birch_planks
            craftingSlots[8].src = all.birch_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "birch planks"
            craftingSlots[4].parentElement.parentElement.title = "birch planks"
            craftingSlots[5].parentElement.parentElement.title = "birch planks"
            craftingSlots[6].parentElement.parentElement.title = "birch planks"
            craftingSlots[7].parentElement.parentElement.title = "birch planks"
            craftingSlots[8].parentElement.parentElement.title = "birch planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "birch planks"
            craftingSlots[4].parentElement.parentElement.alt = "birch planks"
            craftingSlots[5].parentElement.parentElement.alt = "birch planks"
            craftingSlots[6].parentElement.parentElement.alt = "birch planks"
            craftingSlots[7].parentElement.parentElement.alt = "birch planks"
            craftingSlots[8].parentElement.parentElement.alt = "birch planks"
            break;
            
        case "birch_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.birch_log
            craftingSlots[4].src = all.birch_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.birch_log
            craftingSlots[7].src = all.birch_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "birch log"
            craftingSlots[4].parentElement.parentElement.title = "birch log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "birch log"
            craftingSlots[7].parentElement.parentElement.title = "birch log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "birch log"
            craftingSlots[4].parentElement.parentElement.alt = "birch log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "birch log"
            craftingSlots[7].parentElement.parentElement.alt = "birch log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.black_wool
            craftingSlots[1].src = all.black_wool
            craftingSlots[2].src = all.black_wool
            craftingSlots[3].src = all.black_wool
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.black_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "black wool"
            craftingSlots[1].parentElement.parentElement.title = "black wool"
            craftingSlots[2].parentElement.parentElement.title = "black wool"
            craftingSlots[3].parentElement.parentElement.title = "black wool"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "black wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "black wool"
            craftingSlots[1].parentElement.parentElement.alt = "black wool"
            craftingSlots[2].parentElement.parentElement.alt = "black wool"
            craftingSlots[3].parentElement.parentElement.alt = "black wool"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "black wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.black_wool
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.black_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "black wool"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "black wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "black wool"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "black wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "black_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.black_wool
            craftingSlots[7].src = all.black_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "black wool"
            craftingSlots[7].parentElement.parentElement.title = "black wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "black wool"
            craftingSlots[7].parentElement.parentElement.alt = "black wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.black_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "black dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "black dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "black_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.black_stained_glass
            craftingSlots[4].src = all.black_stained_glass
            craftingSlots[5].src = all.black_stained_glass
            craftingSlots[6].src = all.black_stained_glass
            craftingSlots[7].src = all.black_stained_glass
            craftingSlots[8].src = all.black_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "black stained glass"
            craftingSlots[4].parentElement.parentElement.title = "black stained glass"
            craftingSlots[5].parentElement.parentElement.title = "black stained glass"
            craftingSlots[6].parentElement.parentElement.title = "black stained glass"
            craftingSlots[7].parentElement.parentElement.title = "black stained glass"
            craftingSlots[8].parentElement.parentElement.title = "black stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "black stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "black stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "black stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "black stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "black stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "black stained glass"
            break;
            
        case "black_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.black_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "black dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "black dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "black_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.black_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "black dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "black dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "blackstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.blackstone
            craftingSlots[7].src = all.blackstone
            craftingSlots[8].src = all.blackstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "blackstone"
            craftingSlots[7].parentElement.parentElement.title = "blackstone"
            craftingSlots[8].parentElement.parentElement.title = "blackstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "blackstone"
            break;
            
        case "blackstone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.blackstone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blackstone
            craftingSlots[4].src = all.blackstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.blackstone
            craftingSlots[7].src = all.blackstone
            craftingSlots[8].src = all.blackstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "blackstone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blackstone"
            craftingSlots[4].parentElement.parentElement.title = "blackstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "blackstone"
            craftingSlots[7].parentElement.parentElement.title = "blackstone"
            craftingSlots[8].parentElement.parentElement.title = "blackstone"

            craftingSlots[0].parentElement.parentElement.alt = "blackstone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blackstone"
            craftingSlots[4].parentElement.parentElement.alt = "blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "blackstone"
            break;
            
        case "blackstone_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blackstone
            craftingSlots[4].src = all.blackstone
            craftingSlots[5].src = all.blackstone
            craftingSlots[6].src = all.blackstone
            craftingSlots[7].src = all.blackstone
            craftingSlots[8].src = all.blackstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blackstone"
            craftingSlots[4].parentElement.parentElement.title = "blackstone"
            craftingSlots[5].parentElement.parentElement.title = "blackstone"
            craftingSlots[6].parentElement.parentElement.title = "blackstone"
            craftingSlots[7].parentElement.parentElement.title = "blackstone"
            craftingSlots[8].parentElement.parentElement.title = "blackstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blackstone"
            craftingSlots[4].parentElement.parentElement.alt = "blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "blackstone"
            craftingSlots[6].parentElement.parentElement.alt = "blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "blackstone"
            break;
            
        case "blast_furnace":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.furnace
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.smooth_stone
            craftingSlots[7].src = all.smooth_stone
            craftingSlots[8].src = all.smooth_stone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "furnace"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "smooth stone"
            craftingSlots[7].parentElement.parentElement.title = "smooth stone"
            craftingSlots[8].parentElement.parentElement.title = "smooth stone"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "furnace"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "smooth stone"
            craftingSlots[7].parentElement.parentElement.alt = "smooth stone"
            craftingSlots[8].parentElement.parentElement.alt = "smooth stone"
            break;
            
        case "blue_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.blue_wool
            craftingSlots[1].src = all.blue_wool
            craftingSlots[2].src = all.blue_wool
            craftingSlots[3].src = all.blue_wool
            craftingSlots[4].src = all.blue_wool
            craftingSlots[5].src = all.blue_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "blue wool"
            craftingSlots[1].parentElement.parentElement.title = "blue wool"
            craftingSlots[2].parentElement.parentElement.title = "blue wool"
            craftingSlots[3].parentElement.parentElement.title = "blue wool"
            craftingSlots[4].parentElement.parentElement.title = "blue wool"
            craftingSlots[5].parentElement.parentElement.title = "blue wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "blue wool"
            craftingSlots[1].parentElement.parentElement.alt = "blue wool"
            craftingSlots[2].parentElement.parentElement.alt = "blue wool"
            craftingSlots[3].parentElement.parentElement.alt = "blue wool"
            craftingSlots[4].parentElement.parentElement.alt = "blue wool"
            craftingSlots[5].parentElement.parentElement.alt = "blue wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_wool
            craftingSlots[4].src = all.blue_wool
            craftingSlots[5].src = all.blue_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue wool"
            craftingSlots[4].parentElement.parentElement.title = "blue wool"
            craftingSlots[5].parentElement.parentElement.title = "blue wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue wool"
            craftingSlots[4].parentElement.parentElement.alt = "blue wool"
            craftingSlots[5].parentElement.parentElement.alt = "blue wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "blue_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.blue_wool
            craftingSlots[7].src = all.blue_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "blue wool"
            craftingSlots[7].parentElement.parentElement.title = "blue wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "blue wool"
            craftingSlots[7].parentElement.parentElement.alt = "blue wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.blue_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "blue dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "blue_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_stained_glass
            craftingSlots[4].src = all.blue_stained_glass
            craftingSlots[5].src = all.blue_stained_glass
            craftingSlots[6].src = all.blue_stained_glass
            craftingSlots[7].src = all.blue_stained_glass
            craftingSlots[8].src = all.blue_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue stained glass"
            craftingSlots[4].parentElement.parentElement.title = "blue stained glass"
            craftingSlots[5].parentElement.parentElement.title = "blue stained glass"
            craftingSlots[6].parentElement.parentElement.title = "blue stained glass"
            craftingSlots[7].parentElement.parentElement.title = "blue stained glass"
            craftingSlots[8].parentElement.parentElement.title = "blue stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "blue stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "blue stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "blue stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "blue stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "blue stained glass"
            break;
            
        case "blue_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.blue_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "blue dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "blue_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.blue_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "blue dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "bolt_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.bolt_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.copper_block
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "bolt armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "copper block"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "bolt armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "copper block"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "bolt_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "bone_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bone_meal
            craftingSlots[1].src = all.bone_meal
            craftingSlots[2].src = all.bone_meal
            craftingSlots[3].src = all.bone_meal
            craftingSlots[4].src = all.bone_meal
            craftingSlots[5].src = all.bone_meal
            craftingSlots[6].src = all.bone_meal
            craftingSlots[7].src = all.bone_meal
            craftingSlots[8].src = all.bone_meal
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bone meal"
            craftingSlots[1].parentElement.parentElement.title = "bone meal"
            craftingSlots[2].parentElement.parentElement.title = "bone meal"
            craftingSlots[3].parentElement.parentElement.title = "bone meal"
            craftingSlots[4].parentElement.parentElement.title = "bone meal"
            craftingSlots[5].parentElement.parentElement.title = "bone meal"
            craftingSlots[6].parentElement.parentElement.title = "bone meal"
            craftingSlots[7].parentElement.parentElement.title = "bone meal"
            craftingSlots[8].parentElement.parentElement.title = "bone meal"

            craftingSlots[0].parentElement.parentElement.alt = "bone meal"
            craftingSlots[1].parentElement.parentElement.alt = "bone meal"
            craftingSlots[2].parentElement.parentElement.alt = "bone meal"
            craftingSlots[3].parentElement.parentElement.alt = "bone meal"
            craftingSlots[4].parentElement.parentElement.alt = "bone meal"
            craftingSlots[5].parentElement.parentElement.alt = "bone meal"
            craftingSlots[6].parentElement.parentElement.alt = "bone meal"
            craftingSlots[7].parentElement.parentElement.alt = "bone meal"
            craftingSlots[8].parentElement.parentElement.alt = "bone meal"
            break;
                    
        case "bookshelf":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.book
            craftingSlots[4].src = all.book
            craftingSlots[5].src = all.book
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "book"
            craftingSlots[4].parentElement.parentElement.title = "book"
            craftingSlots[5].parentElement.parentElement.title = "book"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "book"
            craftingSlots[4].parentElement.parentElement.alt = "book"
            craftingSlots[5].parentElement.parentElement.alt = "book"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "bow":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.stick
            craftingSlots[2].src = all.string
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.string
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.string
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "stick"
            craftingSlots[2].parentElement.parentElement.title = "string"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "string"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "string"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "stick"
            craftingSlots[2].parentElement.parentElement.alt = "string"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "string"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "string"
            break;
            
        case "bowl":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bread":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.wheat
            craftingSlots[7].src = all.wheat
            craftingSlots[8].src = all.wheat
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "wheat"
            craftingSlots[7].parentElement.parentElement.title = "wheat"
            craftingSlots[8].parentElement.parentElement.title = "wheat"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "wheat"
            craftingSlots[7].parentElement.parentElement.alt = "wheat"
            craftingSlots[8].parentElement.parentElement.alt = "wheat"
            break;
            
        case "brewing_stand":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.cobblestone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[7].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[8].parentElement.parentElement.title = "stone crafting materials"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[7].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[8].parentElement.parentElement.alt = "stone crafting materials"
            break;
            
        case "brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bricks
            craftingSlots[7].src = all.bricks
            craftingSlots[8].src = all.bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bricks"
            craftingSlots[7].parentElement.parentElement.title = "bricks"
            craftingSlots[8].parentElement.parentElement.title = "bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bricks"
            craftingSlots[7].parentElement.parentElement.alt = "bricks"
            craftingSlots[8].parentElement.parentElement.alt = "bricks"
            break;
            
        case "brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bricks
            craftingSlots[4].src = all.bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bricks
            craftingSlots[7].src = all.bricks
            craftingSlots[8].src = all.bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bricks"
            craftingSlots[4].parentElement.parentElement.title = "bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bricks"
            craftingSlots[7].parentElement.parentElement.title = "bricks"
            craftingSlots[8].parentElement.parentElement.title = "bricks"

            craftingSlots[0].parentElement.parentElement.alt = "bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bricks"
            craftingSlots[4].parentElement.parentElement.alt = "bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bricks"
            craftingSlots[7].parentElement.parentElement.alt = "bricks"
            craftingSlots[8].parentElement.parentElement.alt = "bricks"
            break;
            
        case "brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bricks
            craftingSlots[4].src = all.bricks
            craftingSlots[5].src = all.bricks
            craftingSlots[6].src = all.bricks
            craftingSlots[7].src = all.bricks
            craftingSlots[8].src = all.bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bricks"
            craftingSlots[4].parentElement.parentElement.title = "bricks"
            craftingSlots[5].parentElement.parentElement.title = "bricks"
            craftingSlots[6].parentElement.parentElement.title = "bricks"
            craftingSlots[7].parentElement.parentElement.title = "bricks"
            craftingSlots[8].parentElement.parentElement.title = "bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bricks"
            craftingSlots[4].parentElement.parentElement.alt = "bricks"
            craftingSlots[5].parentElement.parentElement.alt = "bricks"
            craftingSlots[6].parentElement.parentElement.alt = "bricks"
            craftingSlots[7].parentElement.parentElement.alt = "bricks"
            craftingSlots[8].parentElement.parentElement.alt = "bricks"
            break;
            
        case "bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brick
            craftingSlots[4].src = all.brick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.brick
            craftingSlots[7].src = all.brick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brick"
            craftingSlots[4].parentElement.parentElement.title = "brick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "brick"
            craftingSlots[7].parentElement.parentElement.title = "brick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brick"
            craftingSlots[4].parentElement.parentElement.alt = "brick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "brick"
            craftingSlots[7].parentElement.parentElement.alt = "brick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "brown_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.brown_wool
            craftingSlots[1].src = all.brown_wool
            craftingSlots[2].src = all.brown_wool
            craftingSlots[3].src = all.brown_wool
            craftingSlots[4].src = all.brown_wool
            craftingSlots[5].src = all.brown_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "brown wool"
            craftingSlots[1].parentElement.parentElement.title = "brown wool"
            craftingSlots[2].parentElement.parentElement.title = "brown wool"
            craftingSlots[3].parentElement.parentElement.title = "brown wool"
            craftingSlots[4].parentElement.parentElement.title = "brown wool"
            craftingSlots[5].parentElement.parentElement.title = "brown wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "brown wool"
            craftingSlots[1].parentElement.parentElement.alt = "brown wool"
            craftingSlots[2].parentElement.parentElement.alt = "brown wool"
            craftingSlots[3].parentElement.parentElement.alt = "brown wool"
            craftingSlots[4].parentElement.parentElement.alt = "brown wool"
            craftingSlots[5].parentElement.parentElement.alt = "brown wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "brown_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brown_wool
            craftingSlots[4].src = all.brown_wool
            craftingSlots[5].src = all.brown_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brown wool"
            craftingSlots[4].parentElement.parentElement.title = "brown wool"
            craftingSlots[5].parentElement.parentElement.title = "brown wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brown wool"
            craftingSlots[4].parentElement.parentElement.alt = "brown wool"
            craftingSlots[5].parentElement.parentElement.alt = "brown wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "brown_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.brown_wool
            craftingSlots[7].src = all.brown_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "brown wool"
            craftingSlots[7].parentElement.parentElement.title = "brown wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "brown wool"
            craftingSlots[7].parentElement.parentElement.alt = "brown wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "brown_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.brown_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "brown dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "brown dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "brown_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brown_stained_glass
            craftingSlots[4].src = all.brown_stained_glass
            craftingSlots[5].src = all.brown_stained_glass
            craftingSlots[6].src = all.brown_stained_glass
            craftingSlots[7].src = all.brown_stained_glass
            craftingSlots[8].src = all.brown_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brown stained glass"
            craftingSlots[4].parentElement.parentElement.title = "brown stained glass"
            craftingSlots[5].parentElement.parentElement.title = "brown stained glass"
            craftingSlots[6].parentElement.parentElement.title = "brown stained glass"
            craftingSlots[7].parentElement.parentElement.title = "brown stained glass"
            craftingSlots[8].parentElement.parentElement.title = "brown stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brown stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "brown stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "brown stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "brown stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "brown stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "brown stained glass"
            break;
            
        case "brown_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.brown_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "brown dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "brown dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "brown_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.brown_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "brown dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "brown dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "brush":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.feather
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "feather"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "feather"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bucket":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cake":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.milk_bucket
            craftingSlots[1].src = all.milk_bucket
            craftingSlots[2].src = all.milk_bucket
            craftingSlots[3].src = all.sugar
            craftingSlots[4].src = all.egg
            craftingSlots[5].src = all.sugar
            craftingSlots[6].src = all.wheat
            craftingSlots[7].src = all.wheat
            craftingSlots[8].src = all.wheat
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "milk bucket"
            craftingSlots[1].parentElement.parentElement.title = "milk bucket"
            craftingSlots[2].parentElement.parentElement.title = "milk bucket"
            craftingSlots[3].parentElement.parentElement.title = "sugar"
            craftingSlots[4].parentElement.parentElement.title = "egg"
            craftingSlots[5].parentElement.parentElement.title = "sugar"
            craftingSlots[6].parentElement.parentElement.title = "wheat"
            craftingSlots[7].parentElement.parentElement.title = "wheat"
            craftingSlots[8].parentElement.parentElement.title = "wheat"

            craftingSlots[0].parentElement.parentElement.alt = "milk bucket"
            craftingSlots[1].parentElement.parentElement.alt = "milk bucket"
            craftingSlots[2].parentElement.parentElement.alt = "milk bucket"
            craftingSlots[3].parentElement.parentElement.alt = "sugar"
            craftingSlots[4].parentElement.parentElement.alt = "egg"
            craftingSlots[5].parentElement.parentElement.alt = "sugar"
            craftingSlots[6].parentElement.parentElement.alt = "wheat"
            craftingSlots[7].parentElement.parentElement.alt = "wheat"
            craftingSlots[8].parentElement.parentElement.alt = "wheat"
            break;
            
        case "calibrated_sculk_sensor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.amethyst_shard
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.amethyst_shard
            craftingSlots[7].src = all.sculk_sensor
            craftingSlots[8].src = all.amethyst_shard
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[7].parentElement.parentElement.title = "sculk sensor"
            craftingSlots[8].parentElement.parentElement.title = "amethyst shard"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[7].parentElement.parentElement.alt = "sculk sensor"
            craftingSlots[8].parentElement.parentElement.alt = "amethyst shard"
            break;
            
        case "campfire":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.stick
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.coals
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.oak_log
            craftingSlots[7].src = all.oak_log
            craftingSlots[8].src = all.oak_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "stick"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "coals"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "oak_log"
            craftingSlots[7].parentElement.parentElement.title = "oak_log"
            craftingSlots[8].parentElement.parentElement.title = "oak_log"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "stick"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "coals"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "oak_log"
            craftingSlots[7].parentElement.parentElement.alt = "oak_log"
            craftingSlots[8].parentElement.parentElement.alt = "oak_log"
            break;
            
        case "candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.string
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.honeycomb
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "string"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "honeycomb"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "string"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "carrot_on_a_stick":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.fishing_rod
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.carrot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "fishing rod"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "carrot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "fishing rod"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "carrot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cartography_table":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.paper
            craftingSlots[1].src = all.paper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "paper"
            craftingSlots[1].parentElement.parentElement.title = "paper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "paper"
            craftingSlots[1].parentElement.parentElement.alt = "paper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cauldron":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "chain":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_nugget
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.iron_nugget
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron nugget"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "iron nugget"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cherry_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cherry_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.cherry_planks
            craftingSlots[6].src = all.cherry_planks
            craftingSlots[7].src = all.cherry_planks
            craftingSlots[8].src = all.cherry_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cherry planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "cherry planks"
            craftingSlots[6].parentElement.parentElement.title = "cherry planks"
            craftingSlots[7].parentElement.parentElement.title = "cherry planks"
            craftingSlots[8].parentElement.parentElement.title = "cherry planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[6].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[7].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[8].parentElement.parentElement.alt = "cherry planks"
            break;
            
        case "cherry_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cherry_planks
            craftingSlots[1].src = all.cherry_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cherry_planks
            craftingSlots[4].src = all.cherry_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cherry_planks
            craftingSlots[7].src = all.cherry_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cherry planks"
            craftingSlots[1].parentElement.parentElement.title = "cherry planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cherry planks"
            craftingSlots[4].parentElement.parentElement.title = "cherry planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cherry planks"
            craftingSlots[7].parentElement.parentElement.title = "cherry planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[1].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[4].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[7].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cherry_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cherry_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.cherry_planks
            craftingSlots[6].src = all.cherry_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.cherry_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cherry planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "cherry planks"
            craftingSlots[6].parentElement.parentElement.title = "cherry planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "cherry planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[6].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "cherry planks"
            break;
            
        case "cherry_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.cherry_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.cherry_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "cherry planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "cherry planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "cherry_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_cherry_log
            craftingSlots[4].src = all.stripped_cherry_log
            craftingSlots[5].src = all.stripped_cherry_log
            craftingSlots[6].src = all.stripped_cherry_log
            craftingSlots[7].src = all.stripped_cherry_log
            craftingSlots[8].src = all.stripped_cherry_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[4].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[5].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[6].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[7].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[8].parentElement.parentElement.title = "stripped cherry log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped cherry log"
            break;
            
        case "cherry_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cherry_planks
            craftingSlots[7].src = all.cherry_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cherry planks"
            craftingSlots[7].parentElement.parentElement.title = "cherry planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[7].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cherry_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cherry_planks
            craftingSlots[1].src = all.cherry_planks
            craftingSlots[2].src = all.cherry_planks
            craftingSlots[3].src = all.cherry_planks
            craftingSlots[4].src = all.cherry_planks
            craftingSlots[5].src = all.cherry_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cherry planks"
            craftingSlots[1].parentElement.parentElement.title = "cherry planks"
            craftingSlots[2].parentElement.parentElement.title = "cherry planks"
            craftingSlots[3].parentElement.parentElement.title = "cherry planks"
            craftingSlots[4].parentElement.parentElement.title = "cherry planks"
            craftingSlots[5].parentElement.parentElement.title = "cherry planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[1].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[2].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[3].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[4].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[5].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cherry_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cherry_planks
            craftingSlots[7].src = all.cherry_planks
            craftingSlots[8].src = all.cherry_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cherry planks"
            craftingSlots[7].parentElement.parentElement.title = "cherry planks"
            craftingSlots[8].parentElement.parentElement.title = "cherry planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[7].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[8].parentElement.parentElement.alt = "cherry planks"
            break;
            
        case "cherry_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cherry_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cherry_planks
            craftingSlots[4].src = all.cherry_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cherry_planks
            craftingSlots[7].src = all.cherry_planks
            craftingSlots[8].src = all.cherry_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cherry planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cherry planks"
            craftingSlots[4].parentElement.parentElement.title = "cherry planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cherry planks"
            craftingSlots[7].parentElement.parentElement.title = "cherry planks"
            craftingSlots[8].parentElement.parentElement.title = "cherry planks"

            craftingSlots[0].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[4].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[7].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[8].parentElement.parentElement.alt = "cherry planks"
            break;
            
        case "cherry_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cherry_planks
            craftingSlots[4].src = all.cherry_planks
            craftingSlots[5].src = all.cherry_planks
            craftingSlots[6].src = all.cherry_planks
            craftingSlots[7].src = all.cherry_planks
            craftingSlots[8].src = all.cherry_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cherry planks"
            craftingSlots[4].parentElement.parentElement.title = "cherry planks"
            craftingSlots[5].parentElement.parentElement.title = "cherry planks"
            craftingSlots[6].parentElement.parentElement.title = "cherry planks"
            craftingSlots[7].parentElement.parentElement.title = "cherry planks"
            craftingSlots[8].parentElement.parentElement.title = "cherry planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[4].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[5].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[6].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[7].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[8].parentElement.parentElement.alt = "cherry planks"
            break;
            
        case "cherry_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cherry_log
            craftingSlots[4].src = all.cherry_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cherry_log
            craftingSlots[7].src = all.cherry_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cherry log"
            craftingSlots[4].parentElement.parentElement.title = "cherry log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cherry log"
            craftingSlots[7].parentElement.parentElement.title = "cherry log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cherry log"
            craftingSlots[4].parentElement.parentElement.alt = "cherry log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cherry log"
            craftingSlots[7].parentElement.parentElement.alt = "cherry log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chest":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "chiseled_bookshelf":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.oak_slab
            craftingSlots[4].src = all.oak_slab
            craftingSlots[5].src = all.oak_slab
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[4].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[5].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[4].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[5].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_deepslate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobbled_deepslate_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobbled_deepslate_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobbled deepslate slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cobbled deepslate slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobbled deepslate slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cobbled deepslate slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_nether_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.nether_brick_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.nether_brick_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "nether brick slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "nether brick slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "nether brick slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "nether brick slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_polished_blackstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_blackstone_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_blackstone_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished blackstone slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished blackstone slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_quartz_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.quartz_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.quartz_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "quartz slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "quartz slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "quartz slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "quartz slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_red_sandstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_sandstone_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_sandstone_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red sandstone slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red sandstone slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red sandstone slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red sandstone slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_sandstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.sandstone_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.sandstone_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "sandstone slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "sandstone slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "sandstone slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "sandstone slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_stone_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stone_brick_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone_brick_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stone brick slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone brick slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stone brick slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone brick slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_tuff":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tuff_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.tuff_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tuff slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "tuff slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tuff slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "tuff slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chiseled_tuff_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tuff_brick_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.tuff_brick_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tuff brick slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "tuff brick slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tuff brick slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "tuff brick slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "clay":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.clay_ball
            craftingSlots[4].src = all.clay_ball
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.clay_ball
            craftingSlots[7].src = all.clay_ball
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "clay ball"
            craftingSlots[4].parentElement.parentElement.title = "clay ball"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "clay ball"
            craftingSlots[7].parentElement.parentElement.title = "clay ball"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "clay ball"
            craftingSlots[4].parentElement.parentElement.alt = "clay ball"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "clay ball"
            craftingSlots[7].parentElement.parentElement.alt = "clay ball"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "clock":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.gold_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.redstone
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.gold_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "gold ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "redstone"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "gold ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "redstone"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "coal_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.coal
            craftingSlots[1].src = all.coal
            craftingSlots[2].src = all.coal
            craftingSlots[3].src = all.coal
            craftingSlots[4].src = all.coal
            craftingSlots[5].src = all.coal
            craftingSlots[6].src = all.coal
            craftingSlots[7].src = all.coal
            craftingSlots[8].src = all.coal
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "coal"
            craftingSlots[1].parentElement.parentElement.title = "coal"
            craftingSlots[2].parentElement.parentElement.title = "coal"
            craftingSlots[3].parentElement.parentElement.title = "coal"
            craftingSlots[4].parentElement.parentElement.title = "coal"
            craftingSlots[5].parentElement.parentElement.title = "coal"
            craftingSlots[6].parentElement.parentElement.title = "coal"
            craftingSlots[7].parentElement.parentElement.title = "coal"
            craftingSlots[8].parentElement.parentElement.title = "coal"

            craftingSlots[0].parentElement.parentElement.alt = "coal"
            craftingSlots[1].parentElement.parentElement.alt = "coal"
            craftingSlots[2].parentElement.parentElement.alt = "coal"
            craftingSlots[3].parentElement.parentElement.alt = "coal"
            craftingSlots[4].parentElement.parentElement.alt = "coal"
            craftingSlots[5].parentElement.parentElement.alt = "coal"
            craftingSlots[6].parentElement.parentElement.alt = "coal"
            craftingSlots[7].parentElement.parentElement.alt = "coal"
            craftingSlots[8].parentElement.parentElement.alt = "coal"
            break;
            
        case "coarse_dirt":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dirt
            craftingSlots[4].src = all.gravel
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.dirt
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dirt"
            craftingSlots[4].parentElement.parentElement.title = "gravel"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "dirt"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dirt"
            craftingSlots[4].parentElement.parentElement.alt = "gravel"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "dirt"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "coast_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.coast_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.cobblestone
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "coast armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "coast armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "coast_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "cobbled_deepslate_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobbled_deepslate
            craftingSlots[7].src = all.cobbled_deepslate
            craftingSlots[8].src = all.cobbled_deepslate
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.title = "cobbled deepslate"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "cobbled deepslate"
            break;
            
        case "cobbled_deepslate_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobbled_deepslate
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobbled_deepslate
            craftingSlots[4].src = all.cobbled_deepslate
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobbled_deepslate
            craftingSlots[7].src = all.cobbled_deepslate
            craftingSlots[8].src = all.cobbled_deepslate
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[4].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.title = "cobbled deepslate"

            craftingSlots[0].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[4].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "cobbled deepslate"
            break;
            
        case "cobbled_deepslate_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobbled_deepslate
            craftingSlots[4].src = all.cobbled_deepslate
            craftingSlots[5].src = all.cobbled_deepslate
            craftingSlots[6].src = all.cobbled_deepslate
            craftingSlots[7].src = all.cobbled_deepslate
            craftingSlots[8].src = all.cobbled_deepslate
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[4].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[6].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.title = "cobbled deepslate"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[4].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[6].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "cobbled deepslate"
            break;
            
        case "cobblestone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.cobblestone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "cobblestone"
            break;
            
        case "cobblestone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.cobblestone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.cobblestone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "cobblestone"
            break;
            
        case "cobblestone_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.cobblestone
            craftingSlots[5].src = all.cobblestone
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.cobblestone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "cobblestone"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "cobblestone"
            break;
            
        case "comparator":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.redstone_torch
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.redstone_torch
            craftingSlots[4].src = all.quartz
            craftingSlots[5].src = all.redstone_torch
            craftingSlots[6].src = all.stone
            craftingSlots[7].src = all.stone
            craftingSlots[8].src = all.stone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "redstone torch"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "redstone torch"
            craftingSlots[4].parentElement.parentElement.title = "quartz"
            craftingSlots[5].parentElement.parentElement.title = "redstone torch"
            craftingSlots[6].parentElement.parentElement.title = "stone"
            craftingSlots[7].parentElement.parentElement.title = "stone"
            craftingSlots[8].parentElement.parentElement.title = "stone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "redstone torch"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "redstone torch"
            craftingSlots[4].parentElement.parentElement.alt = "quartz"
            craftingSlots[5].parentElement.parentElement.alt = "redstone torch"
            craftingSlots[6].parentElement.parentElement.alt = "stone"
            craftingSlots[7].parentElement.parentElement.alt = "stone"
            craftingSlots[8].parentElement.parentElement.alt = "stone"
            break;
            
        case "compass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.redstone
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "redstone"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "redstone"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "composter":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_slab
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.oak_slab
            craftingSlots[3].src = all.oak_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.oak_slab
            craftingSlots[6].src = all.oak_slab
            craftingSlots[7].src = all.oak_slab
            craftingSlots[8].src = all.oak_slab
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[3].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[6].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[7].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.title = "wooden slabs"

            craftingSlots[0].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[3].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[6].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[7].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.alt = "wooden slabs"
            break;
            
        case "conduit":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.nautilus_shell
            craftingSlots[1].src = all.nautilus_shell
            craftingSlots[2].src = all.nautilus_shell
            craftingSlots[3].src = all.nautilus_shell
            craftingSlots[4].src = all.heart_of_the_sea
            craftingSlots[5].src = all.nautilus_shell
            craftingSlots[6].src = all.nautilus_shell
            craftingSlots[7].src = all.nautilus_shell
            craftingSlots[8].src = all.nautilus_shell
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "nautilus shell"
            craftingSlots[1].parentElement.parentElement.title = "nautilus shell"
            craftingSlots[2].parentElement.parentElement.title = "nautilus shell"
            craftingSlots[3].parentElement.parentElement.title = "nautilus shell"
            craftingSlots[4].parentElement.parentElement.title = "heart of the sea"
            craftingSlots[5].parentElement.parentElement.title = "nautilus shell"
            craftingSlots[6].parentElement.parentElement.title = "nautilus shell"
            craftingSlots[7].parentElement.parentElement.title = "nautilus shell"
            craftingSlots[8].parentElement.parentElement.title = "nautilus shell"

            craftingSlots[0].parentElement.parentElement.alt = "nautilus shell"
            craftingSlots[1].parentElement.parentElement.alt = "nautilus shell"
            craftingSlots[2].parentElement.parentElement.alt = "nautilus shell"
            craftingSlots[3].parentElement.parentElement.alt = "nautilus shell"
            craftingSlots[4].parentElement.parentElement.alt = "heart of the sea"
            craftingSlots[5].parentElement.parentElement.alt = "nautilus shell"
            craftingSlots[6].parentElement.parentElement.alt = "nautilus shell"
            craftingSlots[7].parentElement.parentElement.alt = "nautilus shell"
            craftingSlots[8].parentElement.parentElement.alt = "nautilus shell"
            break;
            
        case "cookie":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.wheat
            craftingSlots[7].src = all.cocoa_beans
            craftingSlots[8].src = all.wheat
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "wheat"
            craftingSlots[7].parentElement.parentElement.title = "cocoa beans"
            craftingSlots[8].parentElement.parentElement.title = "wheat"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "wheat"
            craftingSlots[7].parentElement.parentElement.alt = "cocoa beans"
            craftingSlots[8].parentElement.parentElement.alt = "wheat"
            break;
            
        case "copper_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.copper_ingot
            craftingSlots[1].src = all.copper_ingot
            craftingSlots[2].src = all.copper_ingot
            craftingSlots[3].src = all.copper_ingot
            craftingSlots[4].src = all.copper_ingot
            craftingSlots[5].src = all.copper_ingot
            craftingSlots[6].src = all.copper_ingot
            craftingSlots[7].src = all.copper_ingot
            craftingSlots[8].src = all.copper_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "copper ingot"
            craftingSlots[1].parentElement.parentElement.title = "copper ingot"
            craftingSlots[2].parentElement.parentElement.title = "copper ingot"
            craftingSlots[3].parentElement.parentElement.title = "copper ingot"
            craftingSlots[4].parentElement.parentElement.title = "copper ingot"
            craftingSlots[5].parentElement.parentElement.title = "copper ingot"
            craftingSlots[6].parentElement.parentElement.title = "copper ingot"
            craftingSlots[7].parentElement.parentElement.title = "copper ingot"
            craftingSlots[8].parentElement.parentElement.title = "copper ingot"

            craftingSlots[0].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[1].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[2].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[3].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[4].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[5].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[6].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[7].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[8].parentElement.parentElement.alt = "copper ingot"
            break;
            
        case "copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.copper_block
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_block
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.copper_block
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "copper block"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper block"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "copper block"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "copper block"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper block"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "copper block"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "copper_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.copper_ingot
            craftingSlots[1].src = all.copper_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_ingot
            craftingSlots[4].src = all.copper_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.copper_ingot
            craftingSlots[7].src = all.copper_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "copper ingot"
            craftingSlots[1].parentElement.parentElement.title = "copper ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper ingot"
            craftingSlots[4].parentElement.parentElement.title = "copper ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "copper ingot"
            craftingSlots[7].parentElement.parentElement.title = "copper ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[1].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[4].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[7].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.copper_block
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_block
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.copper_block
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.copper_block
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "copper block"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper block"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "copper block"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "copper block"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "copper block"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper block"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "copper block"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "copper block"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "copper_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_ingot
            craftingSlots[4].src = all.copper_ingot
            craftingSlots[5].src = all.copper_ingot
            craftingSlots[6].src = all.copper_ingot
            craftingSlots[7].src = all.copper_ingot
            craftingSlots[8].src = all.copper_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper ingot"
            craftingSlots[4].parentElement.parentElement.title = "copper ingot"
            craftingSlots[5].parentElement.parentElement.title = "copper ingot"
            craftingSlots[6].parentElement.parentElement.title = "copper ingot"
            craftingSlots[7].parentElement.parentElement.title = "copper ingot"
            craftingSlots[8].parentElement.parentElement.title = "copper ingot"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[4].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[5].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[6].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[7].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[8].parentElement.parentElement.alt = "copper ingot"
            break;
            
        case "crafter":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.crafting_table
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.redstone
            craftingSlots[7].src = all.dropper
            craftingSlots[8].src = all.redstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "crafting table"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "redstone"
            craftingSlots[7].parentElement.parentElement.title = "dropper"
            craftingSlots[8].parentElement.parentElement.title = "redstone"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "crafting table"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "redstone"
            craftingSlots[7].parentElement.parentElement.alt = "dropper"
            craftingSlots[8].parentElement.parentElement.alt = "redstone"
            break;
            
        case "crafting_table":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "crimson_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.crimson_planks
            craftingSlots[1].src = all.crimson_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.crimson_planks
            craftingSlots[4].src = all.crimson_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.crimson_planks
            craftingSlots[7].src = all.crimson_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "crimson planks"
            craftingSlots[1].parentElement.parentElement.title = "crimson planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "crimson planks"
            craftingSlots[4].parentElement.parentElement.title = "crimson planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "crimson planks"
            craftingSlots[7].parentElement.parentElement.title = "crimson planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[1].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[4].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[7].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "crimson_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.crimson_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.crimson_planks
            craftingSlots[6].src = all.crimson_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.crimson_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "crimson planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "crimson planks"
            craftingSlots[6].parentElement.parentElement.title = "crimson planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "crimson planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[6].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "crimson planks"
            break;
            
        case "crimson_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.crimson_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.crimson_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "crimson planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "crimson planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "crimson_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_crimson_stem
            craftingSlots[4].src = all.stripped_crimson_stem
            craftingSlots[5].src = all.stripped_crimson_stem
            craftingSlots[6].src = all.stripped_crimson_stem
            craftingSlots[7].src = all.stripped_crimson_stem
            craftingSlots[8].src = all.stripped_crimson_stem
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[4].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[5].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[6].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[7].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[8].parentElement.parentElement.title = "stripped crimson stem"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[4].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[5].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[6].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[7].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[8].parentElement.parentElement.alt = "stripped crimson stem"
            break;
            
        case "crimson_hyphae":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.crimson_stem
            craftingSlots[4].src = all.crimson_stem
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.crimson_stem
            craftingSlots[7].src = all.crimson_stem
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "crimson stem"
            craftingSlots[4].parentElement.parentElement.title = "crimson stem"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "crimson stem"
            craftingSlots[7].parentElement.parentElement.title = "crimson stem"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "crimson stem"
            craftingSlots[4].parentElement.parentElement.alt = "crimson stem"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "crimson stem"
            craftingSlots[7].parentElement.parentElement.alt = "crimson stem"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "crimson_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.crimson_planks
            craftingSlots[7].src = all.crimson_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "crimson planks"
            craftingSlots[7].parentElement.parentElement.title = "crimson planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[7].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "crimson_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.crimson_planks
            craftingSlots[1].src = all.crimson_planks
            craftingSlots[2].src = all.crimson_planks
            craftingSlots[3].src = all.crimson_planks
            craftingSlots[4].src = all.crimson_planks
            craftingSlots[5].src = all.crimson_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "crimson planks"
            craftingSlots[1].parentElement.parentElement.title = "crimson planks"
            craftingSlots[2].parentElement.parentElement.title = "crimson planks"
            craftingSlots[3].parentElement.parentElement.title = "crimson planks"
            craftingSlots[4].parentElement.parentElement.title = "crimson planks"
            craftingSlots[5].parentElement.parentElement.title = "crimson planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[1].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[2].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[3].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[4].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[5].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "crimson_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.crimson_planks
            craftingSlots[7].src = all.crimson_planks
            craftingSlots[8].src = all.crimson_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "crimson planks"
            craftingSlots[7].parentElement.parentElement.title = "crimson planks"
            craftingSlots[8].parentElement.parentElement.title = "crimson planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[7].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[8].parentElement.parentElement.alt = "crimson planks"
            break;
            
        case "crimson_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.crimson_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.crimson_planks
            craftingSlots[4].src = all.crimson_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.crimson_planks
            craftingSlots[7].src = all.crimson_planks
            craftingSlots[8].src = all.crimson_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "crimson planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "crimson planks"
            craftingSlots[4].parentElement.parentElement.title = "crimson planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "crimson planks"
            craftingSlots[7].parentElement.parentElement.title = "crimson planks"
            craftingSlots[8].parentElement.parentElement.title = "crimson planks"

            craftingSlots[0].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[4].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[7].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[8].parentElement.parentElement.alt = "crimson planks"
            break;
            
        case "crimson_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.crimson_planks
            craftingSlots[4].src = all.crimson_planks
            craftingSlots[5].src = all.crimson_planks
            craftingSlots[6].src = all.crimson_planks
            craftingSlots[7].src = all.crimson_planks
            craftingSlots[8].src = all.crimson_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "crimson planks"
            craftingSlots[4].parentElement.parentElement.title = "crimson planks"
            craftingSlots[5].parentElement.parentElement.title = "crimson planks"
            craftingSlots[6].parentElement.parentElement.title = "crimson planks"
            craftingSlots[7].parentElement.parentElement.title = "crimson planks"
            craftingSlots[8].parentElement.parentElement.title = "crimson planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[4].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[5].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[6].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[7].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[8].parentElement.parentElement.alt = "crimson planks"
            break;
            
        case "crossbow":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.stick
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.stick
            craftingSlots[3].src = all.string
            craftingSlots[4].src = all.tripwire_hook
            craftingSlots[5].src = all.string
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stick"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "stick"
            craftingSlots[3].parentElement.parentElement.title = "string"
            craftingSlots[4].parentElement.parentElement.title = "tripwire hook"
            craftingSlots[5].parentElement.parentElement.title = "string"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "stick"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "stick"
            craftingSlots[3].parentElement.parentElement.alt = "string"
            craftingSlots[4].parentElement.parentElement.alt = "tripwire hook"
            craftingSlots[5].parentElement.parentElement.alt = "string"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_block
            craftingSlots[4].src = all.copper_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.copper_block
            craftingSlots[7].src = all.copper_block
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper block"
            craftingSlots[4].parentElement.parentElement.title = "copper block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "copper block"
            craftingSlots[7].parentElement.parentElement.title = "copper block"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper block"
            craftingSlots[4].parentElement.parentElement.alt = "copper block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "copper block"
            craftingSlots[7].parentElement.parentElement.alt = "copper block"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cut_copper
            craftingSlots[7].src = all.cut_copper
            craftingSlots[8].src = all.cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cut copper"
            craftingSlots[7].parentElement.parentElement.title = "cut copper"
            craftingSlots[8].parentElement.parentElement.title = "cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "cut copper"
            break;
            
        case "cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cut_copper
            craftingSlots[4].src = all.cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cut_copper
            craftingSlots[7].src = all.cut_copper
            craftingSlots[8].src = all.cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cut copper"
            craftingSlots[4].parentElement.parentElement.title = "cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cut copper"
            craftingSlots[7].parentElement.parentElement.title = "cut copper"
            craftingSlots[8].parentElement.parentElement.title = "cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "cut copper"
            break;
            
        case "cut_red_sandstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_sandstone
            craftingSlots[4].src = all.red_sandstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_sandstone
            craftingSlots[7].src = all.red_sandstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red sandstone"
            craftingSlots[4].parentElement.parentElement.title = "red sandstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red sandstone"
            craftingSlots[7].parentElement.parentElement.title = "red sandstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cut_red_sandstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cut_red_sandstone
            craftingSlots[7].src = all.cut_red_sandstone
            craftingSlots[8].src = all.cut_red_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cut red sandstone"
            craftingSlots[7].parentElement.parentElement.title = "cut red sandstone"
            craftingSlots[8].parentElement.parentElement.title = "cut red sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cut red sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "cut red sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "cut red sandstone"
            break;
            
        case "cut_sandstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.sandstone
            craftingSlots[4].src = all.sandstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.sandstone
            craftingSlots[7].src = all.sandstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "sandstone"
            craftingSlots[4].parentElement.parentElement.title = "sandstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "sandstone"
            craftingSlots[7].parentElement.parentElement.title = "sandstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cut_sandstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cut_sandstone
            craftingSlots[7].src = all.cut_sandstone
            craftingSlots[8].src = all.cut_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cut sandstone"
            craftingSlots[7].parentElement.parentElement.title = "cut sandstone"
            craftingSlots[8].parentElement.parentElement.title = "cut sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cut sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "cut sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "cut sandstone"
            break;
            
        case "cyan_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cyan_wool
            craftingSlots[1].src = all.cyan_wool
            craftingSlots[2].src = all.cyan_wool
            craftingSlots[3].src = all.cyan_wool
            craftingSlots[4].src = all.cyan_wool
            craftingSlots[5].src = all.cyan_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cyan wool"
            craftingSlots[1].parentElement.parentElement.title = "cyan wool"
            craftingSlots[2].parentElement.parentElement.title = "cyan wool"
            craftingSlots[3].parentElement.parentElement.title = "cyan wool"
            craftingSlots[4].parentElement.parentElement.title = "cyan wool"
            craftingSlots[5].parentElement.parentElement.title = "cyan wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[1].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[2].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[3].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[4].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[5].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cyan_wool
            craftingSlots[4].src = all.cyan_wool
            craftingSlots[5].src = all.cyan_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cyan wool"
            craftingSlots[4].parentElement.parentElement.title = "cyan wool"
            craftingSlots[5].parentElement.parentElement.title = "cyan wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[4].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[5].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "cyan_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cyan_wool
            craftingSlots[7].src = all.cyan_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cyan wool"
            craftingSlots[7].parentElement.parentElement.title = "cyan wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[7].parentElement.parentElement.alt = "cyan wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.cyan_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "cyan dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "cyan_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cyan_stained_glass
            craftingSlots[4].src = all.cyan_stained_glass
            craftingSlots[5].src = all.cyan_stained_glass
            craftingSlots[6].src = all.cyan_stained_glass
            craftingSlots[7].src = all.cyan_stained_glass
            craftingSlots[8].src = all.cyan_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cyan stained glass"
            craftingSlots[4].parentElement.parentElement.title = "cyan stained glass"
            craftingSlots[5].parentElement.parentElement.title = "cyan stained glass"
            craftingSlots[6].parentElement.parentElement.title = "cyan stained glass"
            craftingSlots[7].parentElement.parentElement.title = "cyan stained glass"
            craftingSlots[8].parentElement.parentElement.title = "cyan stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cyan stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "cyan stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "cyan stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "cyan stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "cyan stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "cyan stained glass"
            break;
            
        case "cyan_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.cyan_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "cyan dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "cyan_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.cyan_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "cyan dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "dark_oak_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dark_oak_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.dark_oak_planks
            craftingSlots[6].src = all.dark_oak_planks
            craftingSlots[7].src = all.dark_oak_planks
            craftingSlots[8].src = all.dark_oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.title = "dark oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "dark oak planks"
            break;
            
        case "dark_oak_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.dark_oak_planks
            craftingSlots[1].src = all.dark_oak_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dark_oak_planks
            craftingSlots[4].src = all.dark_oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.dark_oak_planks
            craftingSlots[7].src = all.dark_oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[1].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dark_oak_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dark_oak_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.dark_oak_planks
            craftingSlots[6].src = all.dark_oak_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.dark_oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "dark oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "dark oak planks"
            break;
            
        case "dark_oak_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.dark_oak_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.dark_oak_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "dark_oak_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_dark_oak_log
            craftingSlots[4].src = all.stripped_dark_oak_log
            craftingSlots[5].src = all.stripped_dark_oak_log
            craftingSlots[6].src = all.stripped_dark_oak_log
            craftingSlots[7].src = all.stripped_dark_oak_log
            craftingSlots[8].src = all.stripped_dark_oak_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[4].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[5].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[6].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[7].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[8].parentElement.parentElement.title = "stripped dark oak log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped dark oak log"
            break;
            
        case "dark_oak_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.dark_oak_planks
            craftingSlots[7].src = all.dark_oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dark_oak_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.dark_oak_planks
            craftingSlots[1].src = all.dark_oak_planks
            craftingSlots[2].src = all.dark_oak_planks
            craftingSlots[3].src = all.dark_oak_planks
            craftingSlots[4].src = all.dark_oak_planks
            craftingSlots[5].src = all.dark_oak_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[1].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[2].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[3].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dark_oak_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.dark_oak_planks
            craftingSlots[7].src = all.dark_oak_planks
            craftingSlots[8].src = all.dark_oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.title = "dark oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "dark oak planks"
            break;
            
        case "dark_oak_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.dark_oak_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dark_oak_planks
            craftingSlots[4].src = all.dark_oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.dark_oak_planks
            craftingSlots[7].src = all.dark_oak_planks
            craftingSlots[8].src = all.dark_oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.title = "dark oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "dark oak planks"
            break;
            
        case "dark_oak_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dark_oak_planks
            craftingSlots[4].src = all.dark_oak_planks
            craftingSlots[5].src = all.dark_oak_planks
            craftingSlots[6].src = all.dark_oak_planks
            craftingSlots[7].src = all.dark_oak_planks
            craftingSlots[8].src = all.dark_oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.title = "dark oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "dark oak planks"
            break;
            
        case "dark_oak_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dark_oak_log
            craftingSlots[4].src = all.dark_oak_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.dark_oak_log
            craftingSlots[7].src = all.dark_oak_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dark oak log"
            craftingSlots[4].parentElement.parentElement.title = "dark oak log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "dark oak log"
            craftingSlots[7].parentElement.parentElement.title = "dark oak log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dark oak log"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "dark oak log"
            craftingSlots[7].parentElement.parentElement.alt = "dark oak log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dark_prismarine":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.prismarine_shard
            craftingSlots[1].src = all.prismarine_shard
            craftingSlots[2].src = all.prismarine_shard
            craftingSlots[3].src = all.prismarine_shard
            craftingSlots[4].src = all.black_dye
            craftingSlots[5].src = all.prismarine_shard
            craftingSlots[6].src = all.prismarine_shard
            craftingSlots[7].src = all.prismarine_shard
            craftingSlots[8].src = all.prismarine_shard
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[1].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[2].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[3].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[4].parentElement.parentElement.title = "black dye"
            craftingSlots[5].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[6].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[8].parentElement.parentElement.title = "prismarine shard"

            craftingSlots[0].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[1].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[2].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[3].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[4].parentElement.parentElement.alt = "black dye"
            craftingSlots[5].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine shard"
            break;
            
        case "dark_prismarine_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.dark_prismarine
            craftingSlots[7].src = all.dark_prismarine
            craftingSlots[8].src = all.dark_prismarine
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "dark prismarine"
            craftingSlots[7].parentElement.parentElement.title = "dark prismarine"
            craftingSlots[8].parentElement.parentElement.title = "dark prismarine"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "dark prismarine"
            craftingSlots[7].parentElement.parentElement.alt = "dark prismarine"
            craftingSlots[8].parentElement.parentElement.alt = "dark prismarine"
            break;
            
        case "dark_prismarine_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.dark_prismarine
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.dark_prismarine
            craftingSlots[4].src = all.dark_prismarine
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.dark_prismarine
            craftingSlots[7].src = all.dark_prismarine
            craftingSlots[8].src = all.dark_prismarine
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "dark prismarine"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "dark prismarine"
            craftingSlots[4].parentElement.parentElement.title = "dark prismarine"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "dark prismarine"
            craftingSlots[7].parentElement.parentElement.title = "dark prismarine"
            craftingSlots[8].parentElement.parentElement.title = "dark prismarine"

            craftingSlots[0].parentElement.parentElement.alt = "dark prismarine"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "dark prismarine"
            craftingSlots[4].parentElement.parentElement.alt = "dark prismarine"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "dark prismarine"
            craftingSlots[7].parentElement.parentElement.alt = "dark prismarine"
            craftingSlots[8].parentElement.parentElement.alt = "dark prismarine"
            break;
            
        case "daylight_detector":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.quartz
            craftingSlots[4].src = all.quartz
            craftingSlots[5].src = all.quartz
            craftingSlots[6].src = all.oak_slab
            craftingSlots[7].src = all.oak_slab
            craftingSlots[8].src = all.oak_slab
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "quartz"
            craftingSlots[4].parentElement.parentElement.title = "quartz"
            craftingSlots[5].parentElement.parentElement.title = "quartz"
            craftingSlots[6].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[7].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.title = "wooden slabs"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "quartz"
            craftingSlots[4].parentElement.parentElement.alt = "quartz"
            craftingSlots[5].parentElement.parentElement.alt = "quartz"
            craftingSlots[6].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[7].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.alt = "wooden slabs"
            break;
            
                case "daylight_detector":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "decorated_pot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.brick
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.brick
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.brick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "brick"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "brick"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "brick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "brick"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "brick"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "brick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "deepslate_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.deepslate_bricks
            craftingSlots[7].src = all.deepslate_bricks
            craftingSlots[8].src = all.deepslate_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.title = "deepslate bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.alt = "deepslate bricks"
            break;
            
        case "deepslate_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.deepslate_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.deepslate_bricks
            craftingSlots[4].src = all.deepslate_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.deepslate_bricks
            craftingSlots[7].src = all.deepslate_bricks
            craftingSlots[8].src = all.deepslate_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[4].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.title = "deepslate bricks"

            craftingSlots[0].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[4].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.alt = "deepslate bricks"
            break;
            
        case "deepslate_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.deepslate_bricks
            craftingSlots[4].src = all.deepslate_bricks
            craftingSlots[5].src = all.deepslate_bricks
            craftingSlots[6].src = all.deepslate_bricks
            craftingSlots[7].src = all.deepslate_bricks
            craftingSlots[8].src = all.deepslate_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[4].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[5].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[6].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.title = "deepslate bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[4].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[5].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[6].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.alt = "deepslate bricks"
            break;
            
        case "deepslate_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_deepslate
            craftingSlots[4].src = all.polished_deepslate
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_deepslate
            craftingSlots[7].src = all.polished_deepslate
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[4].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[4].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "deepslate_tile_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.deepslate_tiles
            craftingSlots[7].src = all.deepslate_tiles
            craftingSlots[8].src = all.deepslate_tiles
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[7].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[8].parentElement.parentElement.title = "deepslate tiles"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[7].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[8].parentElement.parentElement.alt = "deepslate tiles"
            break;
            
        case "deepslate_tile_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.deepslate_tiles
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.deepslate_tiles
            craftingSlots[4].src = all.deepslate_tiles
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.deepslate_tiles
            craftingSlots[7].src = all.deepslate_tiles
            craftingSlots[8].src = all.deepslate_tiles
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[4].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[7].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[8].parentElement.parentElement.title = "deepslate tiles"

            craftingSlots[0].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[4].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[7].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[8].parentElement.parentElement.alt = "deepslate tiles"
            break;
            
        case "deepslate_tile_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.deepslate_tiles
            craftingSlots[4].src = all.deepslate_tiles
            craftingSlots[5].src = all.deepslate_tiles
            craftingSlots[6].src = all.deepslate_tiles
            craftingSlots[7].src = all.deepslate_tiles
            craftingSlots[8].src = all.deepslate_tiles
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[4].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[5].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[6].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[7].parentElement.parentElement.title = "deepslate tiles"
            craftingSlots[8].parentElement.parentElement.title = "deepslate tiles"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[4].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[5].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[6].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[7].parentElement.parentElement.alt = "deepslate tiles"
            craftingSlots[8].parentElement.parentElement.alt = "deepslate tiles"
            break;
            
        case "deepslate_tiles":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.deepslate_bricks
            craftingSlots[4].src = all.deepslate_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.deepslate_bricks
            craftingSlots[7].src = all.deepslate_bricks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[4].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.title = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[4].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[7].parentElement.parentElement.alt = "deepslate bricks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "detector_rail":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.stone_pressure_plate
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "stone pressure plate"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "stone pressure plate"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "diamond_axe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.diamond
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "diamond"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "diamond"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "diamond_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.diamond
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.diamond
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "diamond"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "diamond"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "diamond"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "diamond"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
        case "diamond_boots":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
        case "diamond_chestplate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.diamond
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "diamond"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "diamond"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
        case "diamond_helmet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.diamond
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "diamond"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "diamond"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
        case "diamond_hoe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.diamond
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "diamond"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "diamond"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "diamond_leggings":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.diamond
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "diamond"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "diamond"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
        case "diamond_pickaxe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.diamond
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "diamond"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "diamond"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "diamond_shovel":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "diamond_sword":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "diorite":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.quartz
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.quartz
            craftingSlots[7].src = all.cobblestone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "quartz"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "quartz"
            craftingSlots[7].parentElement.parentElement.title = "cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "quartz"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "quartz"
            craftingSlots[7].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "diorite_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.diorite
            craftingSlots[7].src = all.diorite
            craftingSlots[8].src = all.diorite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "diorite"
            craftingSlots[7].parentElement.parentElement.title = "diorite"
            craftingSlots[8].parentElement.parentElement.title = "diorite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "diorite"
            craftingSlots[7].parentElement.parentElement.alt = "diorite"
            craftingSlots[8].parentElement.parentElement.alt = "diorite"
            break;
            
        case "diorite_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diorite
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diorite
            craftingSlots[4].src = all.diorite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.diorite
            craftingSlots[7].src = all.diorite
            craftingSlots[8].src = all.diorite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diorite"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diorite"
            craftingSlots[4].parentElement.parentElement.title = "diorite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "diorite"
            craftingSlots[7].parentElement.parentElement.title = "diorite"
            craftingSlots[8].parentElement.parentElement.title = "diorite"

            craftingSlots[0].parentElement.parentElement.alt = "diorite"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diorite"
            craftingSlots[4].parentElement.parentElement.alt = "diorite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "diorite"
            craftingSlots[7].parentElement.parentElement.alt = "diorite"
            craftingSlots[8].parentElement.parentElement.alt = "diorite"
            break;
            
        case "diorite_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diorite
            craftingSlots[4].src = all.diorite
            craftingSlots[5].src = all.diorite
            craftingSlots[6].src = all.diorite
            craftingSlots[7].src = all.diorite
            craftingSlots[8].src = all.diorite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diorite"
            craftingSlots[4].parentElement.parentElement.title = "diorite"
            craftingSlots[5].parentElement.parentElement.title = "diorite"
            craftingSlots[6].parentElement.parentElement.title = "diorite"
            craftingSlots[7].parentElement.parentElement.title = "diorite"
            craftingSlots[8].parentElement.parentElement.title = "diorite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diorite"
            craftingSlots[4].parentElement.parentElement.alt = "diorite"
            craftingSlots[5].parentElement.parentElement.alt = "diorite"
            craftingSlots[6].parentElement.parentElement.alt = "diorite"
            craftingSlots[7].parentElement.parentElement.alt = "diorite"
            craftingSlots[8].parentElement.parentElement.alt = "diorite"
            break;
            
        case "dispenser":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.cobblestone
            craftingSlots[2].src = all.cobblestone
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.bow
            craftingSlots[5].src = all.cobblestone
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "cobblestone"
            craftingSlots[2].parentElement.parentElement.title = "cobblestone"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "bow"
            craftingSlots[5].parentElement.parentElement.title = "cobblestone"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[2].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "bow"
            craftingSlots[5].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "cobblestone"
            break;
            
        case "dried_kelp_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.dried_kelp
            craftingSlots[1].src = all.dried_kelp
            craftingSlots[2].src = all.dried_kelp
            craftingSlots[3].src = all.dried_kelp
            craftingSlots[4].src = all.dried_kelp
            craftingSlots[5].src = all.dried_kelp
            craftingSlots[6].src = all.dried_kelp
            craftingSlots[7].src = all.dried_kelp
            craftingSlots[8].src = all.dried_kelp
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "dried kelp"
            craftingSlots[1].parentElement.parentElement.title = "dried kelp"
            craftingSlots[2].parentElement.parentElement.title = "dried kelp"
            craftingSlots[3].parentElement.parentElement.title = "dried kelp"
            craftingSlots[4].parentElement.parentElement.title = "dried kelp"
            craftingSlots[5].parentElement.parentElement.title = "dried kelp"
            craftingSlots[6].parentElement.parentElement.title = "dried kelp"
            craftingSlots[7].parentElement.parentElement.title = "dried kelp"
            craftingSlots[8].parentElement.parentElement.title = "dried kelp"

            craftingSlots[0].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[1].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[2].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[3].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[4].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[5].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[6].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[7].parentElement.parentElement.alt = "dried kelp"
            craftingSlots[8].parentElement.parentElement.alt = "dried kelp"
            break;
            
        case "dripstone_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.pointed_dripstone
            craftingSlots[4].src = all.pointed_dripstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.pointed_dripstone
            craftingSlots[7].src = all.pointed_dripstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "pointed dripstone"
            craftingSlots[4].parentElement.parentElement.title = "pointed dripstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "pointed dripstone"
            craftingSlots[7].parentElement.parentElement.title = "pointed dripstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "pointed dripstone"
            craftingSlots[4].parentElement.parentElement.alt = "pointed dripstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "pointed dripstone"
            craftingSlots[7].parentElement.parentElement.alt = "pointed dripstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dropper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.cobblestone
            craftingSlots[2].src = all.cobblestone
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.cobblestone
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "cobblestone"
            craftingSlots[2].parentElement.parentElement.title = "cobblestone"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "cobblestone"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[2].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "cobblestone"
            break;
            
        case "dune_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.dune_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.sandstone
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "dune armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "sandstone"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "dune armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "dune_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "emerald_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.emerald
            craftingSlots[1].src = all.emerald
            craftingSlots[2].src = all.emerald
            craftingSlots[3].src = all.emerald
            craftingSlots[4].src = all.emerald
            craftingSlots[5].src = all.emerald
            craftingSlots[6].src = all.emerald
            craftingSlots[7].src = all.emerald
            craftingSlots[8].src = all.emerald
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "emerald"
            craftingSlots[1].parentElement.parentElement.title = "emerald"
            craftingSlots[2].parentElement.parentElement.title = "emerald"
            craftingSlots[3].parentElement.parentElement.title = "emerald"
            craftingSlots[4].parentElement.parentElement.title = "emerald"
            craftingSlots[5].parentElement.parentElement.title = "emerald"
            craftingSlots[6].parentElement.parentElement.title = "emerald"
            craftingSlots[7].parentElement.parentElement.title = "emerald"
            craftingSlots[8].parentElement.parentElement.title = "emerald"

            craftingSlots[0].parentElement.parentElement.alt = "emerald"
            craftingSlots[1].parentElement.parentElement.alt = "emerald"
            craftingSlots[2].parentElement.parentElement.alt = "emerald"
            craftingSlots[3].parentElement.parentElement.alt = "emerald"
            craftingSlots[4].parentElement.parentElement.alt = "emerald"
            craftingSlots[5].parentElement.parentElement.alt = "emerald"
            craftingSlots[6].parentElement.parentElement.alt = "emerald"
            craftingSlots[7].parentElement.parentElement.alt = "emerald"
            craftingSlots[8].parentElement.parentElement.alt = "emerald"
            break;
            
        case "enchanting_table":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.book
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.obsidian
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.obsidian
            craftingSlots[7].src = all.obsidian
            craftingSlots[8].src = all.obsidian
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "book"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "obsidian"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "obsidian"
            craftingSlots[7].parentElement.parentElement.title = "obsidian"
            craftingSlots[8].parentElement.parentElement.title = "obsidian"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "book"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "obsidian"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "obsidian"
            craftingSlots[7].parentElement.parentElement.alt = "obsidian"
            craftingSlots[8].parentElement.parentElement.alt = "obsidian"
            break;
            
        case "end_crystal":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.ender_eye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.ghast_tear
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "ender eye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "ghast tear"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "ender eye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "ghast tear"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "end_rod":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blaze_rod
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.popped_chorus_fruit
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blaze rod"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "popped chorus fruit"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "popped chorus fruit"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "end_stone_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.end_stone_bricks
            craftingSlots[7].src = all.end_stone_bricks
            craftingSlots[8].src = all.end_stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "end stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "end stone bricks"
            break;
            
        case "end_stone_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.end_stone_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.end_stone_bricks
            craftingSlots[4].src = all.end_stone_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.end_stone_bricks
            craftingSlots[7].src = all.end_stone_bricks
            craftingSlots[8].src = all.end_stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "end stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "end stone bricks"
            break;
            
        case "end_stone_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.end_stone_bricks
            craftingSlots[4].src = all.end_stone_bricks
            craftingSlots[5].src = all.end_stone_bricks
            craftingSlots[6].src = all.end_stone_bricks
            craftingSlots[7].src = all.end_stone_bricks
            craftingSlots[8].src = all.end_stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[5].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[6].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "end stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "end stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[6].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "end stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "end stone bricks"
            break;
            
        case "end_stone_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.end_stone
            craftingSlots[4].src = all.end_stone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.end_stone
            craftingSlots[7].src = all.end_stone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "end stone"
            craftingSlots[4].parentElement.parentElement.title = "end stone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "end stone"
            craftingSlots[7].parentElement.parentElement.title = "end stone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "end stone"
            craftingSlots[4].parentElement.parentElement.alt = "end stone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "end stone"
            craftingSlots[7].parentElement.parentElement.alt = "end stone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "ender_chest":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.obsidian
            craftingSlots[1].src = all.obsidian
            craftingSlots[2].src = all.obsidian
            craftingSlots[3].src = all.obsidian
            craftingSlots[4].src = all.ender_eye
            craftingSlots[5].src = all.obsidian
            craftingSlots[6].src = all.obsidian
            craftingSlots[7].src = all.obsidian
            craftingSlots[8].src = all.obsidian
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "obsidian"
            craftingSlots[1].parentElement.parentElement.title = "obsidian"
            craftingSlots[2].parentElement.parentElement.title = "obsidian"
            craftingSlots[3].parentElement.parentElement.title = "obsidian"
            craftingSlots[4].parentElement.parentElement.title = "ender eye"
            craftingSlots[5].parentElement.parentElement.title = "obsidian"
            craftingSlots[6].parentElement.parentElement.title = "obsidian"
            craftingSlots[7].parentElement.parentElement.title = "obsidian"
            craftingSlots[8].parentElement.parentElement.title = "obsidian"

            craftingSlots[0].parentElement.parentElement.alt = "obsidian"
            craftingSlots[1].parentElement.parentElement.alt = "obsidian"
            craftingSlots[2].parentElement.parentElement.alt = "obsidian"
            craftingSlots[3].parentElement.parentElement.alt = "obsidian"
            craftingSlots[4].parentElement.parentElement.alt = "ender eye"
            craftingSlots[5].parentElement.parentElement.alt = "obsidian"
            craftingSlots[6].parentElement.parentElement.alt = "obsidian"
            craftingSlots[7].parentElement.parentElement.alt = "obsidian"
            craftingSlots[8].parentElement.parentElement.alt = "obsidian"
            break;
            
        case "exposed_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.exposed_cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "exposed cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "exposed cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "exposed_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.exposed_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.exposed_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "exposed copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "exposed copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "exposed_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.exposed_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.exposed_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.exposed_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "exposed copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "exposed copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "exposed copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "exposed_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper
            craftingSlots[4].src = all.exposed_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.exposed_copper
            craftingSlots[7].src = all.exposed_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper"
            craftingSlots[4].parentElement.parentElement.title = "exposed copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "exposed copper"
            craftingSlots[7].parentElement.parentElement.title = "exposed copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[4].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[7].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "exposed_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.exposed_cut_copper
            craftingSlots[7].src = all.exposed_cut_copper
            craftingSlots[8].src = all.exposed_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[7].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[8].parentElement.parentElement.title = "exposed cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "exposed cut copper"
            break;
            
        case "exposed_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.exposed_cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_cut_copper
            craftingSlots[4].src = all.exposed_cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.exposed_cut_copper
            craftingSlots[7].src = all.exposed_cut_copper
            craftingSlots[8].src = all.exposed_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[4].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[7].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[8].parentElement.parentElement.title = "exposed cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "exposed cut copper"
            break;
            
        case "eye_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.eye_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.end_stone
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "eye armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "end stone"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "eye armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "end stone"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "eye_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
                case "fire_charge":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "fishing_rod":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.stick
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.string
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.string
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "stick"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "string"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "string"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "stick"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "string"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "string"
            break;
            
        case "fletching_table":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.flint
            craftingSlots[1].src = all.flint
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "flint"
            craftingSlots[1].parentElement.parentElement.title = "flint"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "flint"
            craftingSlots[1].parentElement.parentElement.alt = "flint"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "flow_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.flow_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.breeze_rod
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "flow armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "breeze rod"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "flow armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "breeze rod"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "flow_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "flower_pot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.brick
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.brick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "brick"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "brick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "brick"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "brick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "furnace":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.cobblestone
            craftingSlots[2].src = all.cobblestone
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.cobblestone
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.cobblestone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[1].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[2].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[3].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[6].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[7].parentElement.parentElement.title = "stone crafting materials"
            craftingSlots[8].parentElement.parentElement.title = "stone crafting materials"

            craftingSlots[0].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[1].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[2].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[3].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[6].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[7].parentElement.parentElement.alt = "stone crafting materials"
            craftingSlots[8].parentElement.parentElement.alt = "stone crafting materials"
            break;
            
        case "glass_bottle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.glass
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "glass"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "glass"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "glistering_melon_slice":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_nugget
            craftingSlots[1].src = all.gold_nugget
            craftingSlots[2].src = all.gold_nugget
            craftingSlots[3].src = all.gold_nugget
            craftingSlots[4].src = all.melon_slice
            craftingSlots[5].src = all.gold_nugget
            craftingSlots[6].src = all.gold_nugget
            craftingSlots[7].src = all.gold_nugget
            craftingSlots[8].src = all.gold_nugget
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold nugget"
            craftingSlots[1].parentElement.parentElement.title = "gold nugget"
            craftingSlots[2].parentElement.parentElement.title = "gold nugget"
            craftingSlots[3].parentElement.parentElement.title = "gold nugget"
            craftingSlots[4].parentElement.parentElement.title = "melon slice"
            craftingSlots[5].parentElement.parentElement.title = "gold nugget"
            craftingSlots[6].parentElement.parentElement.title = "gold nugget"
            craftingSlots[7].parentElement.parentElement.title = "gold nugget"
            craftingSlots[8].parentElement.parentElement.title = "gold nugget"

            craftingSlots[0].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[1].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[2].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[3].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[4].parentElement.parentElement.alt = "melon slice"
            craftingSlots[5].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[6].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[7].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[8].parentElement.parentElement.alt = "gold nugget"
            break;
            
        case "glowstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.glowstone_dust
            craftingSlots[4].src = all.glowstone_dust
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.glowstone_dust
            craftingSlots[7].src = all.glowstone_dust
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[4].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[7].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[4].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[7].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gold_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.gold_ingot
            craftingSlots[2].src = all.gold_ingot
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.gold_ingot
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.gold_ingot
            craftingSlots[8].src = all.gold_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "gold ingot"
            craftingSlots[2].parentElement.parentElement.title = "gold ingot"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "gold ingot"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "gold ingot"
            craftingSlots[8].parentElement.parentElement.title = "gold ingot"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[2].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[8].parentElement.parentElement.alt = "gold ingot"
            break;
            
        case "gold_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_nugget
            craftingSlots[1].src = all.gold_nugget
            craftingSlots[2].src = all.gold_nugget
            craftingSlots[3].src = all.gold_nugget
            craftingSlots[4].src = all.gold_nugget
            craftingSlots[5].src = all.gold_nugget
            craftingSlots[6].src = all.gold_nugget
            craftingSlots[7].src = all.gold_nugget
            craftingSlots[8].src = all.gold_nugget
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold nugget"
            craftingSlots[1].parentElement.parentElement.title = "gold nugget"
            craftingSlots[2].parentElement.parentElement.title = "gold nugget"
            craftingSlots[3].parentElement.parentElement.title = "gold nugget"
            craftingSlots[4].parentElement.parentElement.title = "gold nugget"
            craftingSlots[5].parentElement.parentElement.title = "gold nugget"
            craftingSlots[6].parentElement.parentElement.title = "gold nugget"
            craftingSlots[7].parentElement.parentElement.title = "gold nugget"
            craftingSlots[8].parentElement.parentElement.title = "gold nugget"

            craftingSlots[0].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[1].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[2].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[3].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[4].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[5].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[6].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[7].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[8].parentElement.parentElement.alt = "gold nugget"
            break;
            
        case "golden_apple":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.gold_ingot
            craftingSlots[2].src = all.gold_ingot
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.apple
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.gold_ingot
            craftingSlots[8].src = all.gold_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "gold ingot"
            craftingSlots[2].parentElement.parentElement.title = "gold ingot"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "apple"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "gold ingot"
            craftingSlots[8].parentElement.parentElement.title = "gold ingot"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[2].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "apple"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[8].parentElement.parentElement.alt = "gold ingot"
            break;
            
        case "golden_axe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.gold_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "gold ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "golden_boots":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.gold_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "gold ingot"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "gold ingot"
            break;
            
        case "golden_carrot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_nugget
            craftingSlots[1].src = all.gold_nugget
            craftingSlots[2].src = all.gold_nugget
            craftingSlots[3].src = all.gold_nugget
            craftingSlots[4].src = all.carrot
            craftingSlots[5].src = all.gold_nugget
            craftingSlots[6].src = all.gold_nugget
            craftingSlots[7].src = all.gold_nugget
            craftingSlots[8].src = all.gold_nugget
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold nugget"
            craftingSlots[1].parentElement.parentElement.title = "gold nugget"
            craftingSlots[2].parentElement.parentElement.title = "gold nugget"
            craftingSlots[3].parentElement.parentElement.title = "gold nugget"
            craftingSlots[4].parentElement.parentElement.title = "carrot"
            craftingSlots[5].parentElement.parentElement.title = "gold nugget"
            craftingSlots[6].parentElement.parentElement.title = "gold nugget"
            craftingSlots[7].parentElement.parentElement.title = "gold nugget"
            craftingSlots[8].parentElement.parentElement.title = "gold nugget"

            craftingSlots[0].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[1].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[2].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[3].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[4].parentElement.parentElement.alt = "carrot"
            craftingSlots[5].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[6].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[7].parentElement.parentElement.alt = "gold nugget"
            craftingSlots[8].parentElement.parentElement.alt = "gold nugget"
            break;
            
        case "golden_chestplate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.gold_ingot
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.gold_ingot
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.gold_ingot
            craftingSlots[8].src = all.gold_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "gold ingot"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "gold ingot"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "gold ingot"
            craftingSlots[8].parentElement.parentElement.title = "gold ingot"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[8].parentElement.parentElement.alt = "gold ingot"
            break;
            
        case "golden_helmet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.gold_ingot
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.gold_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "gold ingot"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "gold ingot"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "gold ingot"
            break;
            
        case "golden_hoe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.gold_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "gold ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "golden_leggings":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.gold_ingot
            craftingSlots[2].src = all.gold_ingot
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.gold_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "gold ingot"
            craftingSlots[2].parentElement.parentElement.title = "gold ingot"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "gold ingot"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[2].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "gold ingot"
            break;
            
        case "golden_pickaxe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.gold_ingot
            craftingSlots[2].src = all.gold_ingot
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "gold ingot"
            craftingSlots[2].parentElement.parentElement.title = "gold ingot"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[2].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "golden_shovel":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "golden_sword":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "granite_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.granite
            craftingSlots[7].src = all.granite
            craftingSlots[8].src = all.granite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "granite"
            craftingSlots[7].parentElement.parentElement.title = "granite"
            craftingSlots[8].parentElement.parentElement.title = "granite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "granite"
            craftingSlots[7].parentElement.parentElement.alt = "granite"
            craftingSlots[8].parentElement.parentElement.alt = "granite"
            break;
            
        case "granite_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.granite
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.granite
            craftingSlots[4].src = all.granite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.granite
            craftingSlots[7].src = all.granite
            craftingSlots[8].src = all.granite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "granite"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "granite"
            craftingSlots[4].parentElement.parentElement.title = "granite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "granite"
            craftingSlots[7].parentElement.parentElement.title = "granite"
            craftingSlots[8].parentElement.parentElement.title = "granite"

            craftingSlots[0].parentElement.parentElement.alt = "granite"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "granite"
            craftingSlots[4].parentElement.parentElement.alt = "granite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "granite"
            craftingSlots[7].parentElement.parentElement.alt = "granite"
            craftingSlots[8].parentElement.parentElement.alt = "granite"
            break;
            
        case "granite_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.granite
            craftingSlots[4].src = all.granite
            craftingSlots[5].src = all.granite
            craftingSlots[6].src = all.granite
            craftingSlots[7].src = all.granite
            craftingSlots[8].src = all.granite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "granite"
            craftingSlots[4].parentElement.parentElement.title = "granite"
            craftingSlots[5].parentElement.parentElement.title = "granite"
            craftingSlots[6].parentElement.parentElement.title = "granite"
            craftingSlots[7].parentElement.parentElement.title = "granite"
            craftingSlots[8].parentElement.parentElement.title = "granite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "granite"
            craftingSlots[4].parentElement.parentElement.alt = "granite"
            craftingSlots[5].parentElement.parentElement.alt = "granite"
            craftingSlots[6].parentElement.parentElement.alt = "granite"
            craftingSlots[7].parentElement.parentElement.alt = "granite"
            craftingSlots[8].parentElement.parentElement.alt = "granite"
            break;
            
        case "gray_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gray_wool
            craftingSlots[1].src = all.gray_wool
            craftingSlots[2].src = all.gray_wool
            craftingSlots[3].src = all.gray_wool
            craftingSlots[4].src = all.gray_wool
            craftingSlots[5].src = all.gray_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gray wool"
            craftingSlots[1].parentElement.parentElement.title = "gray wool"
            craftingSlots[2].parentElement.parentElement.title = "gray wool"
            craftingSlots[3].parentElement.parentElement.title = "gray wool"
            craftingSlots[4].parentElement.parentElement.title = "gray wool"
            craftingSlots[5].parentElement.parentElement.title = "gray wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "gray wool"
            craftingSlots[1].parentElement.parentElement.alt = "gray wool"
            craftingSlots[2].parentElement.parentElement.alt = "gray wool"
            craftingSlots[3].parentElement.parentElement.alt = "gray wool"
            craftingSlots[4].parentElement.parentElement.alt = "gray wool"
            craftingSlots[5].parentElement.parentElement.alt = "gray wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gray_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gray_wool
            craftingSlots[4].src = all.gray_wool
            craftingSlots[5].src = all.gray_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gray wool"
            craftingSlots[4].parentElement.parentElement.title = "gray wool"
            craftingSlots[5].parentElement.parentElement.title = "gray wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gray wool"
            craftingSlots[4].parentElement.parentElement.alt = "gray wool"
            craftingSlots[5].parentElement.parentElement.alt = "gray wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "gray_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.gray_wool
            craftingSlots[7].src = all.gray_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "gray wool"
            craftingSlots[7].parentElement.parentElement.title = "gray wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "gray wool"
            craftingSlots[7].parentElement.parentElement.alt = "gray wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gray_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.gray_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "gray dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "gray_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gray_stained_glass
            craftingSlots[4].src = all.gray_stained_glass
            craftingSlots[5].src = all.gray_stained_glass
            craftingSlots[6].src = all.gray_stained_glass
            craftingSlots[7].src = all.gray_stained_glass
            craftingSlots[8].src = all.gray_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gray stained glass"
            craftingSlots[4].parentElement.parentElement.title = "gray stained glass"
            craftingSlots[5].parentElement.parentElement.title = "gray stained glass"
            craftingSlots[6].parentElement.parentElement.title = "gray stained glass"
            craftingSlots[7].parentElement.parentElement.title = "gray stained glass"
            craftingSlots[8].parentElement.parentElement.title = "gray stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gray stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "gray stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "gray stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "gray stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "gray stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "gray stained glass"
            break;
            
        case "gray_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.gray_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "gray dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "gray_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.gray_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "gray dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "green_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.green_wool
            craftingSlots[1].src = all.green_wool
            craftingSlots[2].src = all.green_wool
            craftingSlots[3].src = all.green_wool
            craftingSlots[4].src = all.green_wool
            craftingSlots[5].src = all.green_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "green wool"
            craftingSlots[1].parentElement.parentElement.title = "green wool"
            craftingSlots[2].parentElement.parentElement.title = "green wool"
            craftingSlots[3].parentElement.parentElement.title = "green wool"
            craftingSlots[4].parentElement.parentElement.title = "green wool"
            craftingSlots[5].parentElement.parentElement.title = "green wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "green wool"
            craftingSlots[1].parentElement.parentElement.alt = "green wool"
            craftingSlots[2].parentElement.parentElement.alt = "green wool"
            craftingSlots[3].parentElement.parentElement.alt = "green wool"
            craftingSlots[4].parentElement.parentElement.alt = "green wool"
            craftingSlots[5].parentElement.parentElement.alt = "green wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "green_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.green_wool
            craftingSlots[4].src = all.green_wool
            craftingSlots[5].src = all.green_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "green wool"
            craftingSlots[4].parentElement.parentElement.title = "green wool"
            craftingSlots[5].parentElement.parentElement.title = "green wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "green wool"
            craftingSlots[4].parentElement.parentElement.alt = "green wool"
            craftingSlots[5].parentElement.parentElement.alt = "green wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "green_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.green_wool
            craftingSlots[7].src = all.green_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "green wool"
            craftingSlots[7].parentElement.parentElement.title = "green wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "green wool"
            craftingSlots[7].parentElement.parentElement.alt = "green wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "green_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.green_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "green dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "green dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "green_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.green_stained_glass
            craftingSlots[4].src = all.green_stained_glass
            craftingSlots[5].src = all.green_stained_glass
            craftingSlots[6].src = all.green_stained_glass
            craftingSlots[7].src = all.green_stained_glass
            craftingSlots[8].src = all.green_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "green stained glass"
            craftingSlots[4].parentElement.parentElement.title = "green stained glass"
            craftingSlots[5].parentElement.parentElement.title = "green stained glass"
            craftingSlots[6].parentElement.parentElement.title = "green stained glass"
            craftingSlots[7].parentElement.parentElement.title = "green stained glass"
            craftingSlots[8].parentElement.parentElement.title = "green stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "green stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "green stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "green stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "green stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "green stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "green stained glass"
            break;
            
        case "green_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.green_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "green dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "green dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "green_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.green_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "green dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "green dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "grindstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.stone_slab
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "stone slab"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "stone slab"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "heavy_weighted_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "honey_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.honey_bottle
            craftingSlots[4].src = all.honey_bottle
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.honey_bottle
            craftingSlots[7].src = all.honey_bottle
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "honey bottle"
            craftingSlots[4].parentElement.parentElement.title = "honey bottle"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "honey bottle"
            craftingSlots[7].parentElement.parentElement.title = "honey bottle"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "honey bottle"
            craftingSlots[4].parentElement.parentElement.alt = "honey bottle"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "honey bottle"
            craftingSlots[7].parentElement.parentElement.alt = "honey bottle"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "honeycomb_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.honeycomb
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.honeycomb
            craftingSlots[7].src = all.honeycomb
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "honeycomb"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "honeycomb"
            craftingSlots[7].parentElement.parentElement.title = "honeycomb"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[7].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "hopper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.chest
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "chest"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "chest"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "host_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.host_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.terracotta
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "host armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "terracotta"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "host armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "terracotta"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "host_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "iron_axe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "iron_bars":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "iron_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "iron_boots":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "iron_chestplate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "iron_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "iron_helmet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "iron_hoe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "iron_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_nugget
            craftingSlots[1].src = all.iron_nugget
            craftingSlots[2].src = all.iron_nugget
            craftingSlots[3].src = all.iron_nugget
            craftingSlots[4].src = all.iron_nugget
            craftingSlots[5].src = all.iron_nugget
            craftingSlots[6].src = all.iron_nugget
            craftingSlots[7].src = all.iron_nugget
            craftingSlots[8].src = all.iron_nugget
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron nugget"
            craftingSlots[1].parentElement.parentElement.title = "iron nugget"
            craftingSlots[2].parentElement.parentElement.title = "iron nugget"
            craftingSlots[3].parentElement.parentElement.title = "iron nugget"
            craftingSlots[4].parentElement.parentElement.title = "iron nugget"
            craftingSlots[5].parentElement.parentElement.title = "iron nugget"
            craftingSlots[6].parentElement.parentElement.title = "iron nugget"
            craftingSlots[7].parentElement.parentElement.title = "iron nugget"
            craftingSlots[8].parentElement.parentElement.title = "iron nugget"

            craftingSlots[0].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[1].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[2].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[3].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[4].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[5].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[6].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[7].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[8].parentElement.parentElement.alt = "iron nugget"
            break;
            
        case "iron_leggings":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "iron_pickaxe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "iron_shovel":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "iron_sword":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "iron_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "item_frame":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.stick
            craftingSlots[1].src = all.stick
            craftingSlots[2].src = all.stick
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.leather
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stick"
            craftingSlots[1].parentElement.parentElement.title = "stick"
            craftingSlots[2].parentElement.parentElement.title = "stick"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "leather"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "stick"
            craftingSlots[1].parentElement.parentElement.alt = "stick"
            craftingSlots[2].parentElement.parentElement.alt = "stick"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "leather"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "jack_o_lantern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.carved_pumpkin
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.torch
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "carved pumpkin"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "torch"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "carved pumpkin"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "torch"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "jukebox":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.diamond
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "diamond"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "diamond"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "jungle_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.jungle_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.jungle_planks
            craftingSlots[6].src = all.jungle_planks
            craftingSlots[7].src = all.jungle_planks
            craftingSlots[8].src = all.jungle_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "jungle planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "jungle planks"
            craftingSlots[6].parentElement.parentElement.title = "jungle planks"
            craftingSlots[7].parentElement.parentElement.title = "jungle planks"
            craftingSlots[8].parentElement.parentElement.title = "jungle planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[6].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[7].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[8].parentElement.parentElement.alt = "jungle planks"
            break;
            
        case "jungle_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.jungle_planks
            craftingSlots[1].src = all.jungle_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.jungle_planks
            craftingSlots[4].src = all.jungle_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.jungle_planks
            craftingSlots[7].src = all.jungle_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "jungle planks"
            craftingSlots[1].parentElement.parentElement.title = "jungle planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "jungle planks"
            craftingSlots[4].parentElement.parentElement.title = "jungle planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "jungle planks"
            craftingSlots[7].parentElement.parentElement.title = "jungle planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[1].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[4].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[7].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "jungle_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.jungle_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.jungle_planks
            craftingSlots[6].src = all.jungle_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.jungle_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "jungle planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "jungle planks"
            craftingSlots[6].parentElement.parentElement.title = "jungle planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "jungle planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[6].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "jungle planks"
            break;
            
        case "jungle_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.jungle_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.jungle_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "jungle planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "jungle planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "jungle_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_jungle_log
            craftingSlots[4].src = all.stripped_jungle_log
            craftingSlots[5].src = all.stripped_jungle_log
            craftingSlots[6].src = all.stripped_jungle_log
            craftingSlots[7].src = all.stripped_jungle_log
            craftingSlots[8].src = all.stripped_jungle_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[4].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[5].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[6].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[7].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[8].parentElement.parentElement.title = "stripped jungle log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped jungle log"
            break;
            
        case "jungle_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.jungle_planks
            craftingSlots[7].src = all.jungle_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "jungle planks"
            craftingSlots[7].parentElement.parentElement.title = "jungle planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[7].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "jungle_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.jungle_planks
            craftingSlots[1].src = all.jungle_planks
            craftingSlots[2].src = all.jungle_planks
            craftingSlots[3].src = all.jungle_planks
            craftingSlots[4].src = all.jungle_planks
            craftingSlots[5].src = all.jungle_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "jungle planks"
            craftingSlots[1].parentElement.parentElement.title = "jungle planks"
            craftingSlots[2].parentElement.parentElement.title = "jungle planks"
            craftingSlots[3].parentElement.parentElement.title = "jungle planks"
            craftingSlots[4].parentElement.parentElement.title = "jungle planks"
            craftingSlots[5].parentElement.parentElement.title = "jungle planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[1].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[2].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[3].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[4].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[5].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "jungle_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.jungle_planks
            craftingSlots[7].src = all.jungle_planks
            craftingSlots[8].src = all.jungle_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "jungle planks"
            craftingSlots[7].parentElement.parentElement.title = "jungle planks"
            craftingSlots[8].parentElement.parentElement.title = "jungle planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[7].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[8].parentElement.parentElement.alt = "jungle planks"
            break;
            
        case "jungle_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.jungle_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.jungle_planks
            craftingSlots[4].src = all.jungle_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.jungle_planks
            craftingSlots[7].src = all.jungle_planks
            craftingSlots[8].src = all.jungle_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "jungle planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "jungle planks"
            craftingSlots[4].parentElement.parentElement.title = "jungle planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "jungle planks"
            craftingSlots[7].parentElement.parentElement.title = "jungle planks"
            craftingSlots[8].parentElement.parentElement.title = "jungle planks"

            craftingSlots[0].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[4].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[7].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[8].parentElement.parentElement.alt = "jungle planks"
            break;
            
        case "jungle_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.jungle_planks
            craftingSlots[4].src = all.jungle_planks
            craftingSlots[5].src = all.jungle_planks
            craftingSlots[6].src = all.jungle_planks
            craftingSlots[7].src = all.jungle_planks
            craftingSlots[8].src = all.jungle_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "jungle planks"
            craftingSlots[4].parentElement.parentElement.title = "jungle planks"
            craftingSlots[5].parentElement.parentElement.title = "jungle planks"
            craftingSlots[6].parentElement.parentElement.title = "jungle planks"
            craftingSlots[7].parentElement.parentElement.title = "jungle planks"
            craftingSlots[8].parentElement.parentElement.title = "jungle planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[4].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[5].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[6].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[7].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[8].parentElement.parentElement.alt = "jungle planks"
            break;
            
        case "jungle_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.jungle_log
            craftingSlots[4].src = all.jungle_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.jungle_log
            craftingSlots[7].src = all.jungle_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "jungle log"
            craftingSlots[4].parentElement.parentElement.title = "jungle log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "jungle log"
            craftingSlots[7].parentElement.parentElement.title = "jungle log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "jungle log"
            craftingSlots[4].parentElement.parentElement.alt = "jungle log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "jungle log"
            craftingSlots[7].parentElement.parentElement.alt = "jungle log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "ladder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.stick
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.stick
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stick"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "stick"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "stick"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "stick"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "lantern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_nugget
            craftingSlots[1].src = all.iron_nugget
            craftingSlots[2].src = all.iron_nugget
            craftingSlots[3].src = all.iron_nugget
            craftingSlots[4].src = all.torch
            craftingSlots[5].src = all.iron_nugget
            craftingSlots[6].src = all.iron_nugget
            craftingSlots[7].src = all.iron_nugget
            craftingSlots[8].src = all.iron_nugget
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron nugget"
            craftingSlots[1].parentElement.parentElement.title = "iron nugget"
            craftingSlots[2].parentElement.parentElement.title = "iron nugget"
            craftingSlots[3].parentElement.parentElement.title = "iron nugget"
            craftingSlots[4].parentElement.parentElement.title = "torch"
            craftingSlots[5].parentElement.parentElement.title = "iron nugget"
            craftingSlots[6].parentElement.parentElement.title = "iron nugget"
            craftingSlots[7].parentElement.parentElement.title = "iron nugget"
            craftingSlots[8].parentElement.parentElement.title = "iron nugget"

            craftingSlots[0].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[1].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[2].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[3].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[4].parentElement.parentElement.alt = "torch"
            craftingSlots[5].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[6].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[7].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[8].parentElement.parentElement.alt = "iron nugget"
            break;
            
        case "lapis_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.lapis_lazuli
            craftingSlots[1].src = all.lapis_lazuli
            craftingSlots[2].src = all.lapis_lazuli
            craftingSlots[3].src = all.lapis_lazuli
            craftingSlots[4].src = all.lapis_lazuli
            craftingSlots[5].src = all.lapis_lazuli
            craftingSlots[6].src = all.lapis_lazuli
            craftingSlots[7].src = all.lapis_lazuli
            craftingSlots[8].src = all.lapis_lazuli
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[1].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[2].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[3].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[4].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[5].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[6].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[7].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[8].parentElement.parentElement.title = "lapis lazuli"

            craftingSlots[0].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[1].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[2].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[3].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[4].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[5].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[6].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[7].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[8].parentElement.parentElement.alt = "lapis lazuli"
            break;
            
        case "lead":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.string
            craftingSlots[1].src = all.string
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.string
            craftingSlots[4].src = all.slime_ball
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.string
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "string"
            craftingSlots[1].parentElement.parentElement.title = "string"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "string"
            craftingSlots[4].parentElement.parentElement.title = "slime ball"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "string"

            craftingSlots[0].parentElement.parentElement.alt = "string"
            craftingSlots[1].parentElement.parentElement.alt = "string"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "string"
            craftingSlots[4].parentElement.parentElement.alt = "slime ball"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "string"
            break;
            
        case "leather":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.rabbit_hide
            craftingSlots[4].src = all.rabbit_hide
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.rabbit_hide
            craftingSlots[7].src = all.rabbit_hide
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "rabbit hide"
            craftingSlots[4].parentElement.parentElement.title = "rabbit hide"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "rabbit hide"
            craftingSlots[7].parentElement.parentElement.title = "rabbit hide"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "rabbit hide"
            craftingSlots[4].parentElement.parentElement.alt = "rabbit hide"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "rabbit hide"
            craftingSlots[7].parentElement.parentElement.alt = "rabbit hide"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "leather_boots":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.leather
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.leather
            craftingSlots[6].src = all.leather
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.leather
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "leather"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "leather"
            craftingSlots[6].parentElement.parentElement.title = "leather"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "leather"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "leather"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "leather"
            craftingSlots[6].parentElement.parentElement.alt = "leather"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "leather"
            break;
            
        case "leather_chestplate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.leather
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.leather
            craftingSlots[3].src = all.leather
            craftingSlots[4].src = all.leather
            craftingSlots[5].src = all.leather
            craftingSlots[6].src = all.leather
            craftingSlots[7].src = all.leather
            craftingSlots[8].src = all.leather
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "leather"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "leather"
            craftingSlots[3].parentElement.parentElement.title = "leather"
            craftingSlots[4].parentElement.parentElement.title = "leather"
            craftingSlots[5].parentElement.parentElement.title = "leather"
            craftingSlots[6].parentElement.parentElement.title = "leather"
            craftingSlots[7].parentElement.parentElement.title = "leather"
            craftingSlots[8].parentElement.parentElement.title = "leather"

            craftingSlots[0].parentElement.parentElement.alt = "leather"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "leather"
            craftingSlots[3].parentElement.parentElement.alt = "leather"
            craftingSlots[4].parentElement.parentElement.alt = "leather"
            craftingSlots[5].parentElement.parentElement.alt = "leather"
            craftingSlots[6].parentElement.parentElement.alt = "leather"
            craftingSlots[7].parentElement.parentElement.alt = "leather"
            craftingSlots[8].parentElement.parentElement.alt = "leather"
            break;
            
        case "leather_helmet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.leather
            craftingSlots[4].src = all.leather
            craftingSlots[5].src = all.leather
            craftingSlots[6].src = all.leather
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.leather
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "leather"
            craftingSlots[4].parentElement.parentElement.title = "leather"
            craftingSlots[5].parentElement.parentElement.title = "leather"
            craftingSlots[6].parentElement.parentElement.title = "leather"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "leather"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "leather"
            craftingSlots[4].parentElement.parentElement.alt = "leather"
            craftingSlots[5].parentElement.parentElement.alt = "leather"
            craftingSlots[6].parentElement.parentElement.alt = "leather"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "leather"
            break;
            
        case "leather_horse_armor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.leather
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.leather
            craftingSlots[3].src = all.leather
            craftingSlots[4].src = all.leather
            craftingSlots[5].src = all.leather
            craftingSlots[6].src = all.leather
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.leather
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "leather"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "leather"
            craftingSlots[3].parentElement.parentElement.title = "leather"
            craftingSlots[4].parentElement.parentElement.title = "leather"
            craftingSlots[5].parentElement.parentElement.title = "leather"
            craftingSlots[6].parentElement.parentElement.title = "leather"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "leather"

            craftingSlots[0].parentElement.parentElement.alt = "leather"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "leather"
            craftingSlots[3].parentElement.parentElement.alt = "leather"
            craftingSlots[4].parentElement.parentElement.alt = "leather"
            craftingSlots[5].parentElement.parentElement.alt = "leather"
            craftingSlots[6].parentElement.parentElement.alt = "leather"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "leather"
            break;
            
        case "leather_leggings":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.leather
            craftingSlots[1].src = all.leather
            craftingSlots[2].src = all.leather
            craftingSlots[3].src = all.leather
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.leather
            craftingSlots[6].src = all.leather
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.leather
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "leather"
            craftingSlots[1].parentElement.parentElement.title = "leather"
            craftingSlots[2].parentElement.parentElement.title = "leather"
            craftingSlots[3].parentElement.parentElement.title = "leather"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "leather"
            craftingSlots[6].parentElement.parentElement.title = "leather"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "leather"

            craftingSlots[0].parentElement.parentElement.alt = "leather"
            craftingSlots[1].parentElement.parentElement.alt = "leather"
            craftingSlots[2].parentElement.parentElement.alt = "leather"
            craftingSlots[3].parentElement.parentElement.alt = "leather"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "leather"
            craftingSlots[6].parentElement.parentElement.alt = "leather"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "leather"
            break;
            
        case "lectern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_slab
            craftingSlots[1].src = all.oak_slab
            craftingSlots[2].src = all.oak_slab
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.bookshelf
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.oak_slab
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[1].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[2].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "bookshelf"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[1].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[2].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "bookshelf"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "wooden slabs"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lever":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.light_blue_wool
            craftingSlots[1].src = all.light_blue_wool
            craftingSlots[2].src = all.light_blue_wool
            craftingSlots[3].src = all.light_blue_wool
            craftingSlots[4].src = all.light_blue_wool
            craftingSlots[5].src = all.light_blue_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "light blue wool"
            craftingSlots[1].parentElement.parentElement.title = "light blue wool"
            craftingSlots[2].parentElement.parentElement.title = "light blue wool"
            craftingSlots[3].parentElement.parentElement.title = "light blue wool"
            craftingSlots[4].parentElement.parentElement.title = "light blue wool"
            craftingSlots[5].parentElement.parentElement.title = "light blue wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[1].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[2].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[3].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[4].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[5].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_blue_wool
            craftingSlots[4].src = all.light_blue_wool
            craftingSlots[5].src = all.light_blue_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light blue wool"
            craftingSlots[4].parentElement.parentElement.title = "light blue wool"
            craftingSlots[5].parentElement.parentElement.title = "light blue wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[4].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[5].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "light_blue_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.light_blue_wool
            craftingSlots[7].src = all.light_blue_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "light blue wool"
            craftingSlots[7].parentElement.parentElement.title = "light blue wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[7].parentElement.parentElement.alt = "light blue wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.light_blue_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "light blue dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "light_blue_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_blue_stained_glass
            craftingSlots[4].src = all.light_blue_stained_glass
            craftingSlots[5].src = all.light_blue_stained_glass
            craftingSlots[6].src = all.light_blue_stained_glass
            craftingSlots[7].src = all.light_blue_stained_glass
            craftingSlots[8].src = all.light_blue_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light blue stained glass"
            craftingSlots[4].parentElement.parentElement.title = "light blue stained glass"
            craftingSlots[5].parentElement.parentElement.title = "light blue stained glass"
            craftingSlots[6].parentElement.parentElement.title = "light blue stained glass"
            craftingSlots[7].parentElement.parentElement.title = "light blue stained glass"
            craftingSlots[8].parentElement.parentElement.title = "light blue stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light blue stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "light blue stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "light blue stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "light blue stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "light blue stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "light blue stained glass"
            break;
            
        case "light_blue_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.light_blue_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "light blue dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "light_blue_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.light_blue_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "light blue dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "light_gray_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.light_gray_wool
            craftingSlots[1].src = all.light_gray_wool
            craftingSlots[2].src = all.light_gray_wool
            craftingSlots[3].src = all.light_gray_wool
            craftingSlots[4].src = all.light_gray_wool
            craftingSlots[5].src = all.light_gray_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "light gray wool"
            craftingSlots[1].parentElement.parentElement.title = "light gray wool"
            craftingSlots[2].parentElement.parentElement.title = "light gray wool"
            craftingSlots[3].parentElement.parentElement.title = "light gray wool"
            craftingSlots[4].parentElement.parentElement.title = "light gray wool"
            craftingSlots[5].parentElement.parentElement.title = "light gray wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[1].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[2].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[3].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[4].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[5].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_gray_wool
            craftingSlots[4].src = all.light_gray_wool
            craftingSlots[5].src = all.light_gray_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light gray wool"
            craftingSlots[4].parentElement.parentElement.title = "light gray wool"
            craftingSlots[5].parentElement.parentElement.title = "light gray wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[4].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[5].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "light_gray_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.light_gray_wool
            craftingSlots[7].src = all.light_gray_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "light gray wool"
            craftingSlots[7].parentElement.parentElement.title = "light gray wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[7].parentElement.parentElement.alt = "light gray wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.light_gray_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "light gray dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "light_gray_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_gray_stained_glass
            craftingSlots[4].src = all.light_gray_stained_glass
            craftingSlots[5].src = all.light_gray_stained_glass
            craftingSlots[6].src = all.light_gray_stained_glass
            craftingSlots[7].src = all.light_gray_stained_glass
            craftingSlots[8].src = all.light_gray_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light gray stained glass"
            craftingSlots[4].parentElement.parentElement.title = "light gray stained glass"
            craftingSlots[5].parentElement.parentElement.title = "light gray stained glass"
            craftingSlots[6].parentElement.parentElement.title = "light gray stained glass"
            craftingSlots[7].parentElement.parentElement.title = "light gray stained glass"
            craftingSlots[8].parentElement.parentElement.title = "light gray stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light gray stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "light gray stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "light gray stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "light gray stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "light gray stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "light gray stained glass"
            break;
            
        case "light_gray_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.light_gray_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "light gray dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "light_gray_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.light_gray_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "light gray dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "light_weighted_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.gold_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "gold ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lightning_rod":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.copper_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.copper_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "copper ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "copper ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.lime_wool
            craftingSlots[1].src = all.lime_wool
            craftingSlots[2].src = all.lime_wool
            craftingSlots[3].src = all.lime_wool
            craftingSlots[4].src = all.lime_wool
            craftingSlots[5].src = all.lime_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "lime wool"
            craftingSlots[1].parentElement.parentElement.title = "lime wool"
            craftingSlots[2].parentElement.parentElement.title = "lime wool"
            craftingSlots[3].parentElement.parentElement.title = "lime wool"
            craftingSlots[4].parentElement.parentElement.title = "lime wool"
            craftingSlots[5].parentElement.parentElement.title = "lime wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "lime wool"
            craftingSlots[1].parentElement.parentElement.alt = "lime wool"
            craftingSlots[2].parentElement.parentElement.alt = "lime wool"
            craftingSlots[3].parentElement.parentElement.alt = "lime wool"
            craftingSlots[4].parentElement.parentElement.alt = "lime wool"
            craftingSlots[5].parentElement.parentElement.alt = "lime wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.lime_wool
            craftingSlots[4].src = all.lime_wool
            craftingSlots[5].src = all.lime_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "lime wool"
            craftingSlots[4].parentElement.parentElement.title = "lime wool"
            craftingSlots[5].parentElement.parentElement.title = "lime wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "lime wool"
            craftingSlots[4].parentElement.parentElement.alt = "lime wool"
            craftingSlots[5].parentElement.parentElement.alt = "lime wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "lime_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.lime_wool
            craftingSlots[7].src = all.lime_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "lime wool"
            craftingSlots[7].parentElement.parentElement.title = "lime wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "lime wool"
            craftingSlots[7].parentElement.parentElement.alt = "lime wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.lime_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "lime dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "lime dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "lime_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.lime_stained_glass
            craftingSlots[4].src = all.lime_stained_glass
            craftingSlots[5].src = all.lime_stained_glass
            craftingSlots[6].src = all.lime_stained_glass
            craftingSlots[7].src = all.lime_stained_glass
            craftingSlots[8].src = all.lime_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "lime stained glass"
            craftingSlots[4].parentElement.parentElement.title = "lime stained glass"
            craftingSlots[5].parentElement.parentElement.title = "lime stained glass"
            craftingSlots[6].parentElement.parentElement.title = "lime stained glass"
            craftingSlots[7].parentElement.parentElement.title = "lime stained glass"
            craftingSlots[8].parentElement.parentElement.title = "lime stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "lime stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "lime stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "lime stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "lime stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "lime stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "lime stained glass"
            break;
            
        case "lime_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.lime_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "lime dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "lime dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "lime_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.lime_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "lime dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "lime dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "lodestone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chiseled_stone_bricks
            craftingSlots[1].src = all.chiseled_stone_bricks
            craftingSlots[2].src = all.chiseled_stone_bricks
            craftingSlots[3].src = all.chiseled_stone_bricks
            craftingSlots[4].src = all.netherite_ingot
            craftingSlots[5].src = all.chiseled_stone_bricks
            craftingSlots[6].src = all.chiseled_stone_bricks
            craftingSlots[7].src = all.chiseled_stone_bricks
            craftingSlots[8].src = all.chiseled_stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chiseled stone bricks"
            craftingSlots[1].parentElement.parentElement.title = "chiseled stone bricks"
            craftingSlots[2].parentElement.parentElement.title = "chiseled stone bricks"
            craftingSlots[3].parentElement.parentElement.title = "chiseled stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[5].parentElement.parentElement.title = "chiseled stone bricks"
            craftingSlots[6].parentElement.parentElement.title = "chiseled stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "chiseled stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "chiseled stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "chiseled stone bricks"
            craftingSlots[1].parentElement.parentElement.alt = "chiseled stone bricks"
            craftingSlots[2].parentElement.parentElement.alt = "chiseled stone bricks"
            craftingSlots[3].parentElement.parentElement.alt = "chiseled stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[5].parentElement.parentElement.alt = "chiseled stone bricks"
            craftingSlots[6].parentElement.parentElement.alt = "chiseled stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "chiseled stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "chiseled stone bricks"
            break;
            
        case "loom":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.string
            craftingSlots[4].src = all.string
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "string"
            craftingSlots[4].parentElement.parentElement.title = "string"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "string"
            craftingSlots[4].parentElement.parentElement.alt = "string"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mace":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.heavy_core
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.breeze_rod
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "heavy core"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "breeze rod"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "heavy core"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "breeze rod"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.magenta_wool
            craftingSlots[1].src = all.magenta_wool
            craftingSlots[2].src = all.magenta_wool
            craftingSlots[3].src = all.magenta_wool
            craftingSlots[4].src = all.magenta_wool
            craftingSlots[5].src = all.magenta_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "magenta wool"
            craftingSlots[1].parentElement.parentElement.title = "magenta wool"
            craftingSlots[2].parentElement.parentElement.title = "magenta wool"
            craftingSlots[3].parentElement.parentElement.title = "magenta wool"
            craftingSlots[4].parentElement.parentElement.title = "magenta wool"
            craftingSlots[5].parentElement.parentElement.title = "magenta wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[1].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[2].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[3].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[4].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[5].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.magenta_wool
            craftingSlots[4].src = all.magenta_wool
            craftingSlots[5].src = all.magenta_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "magenta wool"
            craftingSlots[4].parentElement.parentElement.title = "magenta wool"
            craftingSlots[5].parentElement.parentElement.title = "magenta wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[4].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[5].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "magenta_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.magenta_wool
            craftingSlots[7].src = all.magenta_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "magenta wool"
            craftingSlots[7].parentElement.parentElement.title = "magenta wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[7].parentElement.parentElement.alt = "magenta wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.magenta_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "magenta dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "magenta_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.magenta_stained_glass
            craftingSlots[4].src = all.magenta_stained_glass
            craftingSlots[5].src = all.magenta_stained_glass
            craftingSlots[6].src = all.magenta_stained_glass
            craftingSlots[7].src = all.magenta_stained_glass
            craftingSlots[8].src = all.magenta_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "magenta stained glass"
            craftingSlots[4].parentElement.parentElement.title = "magenta stained glass"
            craftingSlots[5].parentElement.parentElement.title = "magenta stained glass"
            craftingSlots[6].parentElement.parentElement.title = "magenta stained glass"
            craftingSlots[7].parentElement.parentElement.title = "magenta stained glass"
            craftingSlots[8].parentElement.parentElement.title = "magenta stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "magenta stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "magenta stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "magenta stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "magenta stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "magenta stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "magenta stained glass"
            break;
            
        case "magenta_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.magenta_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "magenta dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "magenta_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.magenta_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "magenta dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "magma_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.magma_cream
            craftingSlots[4].src = all.magma_cream
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.magma_cream
            craftingSlots[7].src = all.magma_cream
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "magma cream"
            craftingSlots[4].parentElement.parentElement.title = "magma cream"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "magma cream"
            craftingSlots[7].parentElement.parentElement.title = "magma cream"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "magma cream"
            craftingSlots[4].parentElement.parentElement.alt = "magma cream"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "magma cream"
            craftingSlots[7].parentElement.parentElement.alt = "magma cream"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mangrove_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mangrove_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.mangrove_planks
            craftingSlots[6].src = all.mangrove_planks
            craftingSlots[7].src = all.mangrove_planks
            craftingSlots[8].src = all.mangrove_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.title = "mangrove planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.alt = "mangrove planks"
            break;
            
        case "mangrove_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.mangrove_planks
            craftingSlots[1].src = all.mangrove_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mangrove_planks
            craftingSlots[4].src = all.mangrove_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mangrove_planks
            craftingSlots[7].src = all.mangrove_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[1].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[1].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mangrove_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mangrove_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.mangrove_planks
            craftingSlots[6].src = all.mangrove_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.mangrove_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "mangrove planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "mangrove planks"
            break;
            
        case "mangrove_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.mangrove_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.mangrove_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "mangrove_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_mangrove_log
            craftingSlots[4].src = all.stripped_mangrove_log
            craftingSlots[5].src = all.stripped_mangrove_log
            craftingSlots[6].src = all.stripped_mangrove_log
            craftingSlots[7].src = all.stripped_mangrove_log
            craftingSlots[8].src = all.stripped_mangrove_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[4].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[5].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[6].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[7].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[8].parentElement.parentElement.title = "stripped mangrove log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped mangrove log"
            break;
            
        case "mangrove_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mangrove_planks
            craftingSlots[7].src = all.mangrove_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mangrove_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.mangrove_planks
            craftingSlots[1].src = all.mangrove_planks
            craftingSlots[2].src = all.mangrove_planks
            craftingSlots[3].src = all.mangrove_planks
            craftingSlots[4].src = all.mangrove_planks
            craftingSlots[5].src = all.mangrove_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[1].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[2].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[3].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[1].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[2].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[3].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mangrove_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mangrove_planks
            craftingSlots[7].src = all.mangrove_planks
            craftingSlots[8].src = all.mangrove_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.title = "mangrove planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.alt = "mangrove planks"
            break;
            
        case "mangrove_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.mangrove_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mangrove_planks
            craftingSlots[4].src = all.mangrove_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mangrove_planks
            craftingSlots[7].src = all.mangrove_planks
            craftingSlots[8].src = all.mangrove_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.title = "mangrove planks"

            craftingSlots[0].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.alt = "mangrove planks"
            break;
            
        case "mangrove_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mangrove_planks
            craftingSlots[4].src = all.mangrove_planks
            craftingSlots[5].src = all.mangrove_planks
            craftingSlots[6].src = all.mangrove_planks
            craftingSlots[7].src = all.mangrove_planks
            craftingSlots[8].src = all.mangrove_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.title = "mangrove planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[8].parentElement.parentElement.alt = "mangrove planks"
            break;
            
        case "mangrove_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mangrove_log
            craftingSlots[4].src = all.mangrove_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mangrove_log
            craftingSlots[7].src = all.mangrove_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mangrove log"
            craftingSlots[4].parentElement.parentElement.title = "mangrove log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mangrove log"
            craftingSlots[7].parentElement.parentElement.title = "mangrove log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mangrove log"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mangrove log"
            craftingSlots[7].parentElement.parentElement.alt = "mangrove log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "map":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.paper
            craftingSlots[1].src = all.paper
            craftingSlots[2].src = all.paper
            craftingSlots[3].src = all.paper
            craftingSlots[4].src = all.compass
            craftingSlots[5].src = all.paper
            craftingSlots[6].src = all.paper
            craftingSlots[7].src = all.paper
            craftingSlots[8].src = all.paper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "paper"
            craftingSlots[1].parentElement.parentElement.title = "paper"
            craftingSlots[2].parentElement.parentElement.title = "paper"
            craftingSlots[3].parentElement.parentElement.title = "paper"
            craftingSlots[4].parentElement.parentElement.title = "compass"
            craftingSlots[5].parentElement.parentElement.title = "paper"
            craftingSlots[6].parentElement.parentElement.title = "paper"
            craftingSlots[7].parentElement.parentElement.title = "paper"
            craftingSlots[8].parentElement.parentElement.title = "paper"

            craftingSlots[0].parentElement.parentElement.alt = "paper"
            craftingSlots[1].parentElement.parentElement.alt = "paper"
            craftingSlots[2].parentElement.parentElement.alt = "paper"
            craftingSlots[3].parentElement.parentElement.alt = "paper"
            craftingSlots[4].parentElement.parentElement.alt = "compass"
            craftingSlots[5].parentElement.parentElement.alt = "paper"
            craftingSlots[6].parentElement.parentElement.alt = "paper"
            craftingSlots[7].parentElement.parentElement.alt = "paper"
            craftingSlots[8].parentElement.parentElement.alt = "paper"
            break;
            
                case "map":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
                case "map":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "minecart":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.iron_ingot
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "iron ingot"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "moss_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.moss_block
            craftingSlots[7].src = all.moss_block
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "moss block"
            craftingSlots[7].parentElement.parentElement.title = "moss block"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "moss block"
            craftingSlots[7].parentElement.parentElement.alt = "moss block"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mossy_cobblestone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mossy_cobblestone
            craftingSlots[7].src = all.mossy_cobblestone
            craftingSlots[8].src = all.mossy_cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "mossy cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "mossy cobblestone"
            break;
            
        case "mossy_cobblestone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.mossy_cobblestone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mossy_cobblestone
            craftingSlots[4].src = all.mossy_cobblestone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mossy_cobblestone
            craftingSlots[7].src = all.mossy_cobblestone
            craftingSlots[8].src = all.mossy_cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "mossy cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "mossy cobblestone"
            break;
            
        case "mossy_cobblestone_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mossy_cobblestone
            craftingSlots[4].src = all.mossy_cobblestone
            craftingSlots[5].src = all.mossy_cobblestone
            craftingSlots[6].src = all.mossy_cobblestone
            craftingSlots[7].src = all.mossy_cobblestone
            craftingSlots[8].src = all.mossy_cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[6].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "mossy cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[6].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "mossy cobblestone"
            break;
            
        case "mossy_stone_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mossy_stone_bricks
            craftingSlots[7].src = all.mossy_stone_bricks
            craftingSlots[8].src = all.mossy_stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "mossy stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "mossy stone bricks"
            break;
            
        case "mossy_stone_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.mossy_stone_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mossy_stone_bricks
            craftingSlots[4].src = all.mossy_stone_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mossy_stone_bricks
            craftingSlots[7].src = all.mossy_stone_bricks
            craftingSlots[8].src = all.mossy_stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "mossy stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "mossy stone bricks"
            break;
            
        case "mossy_stone_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mossy_stone_bricks
            craftingSlots[4].src = all.mossy_stone_bricks
            craftingSlots[5].src = all.mossy_stone_bricks
            craftingSlots[6].src = all.mossy_stone_bricks
            craftingSlots[7].src = all.mossy_stone_bricks
            craftingSlots[8].src = all.mossy_stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[5].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[6].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "mossy stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "mossy stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[6].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "mossy stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "mossy stone bricks"
            break;
            
        case "mud_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mud_bricks
            craftingSlots[7].src = all.mud_bricks
            craftingSlots[8].src = all.mud_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mud bricks"
            craftingSlots[7].parentElement.parentElement.title = "mud bricks"
            craftingSlots[8].parentElement.parentElement.title = "mud bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[7].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[8].parentElement.parentElement.alt = "mud bricks"
            break;
            
        case "mud_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.mud_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mud_bricks
            craftingSlots[4].src = all.mud_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.mud_bricks
            craftingSlots[7].src = all.mud_bricks
            craftingSlots[8].src = all.mud_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "mud bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mud bricks"
            craftingSlots[4].parentElement.parentElement.title = "mud bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "mud bricks"
            craftingSlots[7].parentElement.parentElement.title = "mud bricks"
            craftingSlots[8].parentElement.parentElement.title = "mud bricks"

            craftingSlots[0].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[4].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[7].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[8].parentElement.parentElement.alt = "mud bricks"
            break;
            
        case "mud_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mud_bricks
            craftingSlots[4].src = all.mud_bricks
            craftingSlots[5].src = all.mud_bricks
            craftingSlots[6].src = all.mud_bricks
            craftingSlots[7].src = all.mud_bricks
            craftingSlots[8].src = all.mud_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mud bricks"
            craftingSlots[4].parentElement.parentElement.title = "mud bricks"
            craftingSlots[5].parentElement.parentElement.title = "mud bricks"
            craftingSlots[6].parentElement.parentElement.title = "mud bricks"
            craftingSlots[7].parentElement.parentElement.title = "mud bricks"
            craftingSlots[8].parentElement.parentElement.title = "mud bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[4].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[5].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[6].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[7].parentElement.parentElement.alt = "mud bricks"
            craftingSlots[8].parentElement.parentElement.alt = "mud bricks"
            break;
            
        case "mud_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.packed_mud
            craftingSlots[4].src = all.packed_mud
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.packed_mud
            craftingSlots[7].src = all.packed_mud
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "packed mud"
            craftingSlots[4].parentElement.parentElement.title = "packed mud"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "packed mud"
            craftingSlots[7].parentElement.parentElement.title = "packed mud"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "packed mud"
            craftingSlots[4].parentElement.parentElement.alt = "packed mud"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "packed mud"
            craftingSlots[7].parentElement.parentElement.alt = "packed mud"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "nether_brick_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.nether_bricks
            craftingSlots[4].src = all.nether_brick
            craftingSlots[5].src = all.nether_bricks
            craftingSlots[6].src = all.nether_bricks
            craftingSlots[7].src = all.nether_brick
            craftingSlots[8].src = all.nether_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "nether bricks"
            craftingSlots[4].parentElement.parentElement.title = "nether brick"
            craftingSlots[5].parentElement.parentElement.title = "nether bricks"
            craftingSlots[6].parentElement.parentElement.title = "nether bricks"
            craftingSlots[7].parentElement.parentElement.title = "nether brick"
            craftingSlots[8].parentElement.parentElement.title = "nether bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[4].parentElement.parentElement.alt = "nether brick"
            craftingSlots[5].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[6].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[7].parentElement.parentElement.alt = "nether brick"
            craftingSlots[8].parentElement.parentElement.alt = "nether bricks"
            break;
            
        case "nether_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.nether_bricks
            craftingSlots[7].src = all.nether_bricks
            craftingSlots[8].src = all.nether_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "nether bricks"
            craftingSlots[7].parentElement.parentElement.title = "nether bricks"
            craftingSlots[8].parentElement.parentElement.title = "nether bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[7].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[8].parentElement.parentElement.alt = "nether bricks"
            break;
            
        case "nether_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.nether_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.nether_bricks
            craftingSlots[4].src = all.nether_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.nether_bricks
            craftingSlots[7].src = all.nether_bricks
            craftingSlots[8].src = all.nether_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "nether bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "nether bricks"
            craftingSlots[4].parentElement.parentElement.title = "nether bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "nether bricks"
            craftingSlots[7].parentElement.parentElement.title = "nether bricks"
            craftingSlots[8].parentElement.parentElement.title = "nether bricks"

            craftingSlots[0].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[4].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[7].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[8].parentElement.parentElement.alt = "nether bricks"
            break;
            
        case "nether_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.nether_bricks
            craftingSlots[4].src = all.nether_bricks
            craftingSlots[5].src = all.nether_bricks
            craftingSlots[6].src = all.nether_bricks
            craftingSlots[7].src = all.nether_bricks
            craftingSlots[8].src = all.nether_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "nether bricks"
            craftingSlots[4].parentElement.parentElement.title = "nether bricks"
            craftingSlots[5].parentElement.parentElement.title = "nether bricks"
            craftingSlots[6].parentElement.parentElement.title = "nether bricks"
            craftingSlots[7].parentElement.parentElement.title = "nether bricks"
            craftingSlots[8].parentElement.parentElement.title = "nether bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[4].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[5].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[6].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[7].parentElement.parentElement.alt = "nether bricks"
            craftingSlots[8].parentElement.parentElement.alt = "nether bricks"
            break;
            
        case "nether_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.nether_brick
            craftingSlots[4].src = all.nether_brick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.nether_brick
            craftingSlots[7].src = all.nether_brick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "nether brick"
            craftingSlots[4].parentElement.parentElement.title = "nether brick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "nether brick"
            craftingSlots[7].parentElement.parentElement.title = "nether brick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "nether brick"
            craftingSlots[4].parentElement.parentElement.alt = "nether brick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "nether brick"
            craftingSlots[7].parentElement.parentElement.alt = "nether brick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "netherite_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.netherite_ingot
            craftingSlots[1].src = all.netherite_ingot
            craftingSlots[2].src = all.netherite_ingot
            craftingSlots[3].src = all.netherite_ingot
            craftingSlots[4].src = all.netherite_ingot
            craftingSlots[5].src = all.netherite_ingot
            craftingSlots[6].src = all.netherite_ingot
            craftingSlots[7].src = all.netherite_ingot
            craftingSlots[8].src = all.netherite_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[1].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[2].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[3].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[4].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[5].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[6].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[7].parentElement.parentElement.title = "netherite ingot"
            craftingSlots[8].parentElement.parentElement.title = "netherite ingot"

            craftingSlots[0].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[1].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[2].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[3].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[4].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[5].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[6].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[7].parentElement.parentElement.alt = "netherite ingot"
            craftingSlots[8].parentElement.parentElement.alt = "netherite ingot"
            break;
            
        case "netherite_upgrade_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.netherite_upgrade_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.netherrack
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "netherite upgrade smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "netherrack"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "netherite upgrade smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "netherrack"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
        case "note_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.redstone
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "redstone"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "redstone"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "oak_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "oak_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oak_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "oak_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "oak_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_oak_log
            craftingSlots[4].src = all.stripped_oak_log
            craftingSlots[5].src = all.stripped_oak_log
            craftingSlots[6].src = all.stripped_oak_log
            craftingSlots[7].src = all.stripped_oak_log
            craftingSlots[8].src = all.stripped_oak_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[4].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[5].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[6].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[7].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[8].parentElement.parentElement.title = "stripped oak log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped oak log"
            break;
            
        case "oak_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oak_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oak_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "oak_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "oak_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "oak_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_log
            craftingSlots[4].src = all.oak_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_log
            craftingSlots[7].src = all.oak_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak log"
            craftingSlots[4].parentElement.parentElement.title = "oak log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak log"
            craftingSlots[7].parentElement.parentElement.title = "oak log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak log"
            craftingSlots[4].parentElement.parentElement.alt = "oak log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak log"
            craftingSlots[7].parentElement.parentElement.alt = "oak log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "observer":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.cobblestone
            craftingSlots[2].src = all.cobblestone
            craftingSlots[3].src = all.redstone
            craftingSlots[4].src = all.redstone
            craftingSlots[5].src = all.quartz
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.cobblestone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "cobblestone"
            craftingSlots[2].parentElement.parentElement.title = "cobblestone"
            craftingSlots[3].parentElement.parentElement.title = "redstone"
            craftingSlots[4].parentElement.parentElement.title = "redstone"
            craftingSlots[5].parentElement.parentElement.title = "quartz"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "cobblestone"
            craftingSlots[8].parentElement.parentElement.title = "cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[2].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[3].parentElement.parentElement.alt = "redstone"
            craftingSlots[4].parentElement.parentElement.alt = "redstone"
            craftingSlots[5].parentElement.parentElement.alt = "quartz"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[8].parentElement.parentElement.alt = "cobblestone"
            break;
            
        case "orange_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.orange_wool
            craftingSlots[1].src = all.orange_wool
            craftingSlots[2].src = all.orange_wool
            craftingSlots[3].src = all.orange_wool
            craftingSlots[4].src = all.orange_wool
            craftingSlots[5].src = all.orange_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "orange wool"
            craftingSlots[1].parentElement.parentElement.title = "orange wool"
            craftingSlots[2].parentElement.parentElement.title = "orange wool"
            craftingSlots[3].parentElement.parentElement.title = "orange wool"
            craftingSlots[4].parentElement.parentElement.title = "orange wool"
            craftingSlots[5].parentElement.parentElement.title = "orange wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "orange wool"
            craftingSlots[1].parentElement.parentElement.alt = "orange wool"
            craftingSlots[2].parentElement.parentElement.alt = "orange wool"
            craftingSlots[3].parentElement.parentElement.alt = "orange wool"
            craftingSlots[4].parentElement.parentElement.alt = "orange wool"
            craftingSlots[5].parentElement.parentElement.alt = "orange wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.orange_wool
            craftingSlots[4].src = all.orange_wool
            craftingSlots[5].src = all.orange_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "orange wool"
            craftingSlots[4].parentElement.parentElement.title = "orange wool"
            craftingSlots[5].parentElement.parentElement.title = "orange wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "orange wool"
            craftingSlots[4].parentElement.parentElement.alt = "orange wool"
            craftingSlots[5].parentElement.parentElement.alt = "orange wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "orange_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.orange_wool
            craftingSlots[7].src = all.orange_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "orange wool"
            craftingSlots[7].parentElement.parentElement.title = "orange wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "orange wool"
            craftingSlots[7].parentElement.parentElement.alt = "orange wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.orange_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "orange dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "orange dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "orange_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.orange_stained_glass
            craftingSlots[4].src = all.orange_stained_glass
            craftingSlots[5].src = all.orange_stained_glass
            craftingSlots[6].src = all.orange_stained_glass
            craftingSlots[7].src = all.orange_stained_glass
            craftingSlots[8].src = all.orange_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "orange stained glass"
            craftingSlots[4].parentElement.parentElement.title = "orange stained glass"
            craftingSlots[5].parentElement.parentElement.title = "orange stained glass"
            craftingSlots[6].parentElement.parentElement.title = "orange stained glass"
            craftingSlots[7].parentElement.parentElement.title = "orange stained glass"
            craftingSlots[8].parentElement.parentElement.title = "orange stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "orange stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "orange stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "orange stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "orange stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "orange stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "orange stained glass"
            break;
            
        case "orange_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.orange_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "orange dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "orange dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "orange_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.orange_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "orange dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "orange dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "oxidized_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oxidized_cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oxidized cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oxidized cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oxidized_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.oxidized_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.oxidized_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oxidized_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.oxidized_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.oxidized_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.oxidized_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oxidized_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper
            craftingSlots[4].src = all.oxidized_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oxidized_copper
            craftingSlots[7].src = all.oxidized_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[7].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[7].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oxidized_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oxidized_cut_copper
            craftingSlots[7].src = all.oxidized_cut_copper
            craftingSlots[8].src = all.oxidized_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.title = "oxidized cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "oxidized cut copper"
            break;
            
        case "oxidized_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oxidized_cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_cut_copper
            craftingSlots[4].src = all.oxidized_cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oxidized_cut_copper
            craftingSlots[7].src = all.oxidized_cut_copper
            craftingSlots[8].src = all.oxidized_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[4].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.title = "oxidized cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "oxidized cut copper"
            break;
            
        case "painting":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.stick
            craftingSlots[1].src = all.stick
            craftingSlots[2].src = all.stick
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.wool
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stick"
            craftingSlots[1].parentElement.parentElement.title = "stick"
            craftingSlots[2].parentElement.parentElement.title = "stick"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "wool"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "stick"
            craftingSlots[1].parentElement.parentElement.alt = "stick"
            craftingSlots[2].parentElement.parentElement.alt = "stick"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "wool"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "paper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.sugar_cane
            craftingSlots[7].src = all.sugar_cane
            craftingSlots[8].src = all.sugar_cane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "sugar cane"
            craftingSlots[7].parentElement.parentElement.title = "sugar cane"
            craftingSlots[8].parentElement.parentElement.title = "sugar cane"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "sugar cane"
            craftingSlots[7].parentElement.parentElement.alt = "sugar cane"
            craftingSlots[8].parentElement.parentElement.alt = "sugar cane"
            break;
            
        case "pink_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.pink_wool
            craftingSlots[1].src = all.pink_wool
            craftingSlots[2].src = all.pink_wool
            craftingSlots[3].src = all.pink_wool
            craftingSlots[4].src = all.pink_wool
            craftingSlots[5].src = all.pink_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "pink wool"
            craftingSlots[1].parentElement.parentElement.title = "pink wool"
            craftingSlots[2].parentElement.parentElement.title = "pink wool"
            craftingSlots[3].parentElement.parentElement.title = "pink wool"
            craftingSlots[4].parentElement.parentElement.title = "pink wool"
            craftingSlots[5].parentElement.parentElement.title = "pink wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "pink wool"
            craftingSlots[1].parentElement.parentElement.alt = "pink wool"
            craftingSlots[2].parentElement.parentElement.alt = "pink wool"
            craftingSlots[3].parentElement.parentElement.alt = "pink wool"
            craftingSlots[4].parentElement.parentElement.alt = "pink wool"
            craftingSlots[5].parentElement.parentElement.alt = "pink wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.pink_wool
            craftingSlots[4].src = all.pink_wool
            craftingSlots[5].src = all.pink_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "pink wool"
            craftingSlots[4].parentElement.parentElement.title = "pink wool"
            craftingSlots[5].parentElement.parentElement.title = "pink wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "pink wool"
            craftingSlots[4].parentElement.parentElement.alt = "pink wool"
            craftingSlots[5].parentElement.parentElement.alt = "pink wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "pink_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.pink_wool
            craftingSlots[7].src = all.pink_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "pink wool"
            craftingSlots[7].parentElement.parentElement.title = "pink wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "pink wool"
            craftingSlots[7].parentElement.parentElement.alt = "pink wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.pink_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "pink dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "pink dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "pink_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.pink_stained_glass
            craftingSlots[4].src = all.pink_stained_glass
            craftingSlots[5].src = all.pink_stained_glass
            craftingSlots[6].src = all.pink_stained_glass
            craftingSlots[7].src = all.pink_stained_glass
            craftingSlots[8].src = all.pink_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "pink stained glass"
            craftingSlots[4].parentElement.parentElement.title = "pink stained glass"
            craftingSlots[5].parentElement.parentElement.title = "pink stained glass"
            craftingSlots[6].parentElement.parentElement.title = "pink stained glass"
            craftingSlots[7].parentElement.parentElement.title = "pink stained glass"
            craftingSlots[8].parentElement.parentElement.title = "pink stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "pink stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "pink stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "pink stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "pink stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "pink stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "pink stained glass"
            break;
            
        case "pink_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.pink_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "pink dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "pink dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "pink_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.pink_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "pink dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "pink dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "piston":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.cobblestone
            craftingSlots[6].src = all.cobblestone
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.cobblestone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "cobblestone"
            craftingSlots[6].parentElement.parentElement.title = "cobblestone"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "cobblestone"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[6].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "cobblestone"
            break;
            
        case "polished_andesite":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.andesite
            craftingSlots[4].src = all.andesite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.andesite
            craftingSlots[7].src = all.andesite
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "andesite"
            craftingSlots[4].parentElement.parentElement.title = "andesite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "andesite"
            craftingSlots[7].parentElement.parentElement.title = "andesite"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "andesite"
            craftingSlots[4].parentElement.parentElement.alt = "andesite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "andesite"
            craftingSlots[7].parentElement.parentElement.alt = "andesite"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_andesite_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_andesite
            craftingSlots[7].src = all.polished_andesite
            craftingSlots[8].src = all.polished_andesite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished andesite"
            craftingSlots[7].parentElement.parentElement.title = "polished andesite"
            craftingSlots[8].parentElement.parentElement.title = "polished andesite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished andesite"
            craftingSlots[7].parentElement.parentElement.alt = "polished andesite"
            craftingSlots[8].parentElement.parentElement.alt = "polished andesite"
            break;
            
        case "polished_andesite_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.polished_andesite
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_andesite
            craftingSlots[4].src = all.polished_andesite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_andesite
            craftingSlots[7].src = all.polished_andesite
            craftingSlots[8].src = all.polished_andesite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "polished andesite"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished andesite"
            craftingSlots[4].parentElement.parentElement.title = "polished andesite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished andesite"
            craftingSlots[7].parentElement.parentElement.title = "polished andesite"
            craftingSlots[8].parentElement.parentElement.title = "polished andesite"

            craftingSlots[0].parentElement.parentElement.alt = "polished andesite"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished andesite"
            craftingSlots[4].parentElement.parentElement.alt = "polished andesite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished andesite"
            craftingSlots[7].parentElement.parentElement.alt = "polished andesite"
            craftingSlots[8].parentElement.parentElement.alt = "polished andesite"
            break;
            
        case "polished_basalt":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.basalt
            craftingSlots[4].src = all.basalt
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.basalt
            craftingSlots[7].src = all.basalt
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "basalt"
            craftingSlots[4].parentElement.parentElement.title = "basalt"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "basalt"
            craftingSlots[7].parentElement.parentElement.title = "basalt"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "basalt"
            craftingSlots[4].parentElement.parentElement.alt = "basalt"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "basalt"
            craftingSlots[7].parentElement.parentElement.alt = "basalt"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_blackstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blackstone
            craftingSlots[4].src = all.blackstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.blackstone
            craftingSlots[7].src = all.blackstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blackstone"
            craftingSlots[4].parentElement.parentElement.title = "blackstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "blackstone"
            craftingSlots[7].parentElement.parentElement.title = "blackstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blackstone"
            craftingSlots[4].parentElement.parentElement.alt = "blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_blackstone_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_blackstone_bricks
            craftingSlots[7].src = all.polished_blackstone_bricks
            craftingSlots[8].src = all.polished_blackstone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[8].parentElement.parentElement.title = "polished blackstone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "polished blackstone bricks"
            break;
            
        case "polished_blackstone_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.polished_blackstone_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_blackstone_bricks
            craftingSlots[4].src = all.polished_blackstone_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_blackstone_bricks
            craftingSlots[7].src = all.polished_blackstone_bricks
            craftingSlots[8].src = all.polished_blackstone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[4].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[8].parentElement.parentElement.title = "polished blackstone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "polished blackstone bricks"
            break;
            
        case "polished_blackstone_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_blackstone_bricks
            craftingSlots[4].src = all.polished_blackstone_bricks
            craftingSlots[5].src = all.polished_blackstone_bricks
            craftingSlots[6].src = all.polished_blackstone_bricks
            craftingSlots[7].src = all.polished_blackstone_bricks
            craftingSlots[8].src = all.polished_blackstone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[4].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[5].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone bricks"
            craftingSlots[8].parentElement.parentElement.title = "polished blackstone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "polished blackstone bricks"
            break;
            
        case "polished_blackstone_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_blackstone
            craftingSlots[4].src = all.polished_blackstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_blackstone
            craftingSlots[7].src = all.polished_blackstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[4].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[4].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_blackstone_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_blackstone
            craftingSlots[7].src = all.polished_blackstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_blackstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_blackstone
            craftingSlots[7].src = all.polished_blackstone
            craftingSlots[8].src = all.polished_blackstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.title = "polished blackstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "polished blackstone"
            break;
            
        case "polished_blackstone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.polished_blackstone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_blackstone
            craftingSlots[4].src = all.polished_blackstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_blackstone
            craftingSlots[7].src = all.polished_blackstone
            craftingSlots[8].src = all.polished_blackstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[4].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.title = "polished blackstone"

            craftingSlots[0].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[4].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "polished blackstone"
            break;
            
        case "polished_blackstone_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_blackstone
            craftingSlots[4].src = all.polished_blackstone
            craftingSlots[5].src = all.polished_blackstone
            craftingSlots[6].src = all.polished_blackstone
            craftingSlots[7].src = all.polished_blackstone
            craftingSlots[8].src = all.polished_blackstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[4].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[6].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.title = "polished blackstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[4].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[6].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[7].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[8].parentElement.parentElement.alt = "polished blackstone"
            break;
            
        case "polished_deepslate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobbled_deepslate
            craftingSlots[4].src = all.cobbled_deepslate
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.cobbled_deepslate
            craftingSlots[7].src = all.cobbled_deepslate
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[4].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[4].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_deepslate_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_deepslate
            craftingSlots[7].src = all.polished_deepslate
            craftingSlots[8].src = all.polished_deepslate
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.title = "polished deepslate"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "polished deepslate"
            break;
            
        case "polished_deepslate_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.polished_deepslate
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_deepslate
            craftingSlots[4].src = all.polished_deepslate
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_deepslate
            craftingSlots[7].src = all.polished_deepslate
            craftingSlots[8].src = all.polished_deepslate
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[4].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.title = "polished deepslate"

            craftingSlots[0].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[4].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "polished deepslate"
            break;
            
        case "polished_deepslate_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_deepslate
            craftingSlots[4].src = all.polished_deepslate
            craftingSlots[5].src = all.polished_deepslate
            craftingSlots[6].src = all.polished_deepslate
            craftingSlots[7].src = all.polished_deepslate
            craftingSlots[8].src = all.polished_deepslate
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[4].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[5].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[6].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.title = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.title = "polished deepslate"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[4].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[6].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[7].parentElement.parentElement.alt = "polished deepslate"
            craftingSlots[8].parentElement.parentElement.alt = "polished deepslate"
            break;
            
        case "polished_diorite":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diorite
            craftingSlots[4].src = all.diorite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.diorite
            craftingSlots[7].src = all.diorite
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diorite"
            craftingSlots[4].parentElement.parentElement.title = "diorite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "diorite"
            craftingSlots[7].parentElement.parentElement.title = "diorite"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diorite"
            craftingSlots[4].parentElement.parentElement.alt = "diorite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "diorite"
            craftingSlots[7].parentElement.parentElement.alt = "diorite"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_diorite_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_diorite
            craftingSlots[7].src = all.polished_diorite
            craftingSlots[8].src = all.polished_diorite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished diorite"
            craftingSlots[7].parentElement.parentElement.title = "polished diorite"
            craftingSlots[8].parentElement.parentElement.title = "polished diorite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished diorite"
            craftingSlots[7].parentElement.parentElement.alt = "polished diorite"
            craftingSlots[8].parentElement.parentElement.alt = "polished diorite"
            break;
            
        case "polished_diorite_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.polished_diorite
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_diorite
            craftingSlots[4].src = all.polished_diorite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_diorite
            craftingSlots[7].src = all.polished_diorite
            craftingSlots[8].src = all.polished_diorite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "polished diorite"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished diorite"
            craftingSlots[4].parentElement.parentElement.title = "polished diorite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished diorite"
            craftingSlots[7].parentElement.parentElement.title = "polished diorite"
            craftingSlots[8].parentElement.parentElement.title = "polished diorite"

            craftingSlots[0].parentElement.parentElement.alt = "polished diorite"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished diorite"
            craftingSlots[4].parentElement.parentElement.alt = "polished diorite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished diorite"
            craftingSlots[7].parentElement.parentElement.alt = "polished diorite"
            craftingSlots[8].parentElement.parentElement.alt = "polished diorite"
            break;
            
        case "polished_granite":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.granite
            craftingSlots[4].src = all.granite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.granite
            craftingSlots[7].src = all.granite
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "granite"
            craftingSlots[4].parentElement.parentElement.title = "granite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "granite"
            craftingSlots[7].parentElement.parentElement.title = "granite"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "granite"
            craftingSlots[4].parentElement.parentElement.alt = "granite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "granite"
            craftingSlots[7].parentElement.parentElement.alt = "granite"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_granite_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_granite
            craftingSlots[7].src = all.polished_granite
            craftingSlots[8].src = all.polished_granite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished granite"
            craftingSlots[7].parentElement.parentElement.title = "polished granite"
            craftingSlots[8].parentElement.parentElement.title = "polished granite"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished granite"
            craftingSlots[7].parentElement.parentElement.alt = "polished granite"
            craftingSlots[8].parentElement.parentElement.alt = "polished granite"
            break;
            
        case "polished_granite_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.polished_granite
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_granite
            craftingSlots[4].src = all.polished_granite
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_granite
            craftingSlots[7].src = all.polished_granite
            craftingSlots[8].src = all.polished_granite
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "polished granite"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished granite"
            craftingSlots[4].parentElement.parentElement.title = "polished granite"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished granite"
            craftingSlots[7].parentElement.parentElement.title = "polished granite"
            craftingSlots[8].parentElement.parentElement.title = "polished granite"

            craftingSlots[0].parentElement.parentElement.alt = "polished granite"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished granite"
            craftingSlots[4].parentElement.parentElement.alt = "polished granite"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished granite"
            craftingSlots[7].parentElement.parentElement.alt = "polished granite"
            craftingSlots[8].parentElement.parentElement.alt = "polished granite"
            break;
            
        case "polished_tuff":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tuff
            craftingSlots[4].src = all.tuff
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.tuff
            craftingSlots[7].src = all.tuff
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tuff"
            craftingSlots[4].parentElement.parentElement.title = "tuff"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "tuff"
            craftingSlots[7].parentElement.parentElement.title = "tuff"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tuff"
            craftingSlots[4].parentElement.parentElement.alt = "tuff"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "tuff"
            craftingSlots[7].parentElement.parentElement.alt = "tuff"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_tuff_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_tuff
            craftingSlots[7].src = all.polished_tuff
            craftingSlots[8].src = all.polished_tuff
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished tuff"
            craftingSlots[7].parentElement.parentElement.title = "polished tuff"
            craftingSlots[8].parentElement.parentElement.title = "polished tuff"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[7].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[8].parentElement.parentElement.alt = "polished tuff"
            break;
            
        case "polished_tuff_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.polished_tuff
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_tuff
            craftingSlots[4].src = all.polished_tuff
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_tuff
            craftingSlots[7].src = all.polished_tuff
            craftingSlots[8].src = all.polished_tuff
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "polished tuff"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished tuff"
            craftingSlots[4].parentElement.parentElement.title = "polished tuff"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished tuff"
            craftingSlots[7].parentElement.parentElement.title = "polished tuff"
            craftingSlots[8].parentElement.parentElement.title = "polished tuff"

            craftingSlots[0].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[4].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[7].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[8].parentElement.parentElement.alt = "polished tuff"
            break;
            
        case "polished_tuff_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_tuff
            craftingSlots[4].src = all.polished_tuff
            craftingSlots[5].src = all.polished_tuff
            craftingSlots[6].src = all.polished_tuff
            craftingSlots[7].src = all.polished_tuff
            craftingSlots[8].src = all.polished_tuff
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished tuff"
            craftingSlots[4].parentElement.parentElement.title = "polished tuff"
            craftingSlots[5].parentElement.parentElement.title = "polished tuff"
            craftingSlots[6].parentElement.parentElement.title = "polished tuff"
            craftingSlots[7].parentElement.parentElement.title = "polished tuff"
            craftingSlots[8].parentElement.parentElement.title = "polished tuff"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[4].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[5].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[6].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[7].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[8].parentElement.parentElement.alt = "polished tuff"
            break;
            
        case "powered_rail":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gold_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.gold_ingot
            craftingSlots[3].src = all.gold_ingot
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.gold_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gold ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "gold ingot"
            craftingSlots[3].parentElement.parentElement.title = "gold ingot"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "gold ingot"

            craftingSlots[0].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[3].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "gold ingot"
            break;
            
        case "prismarine":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.prismarine_shard
            craftingSlots[4].src = all.prismarine_shard
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.prismarine_shard
            craftingSlots[7].src = all.prismarine_shard
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[4].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[4].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "prismarine_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.prismarine_bricks
            craftingSlots[7].src = all.prismarine_bricks
            craftingSlots[8].src = all.prismarine_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "prismarine bricks"
            craftingSlots[7].parentElement.parentElement.title = "prismarine bricks"
            craftingSlots[8].parentElement.parentElement.title = "prismarine bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine bricks"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine bricks"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine bricks"
            break;
            
        case "prismarine_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.prismarine_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.prismarine_bricks
            craftingSlots[4].src = all.prismarine_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.prismarine_bricks
            craftingSlots[7].src = all.prismarine_bricks
            craftingSlots[8].src = all.prismarine_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "prismarine bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "prismarine bricks"
            craftingSlots[4].parentElement.parentElement.title = "prismarine bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "prismarine bricks"
            craftingSlots[7].parentElement.parentElement.title = "prismarine bricks"
            craftingSlots[8].parentElement.parentElement.title = "prismarine bricks"

            craftingSlots[0].parentElement.parentElement.alt = "prismarine bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "prismarine bricks"
            craftingSlots[4].parentElement.parentElement.alt = "prismarine bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine bricks"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine bricks"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine bricks"
            break;
            
        case "prismarine_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.prismarine
            craftingSlots[7].src = all.prismarine
            craftingSlots[8].src = all.prismarine
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "prismarine"
            craftingSlots[7].parentElement.parentElement.title = "prismarine"
            craftingSlots[8].parentElement.parentElement.title = "prismarine"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine"
            break;
            
        case "prismarine_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.prismarine
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.prismarine
            craftingSlots[4].src = all.prismarine
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.prismarine
            craftingSlots[7].src = all.prismarine
            craftingSlots[8].src = all.prismarine
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "prismarine"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "prismarine"
            craftingSlots[4].parentElement.parentElement.title = "prismarine"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "prismarine"
            craftingSlots[7].parentElement.parentElement.title = "prismarine"
            craftingSlots[8].parentElement.parentElement.title = "prismarine"

            craftingSlots[0].parentElement.parentElement.alt = "prismarine"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "prismarine"
            craftingSlots[4].parentElement.parentElement.alt = "prismarine"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine"
            break;
            
        case "prismarine_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.prismarine
            craftingSlots[4].src = all.prismarine
            craftingSlots[5].src = all.prismarine
            craftingSlots[6].src = all.prismarine
            craftingSlots[7].src = all.prismarine
            craftingSlots[8].src = all.prismarine
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "prismarine"
            craftingSlots[4].parentElement.parentElement.title = "prismarine"
            craftingSlots[5].parentElement.parentElement.title = "prismarine"
            craftingSlots[6].parentElement.parentElement.title = "prismarine"
            craftingSlots[7].parentElement.parentElement.title = "prismarine"
            craftingSlots[8].parentElement.parentElement.title = "prismarine"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "prismarine"
            craftingSlots[4].parentElement.parentElement.alt = "prismarine"
            craftingSlots[5].parentElement.parentElement.alt = "prismarine"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine"
            break;
            
        case "purple_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.purple_wool
            craftingSlots[1].src = all.purple_wool
            craftingSlots[2].src = all.purple_wool
            craftingSlots[3].src = all.purple_wool
            craftingSlots[4].src = all.purple_wool
            craftingSlots[5].src = all.purple_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "purple wool"
            craftingSlots[1].parentElement.parentElement.title = "purple wool"
            craftingSlots[2].parentElement.parentElement.title = "purple wool"
            craftingSlots[3].parentElement.parentElement.title = "purple wool"
            craftingSlots[4].parentElement.parentElement.title = "purple wool"
            craftingSlots[5].parentElement.parentElement.title = "purple wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "purple wool"
            craftingSlots[1].parentElement.parentElement.alt = "purple wool"
            craftingSlots[2].parentElement.parentElement.alt = "purple wool"
            craftingSlots[3].parentElement.parentElement.alt = "purple wool"
            craftingSlots[4].parentElement.parentElement.alt = "purple wool"
            craftingSlots[5].parentElement.parentElement.alt = "purple wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purple_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purple_wool
            craftingSlots[4].src = all.purple_wool
            craftingSlots[5].src = all.purple_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purple wool"
            craftingSlots[4].parentElement.parentElement.title = "purple wool"
            craftingSlots[5].parentElement.parentElement.title = "purple wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purple wool"
            craftingSlots[4].parentElement.parentElement.alt = "purple wool"
            craftingSlots[5].parentElement.parentElement.alt = "purple wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "purple_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.purple_wool
            craftingSlots[7].src = all.purple_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "purple wool"
            craftingSlots[7].parentElement.parentElement.title = "purple wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "purple wool"
            craftingSlots[7].parentElement.parentElement.alt = "purple wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purple_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.purple_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "purple dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "purple dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "purple_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purple_stained_glass
            craftingSlots[4].src = all.purple_stained_glass
            craftingSlots[5].src = all.purple_stained_glass
            craftingSlots[6].src = all.purple_stained_glass
            craftingSlots[7].src = all.purple_stained_glass
            craftingSlots[8].src = all.purple_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purple stained glass"
            craftingSlots[4].parentElement.parentElement.title = "purple stained glass"
            craftingSlots[5].parentElement.parentElement.title = "purple stained glass"
            craftingSlots[6].parentElement.parentElement.title = "purple stained glass"
            craftingSlots[7].parentElement.parentElement.title = "purple stained glass"
            craftingSlots[8].parentElement.parentElement.title = "purple stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purple stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "purple stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "purple stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "purple stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "purple stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "purple stained glass"
            break;
            
        case "purple_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.purple_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "purple dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "purple dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "purple_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.purple_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "purple dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "purple dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "purpur_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.popped_chorus_fruit
            craftingSlots[4].src = all.popped_chorus_fruit
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.popped_chorus_fruit
            craftingSlots[7].src = all.popped_chorus_fruit
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "popped chorus fruit"
            craftingSlots[4].parentElement.parentElement.title = "popped chorus fruit"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "popped chorus fruit"
            craftingSlots[7].parentElement.parentElement.title = "popped chorus fruit"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "popped chorus fruit"
            craftingSlots[4].parentElement.parentElement.alt = "popped chorus fruit"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "popped chorus fruit"
            craftingSlots[7].parentElement.parentElement.alt = "popped chorus fruit"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purpur_pillar":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purpur_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.purpur_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purpur slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "purpur slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purpur slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "purpur slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purpur_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.purpur_block
            craftingSlots[7].src = all.purpur_block
            craftingSlots[8].src = all.purpur_block
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "purpur block"
            craftingSlots[7].parentElement.parentElement.title = "purpur block"
            craftingSlots[8].parentElement.parentElement.title = "purpur block"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "purpur block"
            craftingSlots[7].parentElement.parentElement.alt = "purpur block"
            craftingSlots[8].parentElement.parentElement.alt = "purpur block"
            break;
            
        case "purpur_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.purpur_block
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purpur_block
            craftingSlots[4].src = all.purpur_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.purpur_block
            craftingSlots[7].src = all.purpur_block
            craftingSlots[8].src = all.purpur_block
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "purpur block"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purpur block"
            craftingSlots[4].parentElement.parentElement.title = "purpur block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "purpur block"
            craftingSlots[7].parentElement.parentElement.title = "purpur block"
            craftingSlots[8].parentElement.parentElement.title = "purpur block"

            craftingSlots[0].parentElement.parentElement.alt = "purpur block"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purpur block"
            craftingSlots[4].parentElement.parentElement.alt = "purpur block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "purpur block"
            craftingSlots[7].parentElement.parentElement.alt = "purpur block"
            craftingSlots[8].parentElement.parentElement.alt = "purpur block"
            break;
            
        case "quartz_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.quartz
            craftingSlots[4].src = all.quartz
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.quartz
            craftingSlots[7].src = all.quartz
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "quartz"
            craftingSlots[4].parentElement.parentElement.title = "quartz"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "quartz"
            craftingSlots[7].parentElement.parentElement.title = "quartz"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "quartz"
            craftingSlots[4].parentElement.parentElement.alt = "quartz"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "quartz"
            craftingSlots[7].parentElement.parentElement.alt = "quartz"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "quartz_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.quartz_block
            craftingSlots[4].src = all.quartz_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.quartz_block
            craftingSlots[7].src = all.quartz_block
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "quartz block"
            craftingSlots[4].parentElement.parentElement.title = "quartz block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "quartz block"
            craftingSlots[7].parentElement.parentElement.title = "quartz block"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "quartz block"
            craftingSlots[4].parentElement.parentElement.alt = "quartz block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "quartz block"
            craftingSlots[7].parentElement.parentElement.alt = "quartz block"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "quartz_pillar":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.quartz_block
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.quartz_block
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "quartz block"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "quartz block"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "quartz block"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "quartz block"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "quartz_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.chiseled_quartz_block
            craftingSlots[7].src = all.chiseled_quartz_block
            craftingSlots[8].src = all.chiseled_quartz_block
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "chiseled quartz block"
            craftingSlots[7].parentElement.parentElement.title = "chiseled quartz block"
            craftingSlots[8].parentElement.parentElement.title = "chiseled quartz block"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "chiseled quartz block"
            craftingSlots[7].parentElement.parentElement.alt = "chiseled quartz block"
            craftingSlots[8].parentElement.parentElement.alt = "chiseled quartz block"
            break;
            
        case "quartz_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chiseled_quartz_block
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chiseled_quartz_block
            craftingSlots[4].src = all.chiseled_quartz_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.chiseled_quartz_block
            craftingSlots[7].src = all.chiseled_quartz_block
            craftingSlots[8].src = all.chiseled_quartz_block
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chiseled quartz block"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chiseled quartz block"
            craftingSlots[4].parentElement.parentElement.title = "chiseled quartz block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "chiseled quartz block"
            craftingSlots[7].parentElement.parentElement.title = "chiseled quartz block"
            craftingSlots[8].parentElement.parentElement.title = "chiseled quartz block"

            craftingSlots[0].parentElement.parentElement.alt = "chiseled quartz block"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chiseled quartz block"
            craftingSlots[4].parentElement.parentElement.alt = "chiseled quartz block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "chiseled quartz block"
            craftingSlots[7].parentElement.parentElement.alt = "chiseled quartz block"
            craftingSlots[8].parentElement.parentElement.alt = "chiseled quartz block"
            break;
            
        case "rail":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.iron_ingot
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.iron_ingot
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.iron_ingot
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "iron ingot"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "iron ingot"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "iron ingot"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "iron ingot"
            break;
            
        case "raiser_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.raiser_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.terracotta
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "raiser armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "terracotta"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "raiser armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "terracotta"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "raiser_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "raw_copper_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.raw_copper
            craftingSlots[1].src = all.raw_copper
            craftingSlots[2].src = all.raw_copper
            craftingSlots[3].src = all.raw_copper
            craftingSlots[4].src = all.raw_copper
            craftingSlots[5].src = all.raw_copper
            craftingSlots[6].src = all.raw_copper
            craftingSlots[7].src = all.raw_copper
            craftingSlots[8].src = all.raw_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "raw copper"
            craftingSlots[1].parentElement.parentElement.title = "raw copper"
            craftingSlots[2].parentElement.parentElement.title = "raw copper"
            craftingSlots[3].parentElement.parentElement.title = "raw copper"
            craftingSlots[4].parentElement.parentElement.title = "raw copper"
            craftingSlots[5].parentElement.parentElement.title = "raw copper"
            craftingSlots[6].parentElement.parentElement.title = "raw copper"
            craftingSlots[7].parentElement.parentElement.title = "raw copper"
            craftingSlots[8].parentElement.parentElement.title = "raw copper"

            craftingSlots[0].parentElement.parentElement.alt = "raw copper"
            craftingSlots[1].parentElement.parentElement.alt = "raw copper"
            craftingSlots[2].parentElement.parentElement.alt = "raw copper"
            craftingSlots[3].parentElement.parentElement.alt = "raw copper"
            craftingSlots[4].parentElement.parentElement.alt = "raw copper"
            craftingSlots[5].parentElement.parentElement.alt = "raw copper"
            craftingSlots[6].parentElement.parentElement.alt = "raw copper"
            craftingSlots[7].parentElement.parentElement.alt = "raw copper"
            craftingSlots[8].parentElement.parentElement.alt = "raw copper"
            break;
            
        case "raw_gold_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.raw_gold
            craftingSlots[1].src = all.raw_gold
            craftingSlots[2].src = all.raw_gold
            craftingSlots[3].src = all.raw_gold
            craftingSlots[4].src = all.raw_gold
            craftingSlots[5].src = all.raw_gold
            craftingSlots[6].src = all.raw_gold
            craftingSlots[7].src = all.raw_gold
            craftingSlots[8].src = all.raw_gold
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "raw gold"
            craftingSlots[1].parentElement.parentElement.title = "raw gold"
            craftingSlots[2].parentElement.parentElement.title = "raw gold"
            craftingSlots[3].parentElement.parentElement.title = "raw gold"
            craftingSlots[4].parentElement.parentElement.title = "raw gold"
            craftingSlots[5].parentElement.parentElement.title = "raw gold"
            craftingSlots[6].parentElement.parentElement.title = "raw gold"
            craftingSlots[7].parentElement.parentElement.title = "raw gold"
            craftingSlots[8].parentElement.parentElement.title = "raw gold"

            craftingSlots[0].parentElement.parentElement.alt = "raw gold"
            craftingSlots[1].parentElement.parentElement.alt = "raw gold"
            craftingSlots[2].parentElement.parentElement.alt = "raw gold"
            craftingSlots[3].parentElement.parentElement.alt = "raw gold"
            craftingSlots[4].parentElement.parentElement.alt = "raw gold"
            craftingSlots[5].parentElement.parentElement.alt = "raw gold"
            craftingSlots[6].parentElement.parentElement.alt = "raw gold"
            craftingSlots[7].parentElement.parentElement.alt = "raw gold"
            craftingSlots[8].parentElement.parentElement.alt = "raw gold"
            break;
            
        case "raw_iron_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.raw_iron
            craftingSlots[1].src = all.raw_iron
            craftingSlots[2].src = all.raw_iron
            craftingSlots[3].src = all.raw_iron
            craftingSlots[4].src = all.raw_iron
            craftingSlots[5].src = all.raw_iron
            craftingSlots[6].src = all.raw_iron
            craftingSlots[7].src = all.raw_iron
            craftingSlots[8].src = all.raw_iron
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "raw iron"
            craftingSlots[1].parentElement.parentElement.title = "raw iron"
            craftingSlots[2].parentElement.parentElement.title = "raw iron"
            craftingSlots[3].parentElement.parentElement.title = "raw iron"
            craftingSlots[4].parentElement.parentElement.title = "raw iron"
            craftingSlots[5].parentElement.parentElement.title = "raw iron"
            craftingSlots[6].parentElement.parentElement.title = "raw iron"
            craftingSlots[7].parentElement.parentElement.title = "raw iron"
            craftingSlots[8].parentElement.parentElement.title = "raw iron"

            craftingSlots[0].parentElement.parentElement.alt = "raw iron"
            craftingSlots[1].parentElement.parentElement.alt = "raw iron"
            craftingSlots[2].parentElement.parentElement.alt = "raw iron"
            craftingSlots[3].parentElement.parentElement.alt = "raw iron"
            craftingSlots[4].parentElement.parentElement.alt = "raw iron"
            craftingSlots[5].parentElement.parentElement.alt = "raw iron"
            craftingSlots[6].parentElement.parentElement.alt = "raw iron"
            craftingSlots[7].parentElement.parentElement.alt = "raw iron"
            craftingSlots[8].parentElement.parentElement.alt = "raw iron"
            break;
            
        case "recovery_compass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.echo_shard
            craftingSlots[1].src = all.echo_shard
            craftingSlots[2].src = all.echo_shard
            craftingSlots[3].src = all.echo_shard
            craftingSlots[4].src = all.compass
            craftingSlots[5].src = all.echo_shard
            craftingSlots[6].src = all.echo_shard
            craftingSlots[7].src = all.echo_shard
            craftingSlots[8].src = all.echo_shard
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "echo shard"
            craftingSlots[1].parentElement.parentElement.title = "echo shard"
            craftingSlots[2].parentElement.parentElement.title = "echo shard"
            craftingSlots[3].parentElement.parentElement.title = "echo shard"
            craftingSlots[4].parentElement.parentElement.title = "compass"
            craftingSlots[5].parentElement.parentElement.title = "echo shard"
            craftingSlots[6].parentElement.parentElement.title = "echo shard"
            craftingSlots[7].parentElement.parentElement.title = "echo shard"
            craftingSlots[8].parentElement.parentElement.title = "echo shard"

            craftingSlots[0].parentElement.parentElement.alt = "echo shard"
            craftingSlots[1].parentElement.parentElement.alt = "echo shard"
            craftingSlots[2].parentElement.parentElement.alt = "echo shard"
            craftingSlots[3].parentElement.parentElement.alt = "echo shard"
            craftingSlots[4].parentElement.parentElement.alt = "compass"
            craftingSlots[5].parentElement.parentElement.alt = "echo shard"
            craftingSlots[6].parentElement.parentElement.alt = "echo shard"
            craftingSlots[7].parentElement.parentElement.alt = "echo shard"
            craftingSlots[8].parentElement.parentElement.alt = "echo shard"
            break;
            
        case "red_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.red_wool
            craftingSlots[1].src = all.red_wool
            craftingSlots[2].src = all.red_wool
            craftingSlots[3].src = all.red_wool
            craftingSlots[4].src = all.red_wool
            craftingSlots[5].src = all.red_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "red wool"
            craftingSlots[1].parentElement.parentElement.title = "red wool"
            craftingSlots[2].parentElement.parentElement.title = "red wool"
            craftingSlots[3].parentElement.parentElement.title = "red wool"
            craftingSlots[4].parentElement.parentElement.title = "red wool"
            craftingSlots[5].parentElement.parentElement.title = "red wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "red wool"
            craftingSlots[1].parentElement.parentElement.alt = "red wool"
            craftingSlots[2].parentElement.parentElement.alt = "red wool"
            craftingSlots[3].parentElement.parentElement.alt = "red wool"
            craftingSlots[4].parentElement.parentElement.alt = "red wool"
            craftingSlots[5].parentElement.parentElement.alt = "red wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_wool
            craftingSlots[4].src = all.red_wool
            craftingSlots[5].src = all.red_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red wool"
            craftingSlots[4].parentElement.parentElement.title = "red wool"
            craftingSlots[5].parentElement.parentElement.title = "red wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red wool"
            craftingSlots[4].parentElement.parentElement.alt = "red wool"
            craftingSlots[5].parentElement.parentElement.alt = "red wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "red_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_wool
            craftingSlots[7].src = all.red_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red wool"
            craftingSlots[7].parentElement.parentElement.title = "red wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red wool"
            craftingSlots[7].parentElement.parentElement.alt = "red wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_nether_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_nether_bricks
            craftingSlots[7].src = all.red_nether_bricks
            craftingSlots[8].src = all.red_nether_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[7].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[8].parentElement.parentElement.title = "red nether bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[7].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[8].parentElement.parentElement.alt = "red nether bricks"
            break;
            
        case "red_nether_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.red_nether_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_nether_bricks
            craftingSlots[4].src = all.red_nether_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_nether_bricks
            craftingSlots[7].src = all.red_nether_bricks
            craftingSlots[8].src = all.red_nether_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[4].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[7].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[8].parentElement.parentElement.title = "red nether bricks"

            craftingSlots[0].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[4].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[7].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[8].parentElement.parentElement.alt = "red nether bricks"
            break;
            
        case "red_nether_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_nether_bricks
            craftingSlots[4].src = all.red_nether_bricks
            craftingSlots[5].src = all.red_nether_bricks
            craftingSlots[6].src = all.red_nether_bricks
            craftingSlots[7].src = all.red_nether_bricks
            craftingSlots[8].src = all.red_nether_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[4].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[5].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[6].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[7].parentElement.parentElement.title = "red nether bricks"
            craftingSlots[8].parentElement.parentElement.title = "red nether bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[4].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[5].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[6].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[7].parentElement.parentElement.alt = "red nether bricks"
            craftingSlots[8].parentElement.parentElement.alt = "red nether bricks"
            break;
            
        case "red_nether_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.nether_brick
            craftingSlots[4].src = all.nether_wart
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.nether_wart
            craftingSlots[7].src = all.nether_brick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "nether brick"
            craftingSlots[4].parentElement.parentElement.title = "nether wart"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "nether wart"
            craftingSlots[7].parentElement.parentElement.title = "nether brick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "nether brick"
            craftingSlots[4].parentElement.parentElement.alt = "nether wart"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "nether wart"
            craftingSlots[7].parentElement.parentElement.alt = "nether brick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_sandstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_sand
            craftingSlots[4].src = all.red_sand
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_sand
            craftingSlots[7].src = all.red_sand
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red sand"
            craftingSlots[4].parentElement.parentElement.title = "red sand"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red sand"
            craftingSlots[7].parentElement.parentElement.title = "red sand"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red sand"
            craftingSlots[4].parentElement.parentElement.alt = "red sand"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red sand"
            craftingSlots[7].parentElement.parentElement.alt = "red sand"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_sandstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_sandstone
            craftingSlots[7].src = all.red_sandstone
            craftingSlots[8].src = all.red_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red sandstone"
            craftingSlots[7].parentElement.parentElement.title = "red sandstone"
            craftingSlots[8].parentElement.parentElement.title = "red sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "red sandstone"
            break;
            
        case "red_sandstone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.red_sandstone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_sandstone
            craftingSlots[4].src = all.red_sandstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.red_sandstone
            craftingSlots[7].src = all.red_sandstone
            craftingSlots[8].src = all.red_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "red sandstone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red sandstone"
            craftingSlots[4].parentElement.parentElement.title = "red sandstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "red sandstone"
            craftingSlots[7].parentElement.parentElement.title = "red sandstone"
            craftingSlots[8].parentElement.parentElement.title = "red sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "red sandstone"
            break;
            
        case "red_sandstone_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_sandstone
            craftingSlots[4].src = all.red_sandstone
            craftingSlots[5].src = all.red_sandstone
            craftingSlots[6].src = all.red_sandstone
            craftingSlots[7].src = all.red_sandstone
            craftingSlots[8].src = all.red_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red sandstone"
            craftingSlots[4].parentElement.parentElement.title = "red sandstone"
            craftingSlots[5].parentElement.parentElement.title = "red sandstone"
            craftingSlots[6].parentElement.parentElement.title = "red sandstone"
            craftingSlots[7].parentElement.parentElement.title = "red sandstone"
            craftingSlots[8].parentElement.parentElement.title = "red sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[6].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "red sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "red sandstone"
            break;
            
        case "red_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.red_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "red dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "red dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "red_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_stained_glass
            craftingSlots[4].src = all.red_stained_glass
            craftingSlots[5].src = all.red_stained_glass
            craftingSlots[6].src = all.red_stained_glass
            craftingSlots[7].src = all.red_stained_glass
            craftingSlots[8].src = all.red_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red stained glass"
            craftingSlots[4].parentElement.parentElement.title = "red stained glass"
            craftingSlots[5].parentElement.parentElement.title = "red stained glass"
            craftingSlots[6].parentElement.parentElement.title = "red stained glass"
            craftingSlots[7].parentElement.parentElement.title = "red stained glass"
            craftingSlots[8].parentElement.parentElement.title = "red stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "red stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "red stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "red stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "red stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "red stained glass"
            break;
            
        case "red_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.red_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "red dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "red dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "red_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.red_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "red dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "red dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "redstone_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.redstone
            craftingSlots[1].src = all.redstone
            craftingSlots[2].src = all.redstone
            craftingSlots[3].src = all.redstone
            craftingSlots[4].src = all.redstone
            craftingSlots[5].src = all.redstone
            craftingSlots[6].src = all.redstone
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.redstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "redstone"
            craftingSlots[1].parentElement.parentElement.title = "redstone"
            craftingSlots[2].parentElement.parentElement.title = "redstone"
            craftingSlots[3].parentElement.parentElement.title = "redstone"
            craftingSlots[4].parentElement.parentElement.title = "redstone"
            craftingSlots[5].parentElement.parentElement.title = "redstone"
            craftingSlots[6].parentElement.parentElement.title = "redstone"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "redstone"

            craftingSlots[0].parentElement.parentElement.alt = "redstone"
            craftingSlots[1].parentElement.parentElement.alt = "redstone"
            craftingSlots[2].parentElement.parentElement.alt = "redstone"
            craftingSlots[3].parentElement.parentElement.alt = "redstone"
            craftingSlots[4].parentElement.parentElement.alt = "redstone"
            craftingSlots[5].parentElement.parentElement.alt = "redstone"
            craftingSlots[6].parentElement.parentElement.alt = "redstone"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "redstone"
            break;
            
        case "redstone_lamp":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.redstone
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.redstone
            craftingSlots[4].src = all.glowstone
            craftingSlots[5].src = all.redstone
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "redstone"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "redstone"
            craftingSlots[4].parentElement.parentElement.title = "glowstone"
            craftingSlots[5].parentElement.parentElement.title = "redstone"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "redstone"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "redstone"
            craftingSlots[4].parentElement.parentElement.alt = "glowstone"
            craftingSlots[5].parentElement.parentElement.alt = "redstone"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "redstone_torch":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.redstone
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "redstone"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "redstone"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "redstone_torch":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "repeater":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.redstone_torch
            craftingSlots[4].src = all.redstone
            craftingSlots[5].src = all.redstone_torch
            craftingSlots[6].src = all.stone
            craftingSlots[7].src = all.stone
            craftingSlots[8].src = all.stone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "redstone torch"
            craftingSlots[4].parentElement.parentElement.title = "redstone"
            craftingSlots[5].parentElement.parentElement.title = "redstone torch"
            craftingSlots[6].parentElement.parentElement.title = "stone"
            craftingSlots[7].parentElement.parentElement.title = "stone"
            craftingSlots[8].parentElement.parentElement.title = "stone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "redstone torch"
            craftingSlots[4].parentElement.parentElement.alt = "redstone"
            craftingSlots[5].parentElement.parentElement.alt = "redstone torch"
            craftingSlots[6].parentElement.parentElement.alt = "stone"
            craftingSlots[7].parentElement.parentElement.alt = "stone"
            craftingSlots[8].parentElement.parentElement.alt = "stone"
            break;
            
        case "respawn_anchor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.crying_obsidian
            craftingSlots[1].src = all.crying_obsidian
            craftingSlots[2].src = all.crying_obsidian
            craftingSlots[3].src = all.glowstone
            craftingSlots[4].src = all.glowstone
            craftingSlots[5].src = all.glowstone
            craftingSlots[6].src = all.crying_obsidian
            craftingSlots[7].src = all.crying_obsidian
            craftingSlots[8].src = all.crying_obsidian
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "crying obsidian"
            craftingSlots[1].parentElement.parentElement.title = "crying obsidian"
            craftingSlots[2].parentElement.parentElement.title = "crying obsidian"
            craftingSlots[3].parentElement.parentElement.title = "glowstone"
            craftingSlots[4].parentElement.parentElement.title = "glowstone"
            craftingSlots[5].parentElement.parentElement.title = "glowstone"
            craftingSlots[6].parentElement.parentElement.title = "crying obsidian"
            craftingSlots[7].parentElement.parentElement.title = "crying obsidian"
            craftingSlots[8].parentElement.parentElement.title = "crying obsidian"

            craftingSlots[0].parentElement.parentElement.alt = "crying obsidian"
            craftingSlots[1].parentElement.parentElement.alt = "crying obsidian"
            craftingSlots[2].parentElement.parentElement.alt = "crying obsidian"
            craftingSlots[3].parentElement.parentElement.alt = "glowstone"
            craftingSlots[4].parentElement.parentElement.alt = "glowstone"
            craftingSlots[5].parentElement.parentElement.alt = "glowstone"
            craftingSlots[6].parentElement.parentElement.alt = "crying obsidian"
            craftingSlots[7].parentElement.parentElement.alt = "crying obsidian"
            craftingSlots[8].parentElement.parentElement.alt = "crying obsidian"
            break;
            
        case "rib_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.rib_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.netherrack
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "rib armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "netherrack"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "rib armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "netherrack"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "rib_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "sandstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.sand
            craftingSlots[7].src = all.sand
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "sand"
            craftingSlots[7].parentElement.parentElement.title = "sand"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "sand"
            craftingSlots[7].parentElement.parentElement.alt = "sand"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "sandstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.sandstone
            craftingSlots[7].src = all.sandstone
            craftingSlots[8].src = all.sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "sandstone"
            craftingSlots[7].parentElement.parentElement.title = "sandstone"
            craftingSlots[8].parentElement.parentElement.title = "sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "sandstone"
            break;
            
        case "sandstone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.sandstone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.sandstone
            craftingSlots[4].src = all.sandstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.sandstone
            craftingSlots[7].src = all.sandstone
            craftingSlots[8].src = all.sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "sandstone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "sandstone"
            craftingSlots[4].parentElement.parentElement.title = "sandstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "sandstone"
            craftingSlots[7].parentElement.parentElement.title = "sandstone"
            craftingSlots[8].parentElement.parentElement.title = "sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "sandstone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "sandstone"
            break;
            
        case "sandstone_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.sandstone
            craftingSlots[4].src = all.sandstone
            craftingSlots[5].src = all.sandstone
            craftingSlots[6].src = all.sandstone
            craftingSlots[7].src = all.sandstone
            craftingSlots[8].src = all.sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "sandstone"
            craftingSlots[4].parentElement.parentElement.title = "sandstone"
            craftingSlots[5].parentElement.parentElement.title = "sandstone"
            craftingSlots[6].parentElement.parentElement.title = "sandstone"
            craftingSlots[7].parentElement.parentElement.title = "sandstone"
            craftingSlots[8].parentElement.parentElement.title = "sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "sandstone"
            craftingSlots[6].parentElement.parentElement.alt = "sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "sandstone"
            break;
            
        case "scaffolding":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bamboo
            craftingSlots[1].src = all.string
            craftingSlots[2].src = all.bamboo
            craftingSlots[3].src = all.bamboo
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.bamboo
            craftingSlots[6].src = all.bamboo
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.bamboo
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bamboo"
            craftingSlots[1].parentElement.parentElement.title = "string"
            craftingSlots[2].parentElement.parentElement.title = "bamboo"
            craftingSlots[3].parentElement.parentElement.title = "bamboo"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "bamboo"
            craftingSlots[6].parentElement.parentElement.title = "bamboo"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "bamboo"

            craftingSlots[0].parentElement.parentElement.alt = "bamboo"
            craftingSlots[1].parentElement.parentElement.alt = "string"
            craftingSlots[2].parentElement.parentElement.alt = "bamboo"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "bamboo"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo"
            break;
            
        case "sea_lantern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.prismarine_shard
            craftingSlots[1].src = all.prismarine_crystals
            craftingSlots[2].src = all.prismarine_shard
            craftingSlots[3].src = all.prismarine_crystals
            craftingSlots[4].src = all.prismarine_crystals
            craftingSlots[5].src = all.prismarine_crystals
            craftingSlots[6].src = all.prismarine_shard
            craftingSlots[7].src = all.prismarine_crystals
            craftingSlots[8].src = all.prismarine_shard
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[1].parentElement.parentElement.title = "prismarine crystals"
            craftingSlots[2].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[3].parentElement.parentElement.title = "prismarine crystals"
            craftingSlots[4].parentElement.parentElement.title = "prismarine crystals"
            craftingSlots[5].parentElement.parentElement.title = "prismarine crystals"
            craftingSlots[6].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.title = "prismarine crystals"
            craftingSlots[8].parentElement.parentElement.title = "prismarine shard"

            craftingSlots[0].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[1].parentElement.parentElement.alt = "prismarine crystals"
            craftingSlots[2].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[3].parentElement.parentElement.alt = "prismarine crystals"
            craftingSlots[4].parentElement.parentElement.alt = "prismarine crystals"
            craftingSlots[5].parentElement.parentElement.alt = "prismarine crystals"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine crystals"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine shard"
            break;
            
        case "sentry_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.sentry_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.cobblestone
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "sentry armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "sentry armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "sentry_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "shaper_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.shaper_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.terracotta
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "shaper armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "terracotta"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "shaper armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "terracotta"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "shaper_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "shears":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.iron_ingot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "iron ingot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "shield":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.oak_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "oak planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "oak planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "shield":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "shulker_box":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.shulker_shell
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.shulker_shell
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "shulker shell"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "shulker shell"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "shulker shell"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "shulker shell"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "shulker_box":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "silence_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.silence_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.cobbled_deepslate
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "silence armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "silence armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "silence_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "slime_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.slime_ball
            craftingSlots[1].src = all.slime_ball
            craftingSlots[2].src = all.slime_ball
            craftingSlots[3].src = all.slime_ball
            craftingSlots[4].src = all.slime_ball
            craftingSlots[5].src = all.slime_ball
            craftingSlots[6].src = all.slime_ball
            craftingSlots[7].src = all.slime_ball
            craftingSlots[8].src = all.slime_ball
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "slime ball"
            craftingSlots[1].parentElement.parentElement.title = "slime ball"
            craftingSlots[2].parentElement.parentElement.title = "slime ball"
            craftingSlots[3].parentElement.parentElement.title = "slime ball"
            craftingSlots[4].parentElement.parentElement.title = "slime ball"
            craftingSlots[5].parentElement.parentElement.title = "slime ball"
            craftingSlots[6].parentElement.parentElement.title = "slime ball"
            craftingSlots[7].parentElement.parentElement.title = "slime ball"
            craftingSlots[8].parentElement.parentElement.title = "slime ball"

            craftingSlots[0].parentElement.parentElement.alt = "slime ball"
            craftingSlots[1].parentElement.parentElement.alt = "slime ball"
            craftingSlots[2].parentElement.parentElement.alt = "slime ball"
            craftingSlots[3].parentElement.parentElement.alt = "slime ball"
            craftingSlots[4].parentElement.parentElement.alt = "slime ball"
            craftingSlots[5].parentElement.parentElement.alt = "slime ball"
            craftingSlots[6].parentElement.parentElement.alt = "slime ball"
            craftingSlots[7].parentElement.parentElement.alt = "slime ball"
            craftingSlots[8].parentElement.parentElement.alt = "slime ball"
            break;
            
        case "smithing_table":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.iron_ingot
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "iron ingot"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "smoker":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.oak_log
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_log
            craftingSlots[4].src = all.furnace
            craftingSlots[5].src = all.oak_log
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.oak_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "oak_log"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak_log"
            craftingSlots[4].parentElement.parentElement.title = "furnace"
            craftingSlots[5].parentElement.parentElement.title = "oak_log"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "oak_log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "oak_log"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak_log"
            craftingSlots[4].parentElement.parentElement.alt = "furnace"
            craftingSlots[5].parentElement.parentElement.alt = "oak_log"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "oak_log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "smooth_quartz_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.smooth_quartz
            craftingSlots[7].src = all.smooth_quartz
            craftingSlots[8].src = all.smooth_quartz
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "smooth quartz"
            craftingSlots[7].parentElement.parentElement.title = "smooth quartz"
            craftingSlots[8].parentElement.parentElement.title = "smooth quartz"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "smooth quartz"
            craftingSlots[7].parentElement.parentElement.alt = "smooth quartz"
            craftingSlots[8].parentElement.parentElement.alt = "smooth quartz"
            break;
            
        case "smooth_quartz_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.smooth_quartz
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.smooth_quartz
            craftingSlots[4].src = all.smooth_quartz
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.smooth_quartz
            craftingSlots[7].src = all.smooth_quartz
            craftingSlots[8].src = all.smooth_quartz
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "smooth quartz"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "smooth quartz"
            craftingSlots[4].parentElement.parentElement.title = "smooth quartz"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "smooth quartz"
            craftingSlots[7].parentElement.parentElement.title = "smooth quartz"
            craftingSlots[8].parentElement.parentElement.title = "smooth quartz"

            craftingSlots[0].parentElement.parentElement.alt = "smooth quartz"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "smooth quartz"
            craftingSlots[4].parentElement.parentElement.alt = "smooth quartz"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "smooth quartz"
            craftingSlots[7].parentElement.parentElement.alt = "smooth quartz"
            craftingSlots[8].parentElement.parentElement.alt = "smooth quartz"
            break;
            
        case "smooth_red_sandstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.smooth_red_sandstone
            craftingSlots[7].src = all.smooth_red_sandstone
            craftingSlots[8].src = all.smooth_red_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "smooth red sandstone"
            craftingSlots[7].parentElement.parentElement.title = "smooth red sandstone"
            craftingSlots[8].parentElement.parentElement.title = "smooth red sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "smooth red sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "smooth red sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "smooth red sandstone"
            break;
            
        case "smooth_red_sandstone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.smooth_red_sandstone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.smooth_red_sandstone
            craftingSlots[4].src = all.smooth_red_sandstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.smooth_red_sandstone
            craftingSlots[7].src = all.smooth_red_sandstone
            craftingSlots[8].src = all.smooth_red_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "smooth red sandstone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "smooth red sandstone"
            craftingSlots[4].parentElement.parentElement.title = "smooth red sandstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "smooth red sandstone"
            craftingSlots[7].parentElement.parentElement.title = "smooth red sandstone"
            craftingSlots[8].parentElement.parentElement.title = "smooth red sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "smooth red sandstone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "smooth red sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "smooth red sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "smooth red sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "smooth red sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "smooth red sandstone"
            break;
            
        case "smooth_sandstone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.smooth_sandstone
            craftingSlots[7].src = all.smooth_sandstone
            craftingSlots[8].src = all.smooth_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "smooth sandstone"
            craftingSlots[7].parentElement.parentElement.title = "smooth sandstone"
            craftingSlots[8].parentElement.parentElement.title = "smooth sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "smooth sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "smooth sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "smooth sandstone"
            break;
            
        case "smooth_sandstone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.smooth_sandstone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.smooth_sandstone
            craftingSlots[4].src = all.smooth_sandstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.smooth_sandstone
            craftingSlots[7].src = all.smooth_sandstone
            craftingSlots[8].src = all.smooth_sandstone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "smooth sandstone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "smooth sandstone"
            craftingSlots[4].parentElement.parentElement.title = "smooth sandstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "smooth sandstone"
            craftingSlots[7].parentElement.parentElement.title = "smooth sandstone"
            craftingSlots[8].parentElement.parentElement.title = "smooth sandstone"

            craftingSlots[0].parentElement.parentElement.alt = "smooth sandstone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "smooth sandstone"
            craftingSlots[4].parentElement.parentElement.alt = "smooth sandstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "smooth sandstone"
            craftingSlots[7].parentElement.parentElement.alt = "smooth sandstone"
            craftingSlots[8].parentElement.parentElement.alt = "smooth sandstone"
            break;
            
        case "smooth_stone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.smooth_stone
            craftingSlots[7].src = all.smooth_stone
            craftingSlots[8].src = all.smooth_stone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "smooth stone"
            craftingSlots[7].parentElement.parentElement.title = "smooth stone"
            craftingSlots[8].parentElement.parentElement.title = "smooth stone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "smooth stone"
            craftingSlots[7].parentElement.parentElement.alt = "smooth stone"
            craftingSlots[8].parentElement.parentElement.alt = "smooth stone"
            break;
            
        case "snout_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.snout_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.blackstone
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "snout armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "blackstone"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "snout armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "snout_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "snow":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.snow_block
            craftingSlots[7].src = all.snow_block
            craftingSlots[8].src = all.snow_block
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "snow block"
            craftingSlots[7].parentElement.parentElement.title = "snow block"
            craftingSlots[8].parentElement.parentElement.title = "snow block"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "snow block"
            craftingSlots[7].parentElement.parentElement.alt = "snow block"
            craftingSlots[8].parentElement.parentElement.alt = "snow block"
            break;
            
        case "snow_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.snowball
            craftingSlots[4].src = all.snowball
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.snowball
            craftingSlots[7].src = all.snowball
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "snowball"
            craftingSlots[4].parentElement.parentElement.title = "snowball"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "snowball"
            craftingSlots[7].parentElement.parentElement.title = "snowball"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "snowball"
            craftingSlots[4].parentElement.parentElement.alt = "snowball"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "snowball"
            craftingSlots[7].parentElement.parentElement.alt = "snowball"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "soul_campfire":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.stick
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.soul_fire_base_blocks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.oak_log
            craftingSlots[7].src = all.oak_log
            craftingSlots[8].src = all.oak_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "stick"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "soul fire base blocks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "oak_log"
            craftingSlots[7].parentElement.parentElement.title = "oak_log"
            craftingSlots[8].parentElement.parentElement.title = "oak_log"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "stick"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "soul fire base blocks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "oak_log"
            craftingSlots[7].parentElement.parentElement.alt = "oak_log"
            craftingSlots[8].parentElement.parentElement.alt = "oak_log"
            break;
            
        case "soul_lantern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_nugget
            craftingSlots[1].src = all.iron_nugget
            craftingSlots[2].src = all.iron_nugget
            craftingSlots[3].src = all.iron_nugget
            craftingSlots[4].src = all.soul_torch
            craftingSlots[5].src = all.iron_nugget
            craftingSlots[6].src = all.iron_nugget
            craftingSlots[7].src = all.iron_nugget
            craftingSlots[8].src = all.iron_nugget
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron nugget"
            craftingSlots[1].parentElement.parentElement.title = "iron nugget"
            craftingSlots[2].parentElement.parentElement.title = "iron nugget"
            craftingSlots[3].parentElement.parentElement.title = "iron nugget"
            craftingSlots[4].parentElement.parentElement.title = "soul torch"
            craftingSlots[5].parentElement.parentElement.title = "iron nugget"
            craftingSlots[6].parentElement.parentElement.title = "iron nugget"
            craftingSlots[7].parentElement.parentElement.title = "iron nugget"
            craftingSlots[8].parentElement.parentElement.title = "iron nugget"

            craftingSlots[0].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[1].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[2].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[3].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[4].parentElement.parentElement.alt = "soul torch"
            craftingSlots[5].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[6].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[7].parentElement.parentElement.alt = "iron nugget"
            craftingSlots[8].parentElement.parentElement.alt = "iron nugget"
            break;
            
        case "soul_torch":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.coal
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.soul_fire_base_blocks
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "coal"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "soul fire base blocks"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "coal"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "soul fire base blocks"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spectral_arrow":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.glowstone_dust
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.glowstone_dust
            craftingSlots[4].src = all.arrow
            craftingSlots[5].src = all.glowstone_dust
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.glowstone_dust
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[4].parentElement.parentElement.title = "arrow"
            craftingSlots[5].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "glowstone dust"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[4].parentElement.parentElement.alt = "arrow"
            craftingSlots[5].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "glowstone dust"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spire_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.spire_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.purpur_block
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "spire armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "purpur block"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "spire armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "purpur block"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "spire_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "spruce_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.spruce_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.spruce_planks
            craftingSlots[6].src = all.spruce_planks
            craftingSlots[7].src = all.spruce_planks
            craftingSlots[8].src = all.spruce_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "spruce planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "spruce planks"
            craftingSlots[6].parentElement.parentElement.title = "spruce planks"
            craftingSlots[7].parentElement.parentElement.title = "spruce planks"
            craftingSlots[8].parentElement.parentElement.title = "spruce planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[6].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[7].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[8].parentElement.parentElement.alt = "spruce planks"
            break;
            
        case "spruce_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.spruce_planks
            craftingSlots[1].src = all.spruce_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.spruce_planks
            craftingSlots[4].src = all.spruce_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.spruce_planks
            craftingSlots[7].src = all.spruce_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "spruce planks"
            craftingSlots[1].parentElement.parentElement.title = "spruce planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "spruce planks"
            craftingSlots[4].parentElement.parentElement.title = "spruce planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "spruce planks"
            craftingSlots[7].parentElement.parentElement.title = "spruce planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[1].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[4].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[7].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spruce_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.spruce_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.spruce_planks
            craftingSlots[6].src = all.spruce_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.spruce_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "spruce planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "spruce planks"
            craftingSlots[6].parentElement.parentElement.title = "spruce planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "spruce planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[6].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "spruce planks"
            break;
            
        case "spruce_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.spruce_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.spruce_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "spruce planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "spruce planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "spruce_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_spruce_log
            craftingSlots[4].src = all.stripped_spruce_log
            craftingSlots[5].src = all.stripped_spruce_log
            craftingSlots[6].src = all.stripped_spruce_log
            craftingSlots[7].src = all.stripped_spruce_log
            craftingSlots[8].src = all.stripped_spruce_log
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[4].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[5].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[6].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[7].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[8].parentElement.parentElement.title = "stripped spruce log"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[5].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[6].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[8].parentElement.parentElement.alt = "stripped spruce log"
            break;
            
        case "spruce_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.spruce_planks
            craftingSlots[7].src = all.spruce_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "spruce planks"
            craftingSlots[7].parentElement.parentElement.title = "spruce planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[7].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spruce_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.spruce_planks
            craftingSlots[1].src = all.spruce_planks
            craftingSlots[2].src = all.spruce_planks
            craftingSlots[3].src = all.spruce_planks
            craftingSlots[4].src = all.spruce_planks
            craftingSlots[5].src = all.spruce_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "spruce planks"
            craftingSlots[1].parentElement.parentElement.title = "spruce planks"
            craftingSlots[2].parentElement.parentElement.title = "spruce planks"
            craftingSlots[3].parentElement.parentElement.title = "spruce planks"
            craftingSlots[4].parentElement.parentElement.title = "spruce planks"
            craftingSlots[5].parentElement.parentElement.title = "spruce planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[1].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[2].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[3].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[4].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[5].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spruce_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.spruce_planks
            craftingSlots[7].src = all.spruce_planks
            craftingSlots[8].src = all.spruce_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "spruce planks"
            craftingSlots[7].parentElement.parentElement.title = "spruce planks"
            craftingSlots[8].parentElement.parentElement.title = "spruce planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[7].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[8].parentElement.parentElement.alt = "spruce planks"
            break;
            
        case "spruce_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.spruce_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.spruce_planks
            craftingSlots[4].src = all.spruce_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.spruce_planks
            craftingSlots[7].src = all.spruce_planks
            craftingSlots[8].src = all.spruce_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "spruce planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "spruce planks"
            craftingSlots[4].parentElement.parentElement.title = "spruce planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "spruce planks"
            craftingSlots[7].parentElement.parentElement.title = "spruce planks"
            craftingSlots[8].parentElement.parentElement.title = "spruce planks"

            craftingSlots[0].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[4].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[7].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[8].parentElement.parentElement.alt = "spruce planks"
            break;
            
        case "spruce_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.spruce_planks
            craftingSlots[4].src = all.spruce_planks
            craftingSlots[5].src = all.spruce_planks
            craftingSlots[6].src = all.spruce_planks
            craftingSlots[7].src = all.spruce_planks
            craftingSlots[8].src = all.spruce_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "spruce planks"
            craftingSlots[4].parentElement.parentElement.title = "spruce planks"
            craftingSlots[5].parentElement.parentElement.title = "spruce planks"
            craftingSlots[6].parentElement.parentElement.title = "spruce planks"
            craftingSlots[7].parentElement.parentElement.title = "spruce planks"
            craftingSlots[8].parentElement.parentElement.title = "spruce planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[4].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[5].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[6].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[7].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[8].parentElement.parentElement.alt = "spruce planks"
            break;
            
        case "spruce_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.spruce_log
            craftingSlots[4].src = all.spruce_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.spruce_log
            craftingSlots[7].src = all.spruce_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "spruce log"
            craftingSlots[4].parentElement.parentElement.title = "spruce log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "spruce log"
            craftingSlots[7].parentElement.parentElement.title = "spruce log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "spruce log"
            craftingSlots[4].parentElement.parentElement.alt = "spruce log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "spruce log"
            craftingSlots[7].parentElement.parentElement.alt = "spruce log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spyglass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.amethyst_shard
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.copper_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.copper_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "copper ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "copper ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "copper ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stick":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stick":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.bamboo
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.bamboo
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "bamboo"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "bamboo"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "sticky_piston":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.slime_ball
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.piston
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "slime ball"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "piston"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "slime ball"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "piston"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_axe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.cobblestone
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "cobblestone"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone_bricks
            craftingSlots[7].src = all.stone_bricks
            craftingSlots[8].src = all.stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "stone bricks"
            break;
            
        case "stone_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.stone_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stone_bricks
            craftingSlots[4].src = all.stone_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone_bricks
            craftingSlots[7].src = all.stone_bricks
            craftingSlots[8].src = all.stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stone bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "stone bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "stone bricks"
            break;
            
        case "stone_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stone_bricks
            craftingSlots[4].src = all.stone_bricks
            craftingSlots[5].src = all.stone_bricks
            craftingSlots[6].src = all.stone_bricks
            craftingSlots[7].src = all.stone_bricks
            craftingSlots[8].src = all.stone_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "stone bricks"
            craftingSlots[5].parentElement.parentElement.title = "stone bricks"
            craftingSlots[6].parentElement.parentElement.title = "stone bricks"
            craftingSlots[7].parentElement.parentElement.title = "stone bricks"
            craftingSlots[8].parentElement.parentElement.title = "stone bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[5].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[6].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[7].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[8].parentElement.parentElement.alt = "stone bricks"
            break;
            
        case "stone_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stone
            craftingSlots[4].src = all.stone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone
            craftingSlots[7].src = all.stone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stone"
            craftingSlots[4].parentElement.parentElement.title = "stone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone"
            craftingSlots[7].parentElement.parentElement.title = "stone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stone"
            craftingSlots[4].parentElement.parentElement.alt = "stone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone"
            craftingSlots[7].parentElement.parentElement.alt = "stone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_hoe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.cobblestone
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "cobblestone"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_pickaxe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.cobblestone
            craftingSlots[2].src = all.cobblestone
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "cobblestone"
            craftingSlots[2].parentElement.parentElement.title = "cobblestone"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[2].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone
            craftingSlots[7].src = all.stone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone"
            craftingSlots[7].parentElement.parentElement.title = "stone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone"
            craftingSlots[7].parentElement.parentElement.alt = "stone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_shovel":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone
            craftingSlots[7].src = all.stone
            craftingSlots[8].src = all.stone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone"
            craftingSlots[7].parentElement.parentElement.title = "stone"
            craftingSlots[8].parentElement.parentElement.title = "stone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone"
            craftingSlots[7].parentElement.parentElement.alt = "stone"
            craftingSlots[8].parentElement.parentElement.alt = "stone"
            break;
            
        case "stone_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.stone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stone
            craftingSlots[4].src = all.stone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone
            craftingSlots[7].src = all.stone
            craftingSlots[8].src = all.stone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "stone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stone"
            craftingSlots[4].parentElement.parentElement.title = "stone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone"
            craftingSlots[7].parentElement.parentElement.title = "stone"
            craftingSlots[8].parentElement.parentElement.title = "stone"

            craftingSlots[0].parentElement.parentElement.alt = "stone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stone"
            craftingSlots[4].parentElement.parentElement.alt = "stone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone"
            craftingSlots[7].parentElement.parentElement.alt = "stone"
            craftingSlots[8].parentElement.parentElement.alt = "stone"
            break;
            
        case "stone_sword":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cobblestone
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cobblestone"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stonecutter":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stone
            craftingSlots[7].src = all.stone
            craftingSlots[8].src = all.stone
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stone"
            craftingSlots[7].parentElement.parentElement.title = "stone"
            craftingSlots[8].parentElement.parentElement.title = "stone"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stone"
            craftingSlots[7].parentElement.parentElement.alt = "stone"
            craftingSlots[8].parentElement.parentElement.alt = "stone"
            break;
            
        case "stripped_acacia_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_acacia_log
            craftingSlots[4].src = all.stripped_acacia_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_acacia_log
            craftingSlots[7].src = all.stripped_acacia_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[4].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[7].parentElement.parentElement.title = "stripped acacia log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped acacia log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_birch_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_birch_log
            craftingSlots[4].src = all.stripped_birch_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_birch_log
            craftingSlots[7].src = all.stripped_birch_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[4].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[7].parentElement.parentElement.title = "stripped birch log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped birch log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_cherry_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_cherry_log
            craftingSlots[4].src = all.stripped_cherry_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_cherry_log
            craftingSlots[7].src = all.stripped_cherry_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[4].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[7].parentElement.parentElement.title = "stripped cherry log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped cherry log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_crimson_hyphae":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_crimson_stem
            craftingSlots[4].src = all.stripped_crimson_stem
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_crimson_stem
            craftingSlots[7].src = all.stripped_crimson_stem
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[4].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[7].parentElement.parentElement.title = "stripped crimson stem"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[4].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[7].parentElement.parentElement.alt = "stripped crimson stem"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_dark_oak_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_dark_oak_log
            craftingSlots[4].src = all.stripped_dark_oak_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_dark_oak_log
            craftingSlots[7].src = all.stripped_dark_oak_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[4].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[7].parentElement.parentElement.title = "stripped dark oak log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped dark oak log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_jungle_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_jungle_log
            craftingSlots[4].src = all.stripped_jungle_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_jungle_log
            craftingSlots[7].src = all.stripped_jungle_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[4].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[7].parentElement.parentElement.title = "stripped jungle log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped jungle log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_mangrove_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_mangrove_log
            craftingSlots[4].src = all.stripped_mangrove_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_mangrove_log
            craftingSlots[7].src = all.stripped_mangrove_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[4].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[7].parentElement.parentElement.title = "stripped mangrove log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped mangrove log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_oak_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_oak_log
            craftingSlots[4].src = all.stripped_oak_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_oak_log
            craftingSlots[7].src = all.stripped_oak_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[4].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[7].parentElement.parentElement.title = "stripped oak log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped oak log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_spruce_wood":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_spruce_log
            craftingSlots[4].src = all.stripped_spruce_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_spruce_log
            craftingSlots[7].src = all.stripped_spruce_log
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[4].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[7].parentElement.parentElement.title = "stripped spruce log"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[4].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[7].parentElement.parentElement.alt = "stripped spruce log"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stripped_warped_hyphae":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stripped_warped_stem
            craftingSlots[4].src = all.stripped_warped_stem
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stripped_warped_stem
            craftingSlots[7].src = all.stripped_warped_stem
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[4].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[7].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[4].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[7].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "sugar":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "target":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.redstone
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.redstone
            craftingSlots[4].src = all.hay_block
            craftingSlots[5].src = all.redstone
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "redstone"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "redstone"
            craftingSlots[4].parentElement.parentElement.title = "hay block"
            craftingSlots[5].parentElement.parentElement.title = "redstone"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "redstone"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "redstone"
            craftingSlots[4].parentElement.parentElement.alt = "hay block"
            craftingSlots[5].parentElement.parentElement.alt = "redstone"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "tide_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.tide_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.prismarine
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "tide armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "prismarine"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "tide armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "prismarine"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "tide_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "tinted_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.amethyst_shard
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.amethyst_shard
            craftingSlots[4].src = all.glass
            craftingSlots[5].src = all.amethyst_shard
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.amethyst_shard
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[4].parentElement.parentElement.title = "glass"
            craftingSlots[5].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "amethyst shard"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[4].parentElement.parentElement.alt = "glass"
            craftingSlots[5].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "amethyst shard"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "tinted_glass":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "tnt":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gunpowder
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.gunpowder
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.gunpowder
            craftingSlots[5].src = all.sand
            craftingSlots[6].src = all.gunpowder
            craftingSlots[7].src = all.sand
            craftingSlots[8].src = all.gunpowder
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gunpowder"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "gunpowder"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "gunpowder"
            craftingSlots[5].parentElement.parentElement.title = "sand"
            craftingSlots[6].parentElement.parentElement.title = "gunpowder"
            craftingSlots[7].parentElement.parentElement.title = "sand"
            craftingSlots[8].parentElement.parentElement.title = "gunpowder"

            craftingSlots[0].parentElement.parentElement.alt = "gunpowder"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "gunpowder"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "gunpowder"
            craftingSlots[5].parentElement.parentElement.alt = "sand"
            craftingSlots[6].parentElement.parentElement.alt = "gunpowder"
            craftingSlots[7].parentElement.parentElement.alt = "sand"
            craftingSlots[8].parentElement.parentElement.alt = "gunpowder"
            break;
            
        case "torch":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.coal
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "coal"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "coal"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "tripwire_hook":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.iron_ingot
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "iron ingot"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "tuff_brick_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.tuff_bricks
            craftingSlots[7].src = all.tuff_bricks
            craftingSlots[8].src = all.tuff_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[7].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[8].parentElement.parentElement.title = "tuff bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[7].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[8].parentElement.parentElement.alt = "tuff bricks"
            break;
            
        case "tuff_brick_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.tuff_bricks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tuff_bricks
            craftingSlots[4].src = all.tuff_bricks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.tuff_bricks
            craftingSlots[7].src = all.tuff_bricks
            craftingSlots[8].src = all.tuff_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[4].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[7].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[8].parentElement.parentElement.title = "tuff bricks"

            craftingSlots[0].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[4].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[7].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[8].parentElement.parentElement.alt = "tuff bricks"
            break;
            
        case "tuff_brick_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tuff_bricks
            craftingSlots[4].src = all.tuff_bricks
            craftingSlots[5].src = all.tuff_bricks
            craftingSlots[6].src = all.tuff_bricks
            craftingSlots[7].src = all.tuff_bricks
            craftingSlots[8].src = all.tuff_bricks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[4].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[5].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[6].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[7].parentElement.parentElement.title = "tuff bricks"
            craftingSlots[8].parentElement.parentElement.title = "tuff bricks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[4].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[5].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[6].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[7].parentElement.parentElement.alt = "tuff bricks"
            craftingSlots[8].parentElement.parentElement.alt = "tuff bricks"
            break;
            
        case "tuff_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.polished_tuff
            craftingSlots[4].src = all.polished_tuff
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.polished_tuff
            craftingSlots[7].src = all.polished_tuff
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "polished tuff"
            craftingSlots[4].parentElement.parentElement.title = "polished tuff"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "polished tuff"
            craftingSlots[7].parentElement.parentElement.title = "polished tuff"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[4].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[7].parentElement.parentElement.alt = "polished tuff"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "tuff_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.tuff
            craftingSlots[7].src = all.tuff
            craftingSlots[8].src = all.tuff
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "tuff"
            craftingSlots[7].parentElement.parentElement.title = "tuff"
            craftingSlots[8].parentElement.parentElement.title = "tuff"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "tuff"
            craftingSlots[7].parentElement.parentElement.alt = "tuff"
            craftingSlots[8].parentElement.parentElement.alt = "tuff"
            break;
            
        case "tuff_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.tuff
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tuff
            craftingSlots[4].src = all.tuff
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.tuff
            craftingSlots[7].src = all.tuff
            craftingSlots[8].src = all.tuff
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "tuff"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tuff"
            craftingSlots[4].parentElement.parentElement.title = "tuff"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "tuff"
            craftingSlots[7].parentElement.parentElement.title = "tuff"
            craftingSlots[8].parentElement.parentElement.title = "tuff"

            craftingSlots[0].parentElement.parentElement.alt = "tuff"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tuff"
            craftingSlots[4].parentElement.parentElement.alt = "tuff"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "tuff"
            craftingSlots[7].parentElement.parentElement.alt = "tuff"
            craftingSlots[8].parentElement.parentElement.alt = "tuff"
            break;
            
        case "tuff_wall":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tuff
            craftingSlots[4].src = all.tuff
            craftingSlots[5].src = all.tuff
            craftingSlots[6].src = all.tuff
            craftingSlots[7].src = all.tuff
            craftingSlots[8].src = all.tuff
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tuff"
            craftingSlots[4].parentElement.parentElement.title = "tuff"
            craftingSlots[5].parentElement.parentElement.title = "tuff"
            craftingSlots[6].parentElement.parentElement.title = "tuff"
            craftingSlots[7].parentElement.parentElement.title = "tuff"
            craftingSlots[8].parentElement.parentElement.title = "tuff"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tuff"
            craftingSlots[4].parentElement.parentElement.alt = "tuff"
            craftingSlots[5].parentElement.parentElement.alt = "tuff"
            craftingSlots[6].parentElement.parentElement.alt = "tuff"
            craftingSlots[7].parentElement.parentElement.alt = "tuff"
            craftingSlots[8].parentElement.parentElement.alt = "tuff"
            break;
            
        case "turtle_helmet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.turtle_scute
            craftingSlots[4].src = all.turtle_scute
            craftingSlots[5].src = all.turtle_scute
            craftingSlots[6].src = all.turtle_scute
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.turtle_scute
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "turtle scute"
            craftingSlots[4].parentElement.parentElement.title = "turtle scute"
            craftingSlots[5].parentElement.parentElement.title = "turtle scute"
            craftingSlots[6].parentElement.parentElement.title = "turtle scute"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "turtle scute"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "turtle scute"
            craftingSlots[4].parentElement.parentElement.alt = "turtle scute"
            craftingSlots[5].parentElement.parentElement.alt = "turtle scute"
            craftingSlots[6].parentElement.parentElement.alt = "turtle scute"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "turtle scute"
            break;
            
        case "vex_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.vex_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.cobblestone
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "vex armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "vex armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "vex_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "ward_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.ward_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.cobbled_deepslate
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "ward armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "ward armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "cobbled deepslate"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "ward_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "warped_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.warped_planks
            craftingSlots[1].src = all.warped_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.warped_planks
            craftingSlots[4].src = all.warped_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.warped_planks
            craftingSlots[7].src = all.warped_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "warped planks"
            craftingSlots[1].parentElement.parentElement.title = "warped planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "warped planks"
            craftingSlots[4].parentElement.parentElement.title = "warped planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "warped planks"
            craftingSlots[7].parentElement.parentElement.title = "warped planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "warped planks"
            craftingSlots[1].parentElement.parentElement.alt = "warped planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "warped planks"
            craftingSlots[4].parentElement.parentElement.alt = "warped planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "warped planks"
            craftingSlots[7].parentElement.parentElement.alt = "warped planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "warped_fence":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.warped_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.warped_planks
            craftingSlots[6].src = all.warped_planks
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.warped_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "warped planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "warped planks"
            craftingSlots[6].parentElement.parentElement.title = "warped planks"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "warped planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "warped planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "warped planks"
            craftingSlots[6].parentElement.parentElement.alt = "warped planks"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "warped planks"
            break;
            
        case "warped_fence_gate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.warped_planks
            craftingSlots[5].src = all.stick
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.warped_planks
            craftingSlots[8].src = all.stick
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "warped planks"
            craftingSlots[5].parentElement.parentElement.title = "stick"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "warped planks"
            craftingSlots[8].parentElement.parentElement.title = "stick"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "warped planks"
            craftingSlots[5].parentElement.parentElement.alt = "stick"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "warped planks"
            craftingSlots[8].parentElement.parentElement.alt = "stick"
            break;
            
        case "warped_fungus_on_a_stick":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.fishing_rod
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.warped_fungus
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "fishing rod"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "warped fungus"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "fishing rod"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "warped fungus"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "warped_hanging_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.chain
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.chain
            craftingSlots[3].src = all.stripped_warped_stem
            craftingSlots[4].src = all.stripped_warped_stem
            craftingSlots[5].src = all.stripped_warped_stem
            craftingSlots[6].src = all.stripped_warped_stem
            craftingSlots[7].src = all.stripped_warped_stem
            craftingSlots[8].src = all.stripped_warped_stem
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "chain"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "chain"
            craftingSlots[3].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[4].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[5].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[6].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[7].parentElement.parentElement.title = "stripped warped stem"
            craftingSlots[8].parentElement.parentElement.title = "stripped warped stem"

            craftingSlots[0].parentElement.parentElement.alt = "chain"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "chain"
            craftingSlots[3].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[4].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[5].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[6].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[7].parentElement.parentElement.alt = "stripped warped stem"
            craftingSlots[8].parentElement.parentElement.alt = "stripped warped stem"
            break;
            
        case "warped_hyphae":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.warped_stem
            craftingSlots[4].src = all.warped_stem
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.warped_stem
            craftingSlots[7].src = all.warped_stem
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "warped stem"
            craftingSlots[4].parentElement.parentElement.title = "warped stem"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "warped stem"
            craftingSlots[7].parentElement.parentElement.title = "warped stem"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "warped stem"
            craftingSlots[4].parentElement.parentElement.alt = "warped stem"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "warped stem"
            craftingSlots[7].parentElement.parentElement.alt = "warped stem"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "warped_pressure_plate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.warped_planks
            craftingSlots[7].src = all.warped_planks
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "warped planks"
            craftingSlots[7].parentElement.parentElement.title = "warped planks"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "warped planks"
            craftingSlots[7].parentElement.parentElement.alt = "warped planks"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "warped_sign":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.warped_planks
            craftingSlots[1].src = all.warped_planks
            craftingSlots[2].src = all.warped_planks
            craftingSlots[3].src = all.warped_planks
            craftingSlots[4].src = all.warped_planks
            craftingSlots[5].src = all.warped_planks
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "warped planks"
            craftingSlots[1].parentElement.parentElement.title = "warped planks"
            craftingSlots[2].parentElement.parentElement.title = "warped planks"
            craftingSlots[3].parentElement.parentElement.title = "warped planks"
            craftingSlots[4].parentElement.parentElement.title = "warped planks"
            craftingSlots[5].parentElement.parentElement.title = "warped planks"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "warped planks"
            craftingSlots[1].parentElement.parentElement.alt = "warped planks"
            craftingSlots[2].parentElement.parentElement.alt = "warped planks"
            craftingSlots[3].parentElement.parentElement.alt = "warped planks"
            craftingSlots[4].parentElement.parentElement.alt = "warped planks"
            craftingSlots[5].parentElement.parentElement.alt = "warped planks"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "warped_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.warped_planks
            craftingSlots[7].src = all.warped_planks
            craftingSlots[8].src = all.warped_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "warped planks"
            craftingSlots[7].parentElement.parentElement.title = "warped planks"
            craftingSlots[8].parentElement.parentElement.title = "warped planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "warped planks"
            craftingSlots[7].parentElement.parentElement.alt = "warped planks"
            craftingSlots[8].parentElement.parentElement.alt = "warped planks"
            break;
            
        case "warped_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.warped_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.warped_planks
            craftingSlots[4].src = all.warped_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.warped_planks
            craftingSlots[7].src = all.warped_planks
            craftingSlots[8].src = all.warped_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "warped planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "warped planks"
            craftingSlots[4].parentElement.parentElement.title = "warped planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "warped planks"
            craftingSlots[7].parentElement.parentElement.title = "warped planks"
            craftingSlots[8].parentElement.parentElement.title = "warped planks"

            craftingSlots[0].parentElement.parentElement.alt = "warped planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "warped planks"
            craftingSlots[4].parentElement.parentElement.alt = "warped planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "warped planks"
            craftingSlots[7].parentElement.parentElement.alt = "warped planks"
            craftingSlots[8].parentElement.parentElement.alt = "warped planks"
            break;
            
        case "warped_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.warped_planks
            craftingSlots[4].src = all.warped_planks
            craftingSlots[5].src = all.warped_planks
            craftingSlots[6].src = all.warped_planks
            craftingSlots[7].src = all.warped_planks
            craftingSlots[8].src = all.warped_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "warped planks"
            craftingSlots[4].parentElement.parentElement.title = "warped planks"
            craftingSlots[5].parentElement.parentElement.title = "warped planks"
            craftingSlots[6].parentElement.parentElement.title = "warped planks"
            craftingSlots[7].parentElement.parentElement.title = "warped planks"
            craftingSlots[8].parentElement.parentElement.title = "warped planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "warped planks"
            craftingSlots[4].parentElement.parentElement.alt = "warped planks"
            craftingSlots[5].parentElement.parentElement.alt = "warped planks"
            craftingSlots[6].parentElement.parentElement.alt = "warped planks"
            craftingSlots[7].parentElement.parentElement.alt = "warped planks"
            craftingSlots[8].parentElement.parentElement.alt = "warped planks"
            break;
            
        case "waxed_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_copper_block
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_copper_block
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.waxed_copper_block
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_copper_block
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_copper_block
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.waxed_copper_block
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.waxed_copper_block
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_copper_block
            craftingSlots[4].src = all.waxed_copper_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_copper_block
            craftingSlots[7].src = all.waxed_copper_block
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[4].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[7].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[4].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[7].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_cut_copper
            craftingSlots[7].src = all.waxed_cut_copper
            craftingSlots[8].src = all.waxed_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed cut copper"
            break;
            
        case "waxed_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.waxed_cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_cut_copper
            craftingSlots[4].src = all.waxed_cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_cut_copper
            craftingSlots[7].src = all.waxed_cut_copper
            craftingSlots[8].src = all.waxed_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "waxed cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed cut copper"
            craftingSlots[4].parentElement.parentElement.title = "waxed cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "waxed cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "waxed cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed cut copper"
            break;
            
        case "waxed_exposed_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_exposed_cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_exposed_cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed exposed cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed exposed cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed exposed cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed exposed cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_exposed_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_exposed_copper
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.waxed_exposed_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_exposed_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_exposed_copper
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.waxed_exposed_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.waxed_exposed_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_exposed_copper
            craftingSlots[4].src = all.waxed_exposed_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_exposed_copper
            craftingSlots[7].src = all.waxed_exposed_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[4].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed exposed copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[4].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed exposed copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_exposed_cut_copper
            craftingSlots[7].src = all.waxed_exposed_cut_copper
            craftingSlots[8].src = all.waxed_exposed_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed exposed cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed exposed cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed exposed cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed exposed cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed exposed cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed exposed cut copper"
            break;
            
        case "waxed_exposed_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.waxed_exposed_cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_exposed_cut_copper
            craftingSlots[4].src = all.waxed_exposed_cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_exposed_cut_copper
            craftingSlots[7].src = all.waxed_exposed_cut_copper
            craftingSlots[8].src = all.waxed_exposed_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "waxed exposed cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed exposed cut copper"
            craftingSlots[4].parentElement.parentElement.title = "waxed exposed cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed exposed cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed exposed cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed exposed cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "waxed exposed cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed exposed cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "waxed exposed cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed exposed cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed exposed cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed exposed cut copper"
            break;
            
        case "waxed_oxidized_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_oxidized_cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_oxidized_cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed oxidized cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed oxidized cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed oxidized cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed oxidized cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_oxidized_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_oxidized_copper
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.waxed_oxidized_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_oxidized_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_oxidized_copper
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.waxed_oxidized_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.waxed_oxidized_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_oxidized_copper
            craftingSlots[4].src = all.waxed_oxidized_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_oxidized_copper
            craftingSlots[7].src = all.waxed_oxidized_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[4].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed oxidized copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[4].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed oxidized copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_oxidized_cut_copper
            craftingSlots[7].src = all.waxed_oxidized_cut_copper
            craftingSlots[8].src = all.waxed_oxidized_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed oxidized cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed oxidized cut copper"
            break;
            
        case "waxed_oxidized_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.waxed_oxidized_cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_oxidized_cut_copper
            craftingSlots[4].src = all.waxed_oxidized_cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_oxidized_cut_copper
            craftingSlots[7].src = all.waxed_oxidized_cut_copper
            craftingSlots[8].src = all.waxed_oxidized_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "waxed oxidized cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed oxidized cut copper"
            craftingSlots[4].parentElement.parentElement.title = "waxed oxidized cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed oxidized cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "waxed oxidized cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed oxidized cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "waxed oxidized cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed oxidized cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed oxidized cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed oxidized cut copper"
            break;
            
        case "waxed_weathered_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_weathered_cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_weathered_cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed weathered cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed weathered cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed weathered cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed weathered cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_weathered_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_weathered_copper
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.waxed_weathered_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.waxed_weathered_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_weathered_copper
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.waxed_weathered_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.waxed_weathered_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_weathered_copper
            craftingSlots[4].src = all.waxed_weathered_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_weathered_copper
            craftingSlots[7].src = all.waxed_weathered_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[4].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed weathered copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[4].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed weathered copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_weathered_cut_copper
            craftingSlots[7].src = all.waxed_weathered_cut_copper
            craftingSlots[8].src = all.waxed_weathered_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed weathered cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed weathered cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed weathered cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed weathered cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed weathered cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed weathered cut copper"
            break;
            
        case "waxed_weathered_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.waxed_weathered_cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.waxed_weathered_cut_copper
            craftingSlots[4].src = all.waxed_weathered_cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.waxed_weathered_cut_copper
            craftingSlots[7].src = all.waxed_weathered_cut_copper
            craftingSlots[8].src = all.waxed_weathered_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "waxed weathered cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "waxed weathered cut copper"
            craftingSlots[4].parentElement.parentElement.title = "waxed weathered cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "waxed weathered cut copper"
            craftingSlots[7].parentElement.parentElement.title = "waxed weathered cut copper"
            craftingSlots[8].parentElement.parentElement.title = "waxed weathered cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "waxed weathered cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "waxed weathered cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "waxed weathered cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "waxed weathered cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "waxed weathered cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "waxed weathered cut copper"
            break;
            
        case "wayfinder_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.wayfinder_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.terracotta
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "wayfinder armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "terracotta"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "wayfinder armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "terracotta"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "wayfinder_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "weathered_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_cut_copper_slab
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.weathered_cut_copper_slab
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "weathered cut copper slab"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "weathered cut copper slab"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "weathered_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.weathered_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.weathered_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.redstone
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "weathered copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "weathered copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "redstone"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "redstone"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "weathered_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.weathered_copper
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.weathered_copper
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.weathered_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "weathered copper"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "weathered copper"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "weathered copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "weathered_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper
            craftingSlots[4].src = all.weathered_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.weathered_copper
            craftingSlots[7].src = all.weathered_copper
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper"
            craftingSlots[4].parentElement.parentElement.title = "weathered copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "weathered copper"
            craftingSlots[7].parentElement.parentElement.title = "weathered copper"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[4].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[7].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "weathered_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.weathered_cut_copper
            craftingSlots[7].src = all.weathered_cut_copper
            craftingSlots[8].src = all.weathered_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[7].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[8].parentElement.parentElement.title = "weathered cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "weathered cut copper"
            break;
            
        case "weathered_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.weathered_cut_copper
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_cut_copper
            craftingSlots[4].src = all.weathered_cut_copper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.weathered_cut_copper
            craftingSlots[7].src = all.weathered_cut_copper
            craftingSlots[8].src = all.weathered_cut_copper
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[4].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[7].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[8].parentElement.parentElement.title = "weathered cut copper"

            craftingSlots[0].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[7].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[8].parentElement.parentElement.alt = "weathered cut copper"
            break;
            
        case "white_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.white_wool
            craftingSlots[1].src = all.white_wool
            craftingSlots[2].src = all.white_wool
            craftingSlots[3].src = all.white_wool
            craftingSlots[4].src = all.white_wool
            craftingSlots[5].src = all.white_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "white wool"
            craftingSlots[1].parentElement.parentElement.title = "white wool"
            craftingSlots[2].parentElement.parentElement.title = "white wool"
            craftingSlots[3].parentElement.parentElement.title = "white wool"
            craftingSlots[4].parentElement.parentElement.title = "white wool"
            craftingSlots[5].parentElement.parentElement.title = "white wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "white wool"
            craftingSlots[1].parentElement.parentElement.alt = "white wool"
            craftingSlots[2].parentElement.parentElement.alt = "white wool"
            craftingSlots[3].parentElement.parentElement.alt = "white wool"
            craftingSlots[4].parentElement.parentElement.alt = "white wool"
            craftingSlots[5].parentElement.parentElement.alt = "white wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.white_wool
            craftingSlots[4].src = all.white_wool
            craftingSlots[5].src = all.white_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "white wool"
            craftingSlots[4].parentElement.parentElement.title = "white wool"
            craftingSlots[5].parentElement.parentElement.title = "white wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "white wool"
            craftingSlots[4].parentElement.parentElement.alt = "white wool"
            craftingSlots[5].parentElement.parentElement.alt = "white wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "white_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.white_wool
            craftingSlots[7].src = all.white_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "white wool"
            craftingSlots[7].parentElement.parentElement.title = "white wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "white wool"
            craftingSlots[7].parentElement.parentElement.alt = "white wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "white_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.white_stained_glass
            craftingSlots[4].src = all.white_stained_glass
            craftingSlots[5].src = all.white_stained_glass
            craftingSlots[6].src = all.white_stained_glass
            craftingSlots[7].src = all.white_stained_glass
            craftingSlots[8].src = all.white_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "white stained glass"
            craftingSlots[4].parentElement.parentElement.title = "white stained glass"
            craftingSlots[5].parentElement.parentElement.title = "white stained glass"
            craftingSlots[6].parentElement.parentElement.title = "white stained glass"
            craftingSlots[7].parentElement.parentElement.title = "white stained glass"
            craftingSlots[8].parentElement.parentElement.title = "white stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "white stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "white stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "white stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "white stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "white stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "white stained glass"
            break;
            
        case "white_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "white_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;
            
        case "white_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.string
            craftingSlots[4].src = all.string
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.string
            craftingSlots[7].src = all.string
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "string"
            craftingSlots[4].parentElement.parentElement.title = "string"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "string"
            craftingSlots[7].parentElement.parentElement.title = "string"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "string"
            craftingSlots[4].parentElement.parentElement.alt = "string"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "string"
            craftingSlots[7].parentElement.parentElement.alt = "string"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "wild_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.diamond
            craftingSlots[1].src = all.wild_armor_trim_smithing_template
            craftingSlots[2].src = all.diamond
            craftingSlots[3].src = all.diamond
            craftingSlots[4].src = all.mossy_cobblestone
            craftingSlots[5].src = all.diamond
            craftingSlots[6].src = all.diamond
            craftingSlots[7].src = all.diamond
            craftingSlots[8].src = all.diamond
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "diamond"
            craftingSlots[1].parentElement.parentElement.title = "wild armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.title = "diamond"
            craftingSlots[3].parentElement.parentElement.title = "diamond"
            craftingSlots[4].parentElement.parentElement.title = "mossy cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "diamond"
            craftingSlots[6].parentElement.parentElement.title = "diamond"
            craftingSlots[7].parentElement.parentElement.title = "diamond"
            craftingSlots[8].parentElement.parentElement.title = "diamond"

            craftingSlots[0].parentElement.parentElement.alt = "diamond"
            craftingSlots[1].parentElement.parentElement.alt = "wild armor trim smithing template"
            craftingSlots[2].parentElement.parentElement.alt = "diamond"
            craftingSlots[3].parentElement.parentElement.alt = "diamond"
            craftingSlots[4].parentElement.parentElement.alt = "mossy cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "diamond"
            craftingSlots[6].parentElement.parentElement.alt = "diamond"
            craftingSlots[7].parentElement.parentElement.alt = "diamond"
            craftingSlots[8].parentElement.parentElement.alt = "diamond"
            break;
            
                case "wild_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    craftedItem.title = clickedItem.title
                    craftedItem.alt = clickedItem.alt
                    break;
                    
        case "wolf_armor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.armadillo_scute
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.armadillo_scute
            craftingSlots[4].src = all.armadillo_scute
            craftingSlots[5].src = all.armadillo_scute
            craftingSlots[6].src = all.armadillo_scute
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.armadillo_scute
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "armadillo scute"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "armadillo scute"
            craftingSlots[4].parentElement.parentElement.title = "armadillo scute"
            craftingSlots[5].parentElement.parentElement.title = "armadillo scute"
            craftingSlots[6].parentElement.parentElement.title = "armadillo scute"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "armadillo scute"

            craftingSlots[0].parentElement.parentElement.alt = "armadillo scute"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "armadillo scute"
            craftingSlots[4].parentElement.parentElement.alt = "armadillo scute"
            craftingSlots[5].parentElement.parentElement.alt = "armadillo scute"
            craftingSlots[6].parentElement.parentElement.alt = "armadillo scute"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "armadillo scute"
            break;
            
        case "wooden_axe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "wooden_hoe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "wooden_pickaxe":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.oak_planks
            craftingSlots[2].src = all.oak_planks
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stick
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "oak planks"
            craftingSlots[2].parentElement.parentElement.title = "oak planks"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stick"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "oak planks"
            craftingSlots[2].parentElement.parentElement.alt = "oak planks"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stick"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "wooden_shovel":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stick
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stick"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stick"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "wooden_sword":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.oak_planks
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oak_planks
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.stick
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "oak planks"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oak planks"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "stick"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "oak planks"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oak planks"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "stick"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_banner":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.yellow_wool
            craftingSlots[1].src = all.yellow_wool
            craftingSlots[2].src = all.yellow_wool
            craftingSlots[3].src = all.yellow_wool
            craftingSlots[4].src = all.yellow_wool
            craftingSlots[5].src = all.yellow_wool
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.stick
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "yellow wool"
            craftingSlots[1].parentElement.parentElement.title = "yellow wool"
            craftingSlots[2].parentElement.parentElement.title = "yellow wool"
            craftingSlots[3].parentElement.parentElement.title = "yellow wool"
            craftingSlots[4].parentElement.parentElement.title = "yellow wool"
            craftingSlots[5].parentElement.parentElement.title = "yellow wool"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "stick"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[1].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[2].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[3].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[4].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[5].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "stick"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.yellow_wool
            craftingSlots[4].src = all.yellow_wool
            craftingSlots[5].src = all.yellow_wool
            craftingSlots[6].src = all.oak_planks
            craftingSlots[7].src = all.oak_planks
            craftingSlots[8].src = all.oak_planks
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "yellow wool"
            craftingSlots[4].parentElement.parentElement.title = "yellow wool"
            craftingSlots[5].parentElement.parentElement.title = "yellow wool"
            craftingSlots[6].parentElement.parentElement.title = "oak planks"
            craftingSlots[7].parentElement.parentElement.title = "oak planks"
            craftingSlots[8].parentElement.parentElement.title = "oak planks"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[4].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[5].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[6].parentElement.parentElement.alt = "oak planks"
            craftingSlots[7].parentElement.parentElement.alt = "oak planks"
            craftingSlots[8].parentElement.parentElement.alt = "oak planks"
            break;
            
        case "yellow_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.yellow_wool
            craftingSlots[7].src = all.yellow_wool
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "yellow wool"
            craftingSlots[7].parentElement.parentElement.title = "yellow wool"
            craftingSlots[8].parentElement.parentElement.title = "empty"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[7].parentElement.parentElement.alt = "yellow wool"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_stained_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass
            craftingSlots[1].src = all.glass
            craftingSlots[2].src = all.glass
            craftingSlots[3].src = all.glass
            craftingSlots[4].src = all.yellow_dye
            craftingSlots[5].src = all.glass
            craftingSlots[6].src = all.glass
            craftingSlots[7].src = all.glass
            craftingSlots[8].src = all.glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass"
            craftingSlots[1].parentElement.parentElement.title = "glass"
            craftingSlots[2].parentElement.parentElement.title = "glass"
            craftingSlots[3].parentElement.parentElement.title = "glass"
            craftingSlots[4].parentElement.parentElement.title = "yellow dye"
            craftingSlots[5].parentElement.parentElement.title = "glass"
            craftingSlots[6].parentElement.parentElement.title = "glass"
            craftingSlots[7].parentElement.parentElement.title = "glass"
            craftingSlots[8].parentElement.parentElement.title = "glass"

            craftingSlots[0].parentElement.parentElement.alt = "glass"
            craftingSlots[1].parentElement.parentElement.alt = "glass"
            craftingSlots[2].parentElement.parentElement.alt = "glass"
            craftingSlots[3].parentElement.parentElement.alt = "glass"
            craftingSlots[4].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass"
            craftingSlots[6].parentElement.parentElement.alt = "glass"
            craftingSlots[7].parentElement.parentElement.alt = "glass"
            craftingSlots[8].parentElement.parentElement.alt = "glass"
            break;
            
        case "yellow_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.yellow_stained_glass
            craftingSlots[4].src = all.yellow_stained_glass
            craftingSlots[5].src = all.yellow_stained_glass
            craftingSlots[6].src = all.yellow_stained_glass
            craftingSlots[7].src = all.yellow_stained_glass
            craftingSlots[8].src = all.yellow_stained_glass
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "yellow stained glass"
            craftingSlots[4].parentElement.parentElement.title = "yellow stained glass"
            craftingSlots[5].parentElement.parentElement.title = "yellow stained glass"
            craftingSlots[6].parentElement.parentElement.title = "yellow stained glass"
            craftingSlots[7].parentElement.parentElement.title = "yellow stained glass"
            craftingSlots[8].parentElement.parentElement.title = "yellow stained glass"

            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "yellow stained glass"
            craftingSlots[4].parentElement.parentElement.alt = "yellow stained glass"
            craftingSlots[5].parentElement.parentElement.alt = "yellow stained glass"
            craftingSlots[6].parentElement.parentElement.alt = "yellow stained glass"
            craftingSlots[7].parentElement.parentElement.alt = "yellow stained glass"
            craftingSlots[8].parentElement.parentElement.alt = "yellow stained glass"
            break;
            
        case "yellow_stained_glass_pane":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.glass_pane
            craftingSlots[1].src = all.glass_pane
            craftingSlots[2].src = all.glass_pane
            craftingSlots[3].src = all.glass_pane
            craftingSlots[4].src = all.yellow_dye
            craftingSlots[5].src = all.glass_pane
            craftingSlots[6].src = all.glass_pane
            craftingSlots[7].src = all.glass_pane
            craftingSlots[8].src = all.glass_pane
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "glass pane"
            craftingSlots[1].parentElement.parentElement.title = "glass pane"
            craftingSlots[2].parentElement.parentElement.title = "glass pane"
            craftingSlots[3].parentElement.parentElement.title = "glass pane"
            craftingSlots[4].parentElement.parentElement.title = "yellow dye"
            craftingSlots[5].parentElement.parentElement.title = "glass pane"
            craftingSlots[6].parentElement.parentElement.title = "glass pane"
            craftingSlots[7].parentElement.parentElement.title = "glass pane"
            craftingSlots[8].parentElement.parentElement.title = "glass pane"

            craftingSlots[0].parentElement.parentElement.alt = "glass pane"
            craftingSlots[1].parentElement.parentElement.alt = "glass pane"
            craftingSlots[2].parentElement.parentElement.alt = "glass pane"
            craftingSlots[3].parentElement.parentElement.alt = "glass pane"
            craftingSlots[4].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[5].parentElement.parentElement.alt = "glass pane"
            craftingSlots[6].parentElement.parentElement.alt = "glass pane"
            craftingSlots[7].parentElement.parentElement.alt = "glass pane"
            craftingSlots[8].parentElement.parentElement.alt = "glass pane"
            break;
            
        case "yellow_terracotta":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.terracotta
            craftingSlots[1].src = all.terracotta
            craftingSlots[2].src = all.terracotta
            craftingSlots[3].src = all.terracotta
            craftingSlots[4].src = all.yellow_dye
            craftingSlots[5].src = all.terracotta
            craftingSlots[6].src = all.terracotta
            craftingSlots[7].src = all.terracotta
            craftingSlots[8].src = all.terracotta
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "terracotta"
            craftingSlots[1].parentElement.parentElement.title = "terracotta"
            craftingSlots[2].parentElement.parentElement.title = "terracotta"
            craftingSlots[3].parentElement.parentElement.title = "terracotta"
            craftingSlots[4].parentElement.parentElement.title = "yellow dye"
            craftingSlots[5].parentElement.parentElement.title = "terracotta"
            craftingSlots[6].parentElement.parentElement.title = "terracotta"
            craftingSlots[7].parentElement.parentElement.title = "terracotta"
            craftingSlots[8].parentElement.parentElement.title = "terracotta"

            craftingSlots[0].parentElement.parentElement.alt = "terracotta"
            craftingSlots[1].parentElement.parentElement.alt = "terracotta"
            craftingSlots[2].parentElement.parentElement.alt = "terracotta"
            craftingSlots[3].parentElement.parentElement.alt = "terracotta"
            craftingSlots[4].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[5].parentElement.parentElement.alt = "terracotta"
            craftingSlots[6].parentElement.parentElement.alt = "terracotta"
            craftingSlots[7].parentElement.parentElement.alt = "terracotta"
            craftingSlots[8].parentElement.parentElement.alt = "terracotta"
            break;

        //#endregion Shaped

        //#region Shapeless

        case "acacia_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.acacia_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "acacia planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "acacia planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "acacia_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.acacia_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "acacia boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "acacia boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "acacia_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.acacia_logs
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "acacia logs"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "acacia logs"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "andesite":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diorite
            craftingSlots[4].src = all.cobblestone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diorite"
            craftingSlots[4].parentElement.parentElement.title = "cobblestone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diorite"
            craftingSlots[4].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "anvil":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "bamboo_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bamboo
            craftingSlots[1].src = all.bamboo
            craftingSlots[2].src = all.bamboo
            craftingSlots[3].src = all.bamboo
            craftingSlots[4].src = all.bamboo
            craftingSlots[5].src = all.bamboo
            craftingSlots[6].src = all.bamboo
            craftingSlots[7].src = all.bamboo
            craftingSlots[8].src = all.bamboo
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bamboo"
            craftingSlots[1].parentElement.parentElement.title = "bamboo"
            craftingSlots[2].parentElement.parentElement.title = "bamboo"
            craftingSlots[3].parentElement.parentElement.title = "bamboo"
            craftingSlots[4].parentElement.parentElement.title = "bamboo"
            craftingSlots[5].parentElement.parentElement.title = "bamboo"
            craftingSlots[6].parentElement.parentElement.title = "bamboo"
            craftingSlots[7].parentElement.parentElement.title = "bamboo"
            craftingSlots[8].parentElement.parentElement.title = "bamboo"
            
            craftingSlots[0].parentElement.parentElement.alt = "bamboo"
            craftingSlots[1].parentElement.parentElement.alt = "bamboo"
            craftingSlots[2].parentElement.parentElement.alt = "bamboo"
            craftingSlots[3].parentElement.parentElement.alt = "bamboo"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo"
            craftingSlots[5].parentElement.parentElement.alt = "bamboo"
            craftingSlots[6].parentElement.parentElement.alt = "bamboo"
            craftingSlots[7].parentElement.parentElement.alt = "bamboo"
            craftingSlots[8].parentElement.parentElement.alt = "bamboo"
            break;
            
        case "bamboo_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.bamboo_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bamboo_chest_raft":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.bamboo_raft
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "bamboo raft"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo raft"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bamboo_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.bamboo_blocks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "bamboo blocks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "bamboo blocks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "bamboo_trapdoor":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "beetroot_soup":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.bowl
            craftingSlots[1].src = all.beetroot
            craftingSlots[2].src = all.beetroot
            craftingSlots[3].src = all.beetroot
            craftingSlots[4].src = all.beetroot
            craftingSlots[5].src = all.beetroot
            craftingSlots[6].src = all.beetroot
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "bowl"
            craftingSlots[1].parentElement.parentElement.title = "beetroot"
            craftingSlots[2].parentElement.parentElement.title = "beetroot"
            craftingSlots[3].parentElement.parentElement.title = "beetroot"
            craftingSlots[4].parentElement.parentElement.title = "beetroot"
            craftingSlots[5].parentElement.parentElement.title = "beetroot"
            craftingSlots[6].parentElement.parentElement.title = "beetroot"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "bowl"
            craftingSlots[1].parentElement.parentElement.alt = "beetroot"
            craftingSlots[2].parentElement.parentElement.alt = "beetroot"
            craftingSlots[3].parentElement.parentElement.alt = "beetroot"
            craftingSlots[4].parentElement.parentElement.alt = "beetroot"
            craftingSlots[5].parentElement.parentElement.alt = "beetroot"
            craftingSlots[6].parentElement.parentElement.alt = "beetroot"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "birch_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.birch_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "birch planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "birch planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "birch_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.birch_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "birch boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "birch boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "birch_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.birch_logs
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "birch logs"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "birch logs"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.black_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "black dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "black dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.black_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "black dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "black dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "black_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.ink_sac
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "ink sac"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "ink sac"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.wither_rose
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "wither rose"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "wither rose"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blaze_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.blaze_rod
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "blaze rod"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "blaze rod"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.blue_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "blue dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.blue_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "blue dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "blue dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "blue_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.lapis_lazuli
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "lapis lazuli"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "lapis lazuli"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.cornflower
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "cornflower"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "cornflower"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_ice":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.packed_ice
            craftingSlots[1].src = all.packed_ice
            craftingSlots[2].src = all.packed_ice
            craftingSlots[3].src = all.packed_ice
            craftingSlots[4].src = all.packed_ice
            craftingSlots[5].src = all.packed_ice
            craftingSlots[6].src = all.packed_ice
            craftingSlots[7].src = all.packed_ice
            craftingSlots[8].src = all.packed_ice
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "packed ice"
            craftingSlots[1].parentElement.parentElement.title = "packed ice"
            craftingSlots[2].parentElement.parentElement.title = "packed ice"
            craftingSlots[3].parentElement.parentElement.title = "packed ice"
            craftingSlots[4].parentElement.parentElement.title = "packed ice"
            craftingSlots[5].parentElement.parentElement.title = "packed ice"
            craftingSlots[6].parentElement.parentElement.title = "packed ice"
            craftingSlots[7].parentElement.parentElement.title = "packed ice"
            craftingSlots[8].parentElement.parentElement.title = "packed ice"
            
            craftingSlots[0].parentElement.parentElement.alt = "packed ice"
            craftingSlots[1].parentElement.parentElement.alt = "packed ice"
            craftingSlots[2].parentElement.parentElement.alt = "packed ice"
            craftingSlots[3].parentElement.parentElement.alt = "packed ice"
            craftingSlots[4].parentElement.parentElement.alt = "packed ice"
            craftingSlots[5].parentElement.parentElement.alt = "packed ice"
            craftingSlots[6].parentElement.parentElement.alt = "packed ice"
            craftingSlots[7].parentElement.parentElement.alt = "packed ice"
            craftingSlots[8].parentElement.parentElement.alt = "packed ice"
            break;
            
                case "bolt_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "bone_meal":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.bone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "bone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "bone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "bone_meal":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.bone_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "bone block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "bone block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "book":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.paper
            craftingSlots[1].src = all.paper
            craftingSlots[2].src = all.paper
            craftingSlots[3].src = all.leather
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "paper"
            craftingSlots[1].parentElement.parentElement.title = "paper"
            craftingSlots[2].parentElement.parentElement.title = "paper"
            craftingSlots[3].parentElement.parentElement.title = "leather"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "paper"
            craftingSlots[1].parentElement.parentElement.alt = "paper"
            craftingSlots[2].parentElement.parentElement.alt = "paper"
            craftingSlots[3].parentElement.parentElement.alt = "leather"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
                    
        case "brown_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.brown_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "brown dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "brown dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "brown_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.brown_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "brown dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "brown dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "brown_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.cocoa_beans
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "cocoa beans"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "cocoa beans"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cherry_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.cherry_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "cherry planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "cherry planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cherry_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.cherry_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "cherry boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "cherry boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cherry_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.cherry_logs
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "cherry logs"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "cherry logs"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "chest_minecart":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.minecart
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "minecart"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "minecart"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "coal":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.coal_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "coal block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "coal block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "coast_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "copper_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.copper_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "copper block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "copper block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "copper_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.waxed_copper_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "waxed copper block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "waxed copper block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "creeper_banner_pattern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.paper
            craftingSlots[4].src = all.creeper_head
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "paper"
            craftingSlots[4].parentElement.parentElement.title = "creeper head"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "paper"
            craftingSlots[4].parentElement.parentElement.alt = "creeper head"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "crimson_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.crimson_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "crimson planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "crimson planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "crimson_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.crimson_stems
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "crimson stems"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "crimson stems"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.cyan_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "cyan dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.cyan_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "cyan dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "cyan_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_dye
            craftingSlots[4].src = all.green_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue dye"
            craftingSlots[4].parentElement.parentElement.title = "green dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "green dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.pitcher_plant
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "pitcher plant"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "pitcher plant"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dark_oak_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.dark_oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dark_oak_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.dark_oak_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "dark oak boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "dark_oak_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.dark_oak_logs
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "dark oak logs"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "dark oak logs"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "daylight_detector":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "diamond":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.diamond_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "diamond block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "diamond block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        // case "dried_kelp":
        //     clickedItem = document.getElementById(itemName)
        //     resetCraftingItems()
        //     showCorrectCraftingMethod("crafting")
        //     craftingSlots[0].src = all.empty
        //     craftingSlots[1].src = all.empty
        //     craftingSlots[2].src = all.empty
        //     craftingSlots[3].src = all.empty
        //     craftingSlots[4].src = all.dried_kelp_block
        //     craftingSlots[5].src = all.empty
        //     craftingSlots[6].src = all.empty
        //     craftingSlots[7].src = all.empty
        //     craftingSlots[8].src = all.empty
        //     craftedItem.src = clickedItem.src
        //     craftedItem.title = clickedItem.title
        //     craftedItem.alt = clickedItem.alt
        //     craftingSlots[0].parentElement.parentElement.title = "empty"
        //     craftingSlots[1].parentElement.parentElement.title = "empty"
        //     craftingSlots[2].parentElement.parentElement.title = "empty"
        //     craftingSlots[3].parentElement.parentElement.title = "empty"
        //     craftingSlots[4].parentElement.parentElement.title = "dried kelp block"
        //     craftingSlots[5].parentElement.parentElement.title = "empty"
        //     craftingSlots[6].parentElement.parentElement.title = "empty"
        //     craftingSlots[7].parentElement.parentElement.title = "empty"
        //     craftingSlots[8].parentElement.parentElement.title = "empty"
            
        //     craftingSlots[0].parentElement.parentElement.alt = "empty"
        //     craftingSlots[1].parentElement.parentElement.alt = "empty"
        //     craftingSlots[2].parentElement.parentElement.alt = "empty"
        //     craftingSlots[3].parentElement.parentElement.alt = "empty"
        //     craftingSlots[4].parentElement.parentElement.alt = "dried kelp block"
        //     craftingSlots[5].parentElement.parentElement.alt = "empty"
        //     craftingSlots[6].parentElement.parentElement.alt = "empty"
        //     craftingSlots[7].parentElement.parentElement.alt = "empty"
        //     craftingSlots[8].parentElement.parentElement.alt = "empty"
        //     break;
            
                case "dune_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "black_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.black_dye
            craftingSlots[4].src = all.blue_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "black dye"
            craftingSlots[4].parentElement.parentElement.title = "blue bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "black dye"
            craftingSlots[4].parentElement.parentElement.alt = "blue bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.black_dye
            craftingSlots[4].src = all.blue_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "black dye"
            craftingSlots[4].parentElement.parentElement.title = "blue carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "black dye"
            craftingSlots[4].parentElement.parentElement.alt = "blue carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "black_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.black_dye
            craftingSlots[4].src = all.blue_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "black dye"
            craftingSlots[4].parentElement.parentElement.title = "blue wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "black dye"
            craftingSlots[4].parentElement.parentElement.alt = "blue wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "blue_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "brown_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brown_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brown dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brown dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "brown_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brown_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brown dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brown dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "brown_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.brown_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "brown dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "brown dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cyan_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cyan dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cyan_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cyan dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "cyan_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cyan_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cyan dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cyan dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gray_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gray_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gray dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gray dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gray_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gray_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gray dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gray dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gray_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gray_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gray dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gray dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "green_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.green_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "green dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "green dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "green_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.green_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "green dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "green dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "green_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.green_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "green dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "green dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_blue_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light blue dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_blue_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light blue dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_blue_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light blue dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_gray_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light gray dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_gray_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light gray dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.light_gray_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "light gray dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.lime_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "lime dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "lime dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.lime_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "lime dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "lime dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.lime_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "lime dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "lime dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.magenta_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "magenta dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.magenta_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "magenta dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.magenta_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "magenta dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.orange_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "orange dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "orange dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.orange_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "orange dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "orange dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.orange_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "orange dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "orange dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.pink_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "pink dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "pink dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.pink_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "pink dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "pink dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.pink_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "pink dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "pink dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purple_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purple_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purple dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purple dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purple_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purple_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purple dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purple dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purple_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purple_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purple dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purple dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.white_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "white dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "white dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.white_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "white dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "white dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.white_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "white dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "white dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_bed":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.yellow_dye
            craftingSlots[4].src = all.black_bed
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "yellow dye"
            craftingSlots[4].parentElement.parentElement.title = "black bed"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[4].parentElement.parentElement.alt = "black bed"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_carpet":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.yellow_dye
            craftingSlots[4].src = all.black_carpet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "yellow dye"
            craftingSlots[4].parentElement.parentElement.title = "black carpet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[4].parentElement.parentElement.alt = "black carpet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_wool":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.yellow_dye
            craftingSlots[4].src = all.black_wool
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "yellow dye"
            craftingSlots[4].parentElement.parentElement.title = "black wool"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[4].parentElement.parentElement.alt = "black wool"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "emerald":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.emerald_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "emerald block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "emerald block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "ender_eye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.ender_pearl
            craftingSlots[4].src = all.blaze_powder
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "ender pearl"
            craftingSlots[4].parentElement.parentElement.title = "blaze powder"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "ender pearl"
            craftingSlots[4].parentElement.parentElement.alt = "blaze powder"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "eye_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "fermented_spider_eye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.spider_eye
            craftingSlots[1].src = all.brown_mushroom
            craftingSlots[2].src = all.sugar
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "spider eye"
            craftingSlots[1].parentElement.parentElement.title = "brown mushroom"
            craftingSlots[2].parentElement.parentElement.title = "sugar"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "spider eye"
            craftingSlots[1].parentElement.parentElement.alt = "brown mushroom"
            craftingSlots[2].parentElement.parentElement.alt = "sugar"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "fire_charge":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gunpowder
            craftingSlots[1].src = all.blaze_powder
            craftingSlots[2].src = all.coal
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gunpowder"
            craftingSlots[1].parentElement.parentElement.title = "blaze powder"
            craftingSlots[2].parentElement.parentElement.title = "coal"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "gunpowder"
            craftingSlots[1].parentElement.parentElement.alt = "blaze powder"
            craftingSlots[2].parentElement.parentElement.alt = "coal"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "fire_charge":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "firework_rocket":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gunpowder
            craftingSlots[4].src = all.paper
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gunpowder"
            craftingSlots[4].parentElement.parentElement.title = "paper"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gunpowder"
            craftingSlots[4].parentElement.parentElement.alt = "paper"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "firework_rocket":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "firework_rocket":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "flint_and_steel":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.iron_ingot
            craftingSlots[4].src = all.flint
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "iron ingot"
            craftingSlots[4].parentElement.parentElement.title = "flint"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[4].parentElement.parentElement.alt = "flint"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "flow_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "flower_banner_pattern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.paper
            craftingSlots[4].src = all.oxeye_daisy
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "paper"
            craftingSlots[4].parentElement.parentElement.title = "oxeye daisy"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "paper"
            craftingSlots[4].parentElement.parentElement.alt = "oxeye daisy"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "furnace_minecart":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.furnace
            craftingSlots[4].src = all.minecart
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "furnace"
            craftingSlots[4].parentElement.parentElement.title = "minecart"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "furnace"
            craftingSlots[4].parentElement.parentElement.alt = "minecart"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "glow_item_frame":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.item_frame
            craftingSlots[4].src = all.glow_ink_sac
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "item frame"
            craftingSlots[4].parentElement.parentElement.title = "glow ink sac"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "item frame"
            craftingSlots[4].parentElement.parentElement.alt = "glow ink sac"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gold_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.gold_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "gold block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "gold block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gold_nugget":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.gold_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "gold ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "granite":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.diorite
            craftingSlots[4].src = all.quartz
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "diorite"
            craftingSlots[4].parentElement.parentElement.title = "quartz"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "diorite"
            craftingSlots[4].parentElement.parentElement.alt = "quartz"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gray_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.gray_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "gray dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "gray_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.gray_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "gray dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "gray dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "gray_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.black_dye
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "black dye"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "black dye"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "green_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.green_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "green dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "green dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "green_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.green_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "green dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "green dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "hay_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.wheat
            craftingSlots[1].src = all.wheat
            craftingSlots[2].src = all.wheat
            craftingSlots[3].src = all.wheat
            craftingSlots[4].src = all.wheat
            craftingSlots[5].src = all.wheat
            craftingSlots[6].src = all.wheat
            craftingSlots[7].src = all.wheat
            craftingSlots[8].src = all.wheat
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "wheat"
            craftingSlots[1].parentElement.parentElement.title = "wheat"
            craftingSlots[2].parentElement.parentElement.title = "wheat"
            craftingSlots[3].parentElement.parentElement.title = "wheat"
            craftingSlots[4].parentElement.parentElement.title = "wheat"
            craftingSlots[5].parentElement.parentElement.title = "wheat"
            craftingSlots[6].parentElement.parentElement.title = "wheat"
            craftingSlots[7].parentElement.parentElement.title = "wheat"
            craftingSlots[8].parentElement.parentElement.title = "wheat"
            
            craftingSlots[0].parentElement.parentElement.alt = "wheat"
            craftingSlots[1].parentElement.parentElement.alt = "wheat"
            craftingSlots[2].parentElement.parentElement.alt = "wheat"
            craftingSlots[3].parentElement.parentElement.alt = "wheat"
            craftingSlots[4].parentElement.parentElement.alt = "wheat"
            craftingSlots[5].parentElement.parentElement.alt = "wheat"
            craftingSlots[6].parentElement.parentElement.alt = "wheat"
            craftingSlots[7].parentElement.parentElement.alt = "wheat"
            craftingSlots[8].parentElement.parentElement.alt = "wheat"
            break;
            
        case "honey_bottle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.honey_block
            craftingSlots[1].src = all.glass_bottle
            craftingSlots[2].src = all.glass_bottle
            craftingSlots[3].src = all.glass_bottle
            craftingSlots[4].src = all.glass_bottle
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "honey block"
            craftingSlots[1].parentElement.parentElement.title = "glass bottle"
            craftingSlots[2].parentElement.parentElement.title = "glass bottle"
            craftingSlots[3].parentElement.parentElement.title = "glass bottle"
            craftingSlots[4].parentElement.parentElement.title = "glass bottle"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "honey block"
            craftingSlots[1].parentElement.parentElement.alt = "glass bottle"
            craftingSlots[2].parentElement.parentElement.alt = "glass bottle"
            craftingSlots[3].parentElement.parentElement.alt = "glass bottle"
            craftingSlots[4].parentElement.parentElement.alt = "glass bottle"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "hopper_minecart":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.hopper
            craftingSlots[4].src = all.minecart
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "hopper"
            craftingSlots[4].parentElement.parentElement.title = "minecart"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "hopper"
            craftingSlots[4].parentElement.parentElement.alt = "minecart"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "host_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "iron_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.iron_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "iron block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "iron block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "iron_nugget":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.iron_ingot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "iron ingot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "iron ingot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "jungle_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.jungle_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "jungle planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "jungle planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "jungle_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.jungle_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "jungle boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "jungle boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "jungle_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.jungle_logs
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "jungle logs"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "jungle logs"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lapis_lazuli":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.lapis_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "lapis block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "lapis block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.light_blue_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "light blue dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.light_blue_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "light blue dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "light blue dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "light_blue_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.blue_orchid
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "blue orchid"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "blue orchid"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_blue_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_dye
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue dye"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.light_gray_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "light gray dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.light_gray_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "light gray dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "light gray dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "light_gray_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.azure_bluet
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "azure bluet"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "azure bluet"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.black_dye
            craftingSlots[1].src = all.white_dye
            craftingSlots[2].src = all.white_dye
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "black dye"
            craftingSlots[1].parentElement.parentElement.title = "white dye"
            craftingSlots[2].parentElement.parentElement.title = "white dye"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "black dye"
            craftingSlots[1].parentElement.parentElement.alt = "white dye"
            craftingSlots[2].parentElement.parentElement.alt = "white dye"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.gray_dye
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "gray dye"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "gray dye"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.oxeye_daisy
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "oxeye daisy"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "oxeye daisy"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "light_gray_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.white_tulip
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "white tulip"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "white tulip"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.lime_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "lime dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "lime dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "lime_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.lime_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "lime dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "lime dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "lime_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.green_dye
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "green dye"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "green dye"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.magenta_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "magenta dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.magenta_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "magenta dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "magenta dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "magenta_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.allium
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "allium"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "allium"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.blue_dye
            craftingSlots[1].src = all.red_dye
            craftingSlots[2].src = all.pink_dye
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "blue dye"
            craftingSlots[1].parentElement.parentElement.title = "red dye"
            craftingSlots[2].parentElement.parentElement.title = "pink dye"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "blue dye"
            craftingSlots[1].parentElement.parentElement.alt = "red dye"
            craftingSlots[2].parentElement.parentElement.alt = "pink dye"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.blue_dye
            craftingSlots[1].src = all.red_dye
            craftingSlots[2].src = all.red_dye
            craftingSlots[3].src = all.white_dye
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "blue dye"
            craftingSlots[1].parentElement.parentElement.title = "red dye"
            craftingSlots[2].parentElement.parentElement.title = "red dye"
            craftingSlots[3].parentElement.parentElement.title = "white dye"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "blue dye"
            craftingSlots[1].parentElement.parentElement.alt = "red dye"
            craftingSlots[2].parentElement.parentElement.alt = "red dye"
            craftingSlots[3].parentElement.parentElement.alt = "white dye"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.lilac
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "lilac"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "lilac"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magenta_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.purple_dye
            craftingSlots[4].src = all.pink_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "purple dye"
            craftingSlots[4].parentElement.parentElement.title = "pink dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "purple dye"
            craftingSlots[4].parentElement.parentElement.alt = "pink dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "magma_cream":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blaze_powder
            craftingSlots[4].src = all.slime_ball
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blaze powder"
            craftingSlots[4].parentElement.parentElement.title = "slime ball"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blaze powder"
            craftingSlots[4].parentElement.parentElement.alt = "slime ball"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mangrove_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.mangrove_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mangrove_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.mangrove_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "mangrove boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mangrove_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.mangrove_logs
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "mangrove logs"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove logs"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "map":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "map":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "melon":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.melon_slice
            craftingSlots[1].src = all.melon_slice
            craftingSlots[2].src = all.melon_slice
            craftingSlots[3].src = all.melon_slice
            craftingSlots[4].src = all.melon_slice
            craftingSlots[5].src = all.melon_slice
            craftingSlots[6].src = all.melon_slice
            craftingSlots[7].src = all.melon_slice
            craftingSlots[8].src = all.melon_slice
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "melon slice"
            craftingSlots[1].parentElement.parentElement.title = "melon slice"
            craftingSlots[2].parentElement.parentElement.title = "melon slice"
            craftingSlots[3].parentElement.parentElement.title = "melon slice"
            craftingSlots[4].parentElement.parentElement.title = "melon slice"
            craftingSlots[5].parentElement.parentElement.title = "melon slice"
            craftingSlots[6].parentElement.parentElement.title = "melon slice"
            craftingSlots[7].parentElement.parentElement.title = "melon slice"
            craftingSlots[8].parentElement.parentElement.title = "melon slice"
            
            craftingSlots[0].parentElement.parentElement.alt = "melon slice"
            craftingSlots[1].parentElement.parentElement.alt = "melon slice"
            craftingSlots[2].parentElement.parentElement.alt = "melon slice"
            craftingSlots[3].parentElement.parentElement.alt = "melon slice"
            craftingSlots[4].parentElement.parentElement.alt = "melon slice"
            craftingSlots[5].parentElement.parentElement.alt = "melon slice"
            craftingSlots[6].parentElement.parentElement.alt = "melon slice"
            craftingSlots[7].parentElement.parentElement.alt = "melon slice"
            craftingSlots[8].parentElement.parentElement.alt = "melon slice"
            break;
            
        case "melon_seeds":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.melon_slice
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "melon slice"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "melon slice"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mojang_banner_pattern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.paper
            craftingSlots[4].src = all.enchanted_golden_apple
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "paper"
            craftingSlots[4].parentElement.parentElement.title = "enchanted golden apple"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "paper"
            craftingSlots[4].parentElement.parentElement.alt = "enchanted golden apple"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mossy_cobblestone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.moss_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "moss block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "moss block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mossy_cobblestone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cobblestone
            craftingSlots[4].src = all.vine
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cobblestone"
            craftingSlots[4].parentElement.parentElement.title = "vine"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cobblestone"
            craftingSlots[4].parentElement.parentElement.alt = "vine"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mossy_stone_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stone_bricks
            craftingSlots[4].src = all.moss_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "moss block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "moss block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mossy_stone_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.stone_bricks
            craftingSlots[4].src = all.vine
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "stone bricks"
            craftingSlots[4].parentElement.parentElement.title = "vine"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "stone bricks"
            craftingSlots[4].parentElement.parentElement.alt = "vine"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "muddy_mangrove_roots":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mud
            craftingSlots[4].src = all.mangrove_roots
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mud"
            craftingSlots[4].parentElement.parentElement.title = "mangrove roots"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mud"
            craftingSlots[4].parentElement.parentElement.alt = "mangrove roots"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "mushroom_stew":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.brown_mushroom
            craftingSlots[1].src = all.red_mushroom
            craftingSlots[2].src = all.bowl
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "brown mushroom"
            craftingSlots[1].parentElement.parentElement.title = "red mushroom"
            craftingSlots[2].parentElement.parentElement.title = "bowl"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "brown mushroom"
            craftingSlots[1].parentElement.parentElement.alt = "red mushroom"
            craftingSlots[2].parentElement.parentElement.alt = "bowl"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "music_disc_5":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.disc_fragment_5
            craftingSlots[1].src = all.disc_fragment_5
            craftingSlots[2].src = all.disc_fragment_5
            craftingSlots[3].src = all.disc_fragment_5
            craftingSlots[4].src = all.disc_fragment_5
            craftingSlots[5].src = all.disc_fragment_5
            craftingSlots[6].src = all.disc_fragment_5
            craftingSlots[7].src = all.disc_fragment_5
            craftingSlots[8].src = all.disc_fragment_5
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[1].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[2].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[3].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[4].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[5].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[6].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[7].parentElement.parentElement.title = "disc fragment 5"
            craftingSlots[8].parentElement.parentElement.title = "disc fragment 5"
            
            craftingSlots[0].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[1].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[2].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[3].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[4].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[5].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[6].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[7].parentElement.parentElement.alt = "disc fragment 5"
            craftingSlots[8].parentElement.parentElement.alt = "disc fragment 5"
            break;
            
        case "nether_wart_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.nether_wart
            craftingSlots[1].src = all.nether_wart
            craftingSlots[2].src = all.nether_wart
            craftingSlots[3].src = all.nether_wart
            craftingSlots[4].src = all.nether_wart
            craftingSlots[5].src = all.nether_wart
            craftingSlots[6].src = all.nether_wart
            craftingSlots[7].src = all.nether_wart
            craftingSlots[8].src = all.nether_wart
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "nether wart"
            craftingSlots[1].parentElement.parentElement.title = "nether wart"
            craftingSlots[2].parentElement.parentElement.title = "nether wart"
            craftingSlots[3].parentElement.parentElement.title = "nether wart"
            craftingSlots[4].parentElement.parentElement.title = "nether wart"
            craftingSlots[5].parentElement.parentElement.title = "nether wart"
            craftingSlots[6].parentElement.parentElement.title = "nether wart"
            craftingSlots[7].parentElement.parentElement.title = "nether wart"
            craftingSlots[8].parentElement.parentElement.title = "nether wart"
            
            craftingSlots[0].parentElement.parentElement.alt = "nether wart"
            craftingSlots[1].parentElement.parentElement.alt = "nether wart"
            craftingSlots[2].parentElement.parentElement.alt = "nether wart"
            craftingSlots[3].parentElement.parentElement.alt = "nether wart"
            craftingSlots[4].parentElement.parentElement.alt = "nether wart"
            craftingSlots[5].parentElement.parentElement.alt = "nether wart"
            craftingSlots[6].parentElement.parentElement.alt = "nether wart"
            craftingSlots[7].parentElement.parentElement.alt = "nether wart"
            craftingSlots[8].parentElement.parentElement.alt = "nether wart"
            break;
            
        case "netherite_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.netherite_scrap
            craftingSlots[1].src = all.netherite_scrap
            craftingSlots[2].src = all.netherite_scrap
            craftingSlots[3].src = all.netherite_scrap
            craftingSlots[4].src = all.gold_ingot
            craftingSlots[5].src = all.gold_ingot
            craftingSlots[6].src = all.gold_ingot
            craftingSlots[7].src = all.gold_ingot
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "netherite scrap"
            craftingSlots[1].parentElement.parentElement.title = "netherite scrap"
            craftingSlots[2].parentElement.parentElement.title = "netherite scrap"
            craftingSlots[3].parentElement.parentElement.title = "netherite scrap"
            craftingSlots[4].parentElement.parentElement.title = "gold ingot"
            craftingSlots[5].parentElement.parentElement.title = "gold ingot"
            craftingSlots[6].parentElement.parentElement.title = "gold ingot"
            craftingSlots[7].parentElement.parentElement.title = "gold ingot"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "netherite scrap"
            craftingSlots[1].parentElement.parentElement.alt = "netherite scrap"
            craftingSlots[2].parentElement.parentElement.alt = "netherite scrap"
            craftingSlots[3].parentElement.parentElement.alt = "netherite scrap"
            craftingSlots[4].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[5].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[6].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[7].parentElement.parentElement.alt = "gold ingot"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "netherite_ingot":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.netherite_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "netherite block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "netherite block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oak_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.oak_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "oak planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "oak planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oak_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.oak_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "oak boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "oak boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "oak_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.oak_log
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "oak log"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "oak log"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.orange_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "orange dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "orange dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.orange_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "orange dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "orange dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "orange_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.orange_tulip
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "orange tulip"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "orange tulip"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_dye
            craftingSlots[4].src = all.yellow_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red dye"
            craftingSlots[4].parentElement.parentElement.title = "yellow dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red dye"
            craftingSlots[4].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "orange_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.torchflower
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "torchflower"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "torchflower"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "packed_ice":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.ice
            craftingSlots[1].src = all.ice
            craftingSlots[2].src = all.ice
            craftingSlots[3].src = all.ice
            craftingSlots[4].src = all.ice
            craftingSlots[5].src = all.ice
            craftingSlots[6].src = all.ice
            craftingSlots[7].src = all.ice
            craftingSlots[8].src = all.ice
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "ice"
            craftingSlots[1].parentElement.parentElement.title = "ice"
            craftingSlots[2].parentElement.parentElement.title = "ice"
            craftingSlots[3].parentElement.parentElement.title = "ice"
            craftingSlots[4].parentElement.parentElement.title = "ice"
            craftingSlots[5].parentElement.parentElement.title = "ice"
            craftingSlots[6].parentElement.parentElement.title = "ice"
            craftingSlots[7].parentElement.parentElement.title = "ice"
            craftingSlots[8].parentElement.parentElement.title = "ice"
            
            craftingSlots[0].parentElement.parentElement.alt = "ice"
            craftingSlots[1].parentElement.parentElement.alt = "ice"
            craftingSlots[2].parentElement.parentElement.alt = "ice"
            craftingSlots[3].parentElement.parentElement.alt = "ice"
            craftingSlots[4].parentElement.parentElement.alt = "ice"
            craftingSlots[5].parentElement.parentElement.alt = "ice"
            craftingSlots[6].parentElement.parentElement.alt = "ice"
            craftingSlots[7].parentElement.parentElement.alt = "ice"
            craftingSlots[8].parentElement.parentElement.alt = "ice"
            break;
            
        case "packed_mud":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.mud
            craftingSlots[4].src = all.wheat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "mud"
            craftingSlots[4].parentElement.parentElement.title = "wheat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "mud"
            craftingSlots[4].parentElement.parentElement.alt = "wheat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.pink_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "pink dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "pink dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.pink_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "pink dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "pink dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "pink_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.peony
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "peony"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "peony"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.pink_petals
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "pink petals"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "pink petals"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.pink_tulip
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "pink tulip"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "pink tulip"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pink_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.red_dye
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "red dye"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "red dye"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "polished_blackstone_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.polished_blackstone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "polished blackstone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "prismarine_bricks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.prismarine_shard
            craftingSlots[1].src = all.prismarine_shard
            craftingSlots[2].src = all.prismarine_shard
            craftingSlots[3].src = all.prismarine_shard
            craftingSlots[4].src = all.prismarine_shard
            craftingSlots[5].src = all.prismarine_shard
            craftingSlots[6].src = all.prismarine_shard
            craftingSlots[7].src = all.prismarine_shard
            craftingSlots[8].src = all.prismarine_shard
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[1].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[2].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[3].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[4].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[5].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[6].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.title = "prismarine shard"
            craftingSlots[8].parentElement.parentElement.title = "prismarine shard"
            
            craftingSlots[0].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[1].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[2].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[3].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[4].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[5].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[6].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[7].parentElement.parentElement.alt = "prismarine shard"
            craftingSlots[8].parentElement.parentElement.alt = "prismarine shard"
            break;
            
        case "pumpkin_pie":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.pumpkin
            craftingSlots[1].src = all.sugar
            craftingSlots[2].src = all.egg
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "pumpkin"
            craftingSlots[1].parentElement.parentElement.title = "sugar"
            craftingSlots[2].parentElement.parentElement.title = "egg"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "pumpkin"
            craftingSlots[1].parentElement.parentElement.alt = "sugar"
            craftingSlots[2].parentElement.parentElement.alt = "egg"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "pumpkin_seeds":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.pumpkin
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "pumpkin"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "pumpkin"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purple_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.purple_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "purple dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "purple dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "purple_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.purple_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "purple dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "purple dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "purple_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.blue_dye
            craftingSlots[4].src = all.red_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "blue dye"
            craftingSlots[4].parentElement.parentElement.title = "red dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "blue dye"
            craftingSlots[4].parentElement.parentElement.alt = "red dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "rabbit_stew":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.baked_potato
            craftingSlots[1].src = all.cooked_rabbit
            craftingSlots[2].src = all.bowl
            craftingSlots[3].src = all.carrot
            craftingSlots[4].src = all.brown_mushroom
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "baked potato"
            craftingSlots[1].parentElement.parentElement.title = "cooked rabbit"
            craftingSlots[2].parentElement.parentElement.title = "bowl"
            craftingSlots[3].parentElement.parentElement.title = "carrot"
            craftingSlots[4].parentElement.parentElement.title = "brown mushroom"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "baked potato"
            craftingSlots[1].parentElement.parentElement.alt = "cooked rabbit"
            craftingSlots[2].parentElement.parentElement.alt = "bowl"
            craftingSlots[3].parentElement.parentElement.alt = "carrot"
            craftingSlots[4].parentElement.parentElement.alt = "brown mushroom"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "rabbit_stew":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.baked_potato
            craftingSlots[1].src = all.cooked_rabbit
            craftingSlots[2].src = all.bowl
            craftingSlots[3].src = all.carrot
            craftingSlots[4].src = all.red_mushroom
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "baked potato"
            craftingSlots[1].parentElement.parentElement.title = "cooked rabbit"
            craftingSlots[2].parentElement.parentElement.title = "bowl"
            craftingSlots[3].parentElement.parentElement.title = "carrot"
            craftingSlots[4].parentElement.parentElement.title = "red mushroom"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "baked potato"
            craftingSlots[1].parentElement.parentElement.alt = "cooked rabbit"
            craftingSlots[2].parentElement.parentElement.alt = "bowl"
            craftingSlots[3].parentElement.parentElement.alt = "carrot"
            craftingSlots[4].parentElement.parentElement.alt = "red mushroom"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "raiser_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "raw_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.raw_copper_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "raw copper block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "raw copper block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "raw_gold":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.raw_gold_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "raw gold block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "raw gold block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "raw_iron":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.raw_iron_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "raw iron block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "raw iron block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.red_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "red dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "red dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.red_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "red dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "red dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "red_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.beetroot
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "beetroot"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "beetroot"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.poppy
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "poppy"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "poppy"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.rose_bush
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "rose bush"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "rose bush"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "red_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.red_tulip
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "red tulip"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "red tulip"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "redstone":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.redstone_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "redstone block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "redstone block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "redstone_torch":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "rib_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "sentry_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "shaper_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "shield":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "shulker_box":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "silence_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "skull_banner_pattern":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.paper
            craftingSlots[4].src = all.wither_skeleton_skull
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "paper"
            craftingSlots[4].parentElement.parentElement.title = "wither skeleton skull"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "paper"
            craftingSlots[4].parentElement.parentElement.alt = "wither skeleton skull"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "slime_ball":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.slime_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "slime block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "slime block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "snout_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "spire_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "spruce_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.spruce_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "spruce planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "spruce planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spruce_chest_boat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.spruce_boat
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "spruce boat"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "spruce boat"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "spruce_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.spruce_logs
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "spruce logs"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "spruce logs"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "stone_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.stone
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "stone"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "stone"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "sugar":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.honey_bottle
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "honey bottle"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "honey bottle"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "sugar":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.sugar_cane
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "sugar cane"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "sugar cane"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "sugar":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "tide_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "tinted_glass":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "tnt_minecart":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.tnt
            craftingSlots[4].src = all.minecart
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "tnt"
            craftingSlots[4].parentElement.parentElement.title = "minecart"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "tnt"
            craftingSlots[4].parentElement.parentElement.alt = "minecart"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "trapped_chest":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chest
            craftingSlots[4].src = all.tripwire_hook
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chest"
            craftingSlots[4].parentElement.parentElement.title = "tripwire hook"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chest"
            craftingSlots[4].parentElement.parentElement.alt = "tripwire hook"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "vex_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
                case "ward_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "warped_button":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.warped_planks
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "warped planks"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "warped planks"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "warped_planks":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.warped_stems
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "warped stems"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "warped stems"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.chiseled_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "chiseled copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "chiseled copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_copper_block":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_block
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper block"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper block"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_bulb
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper bulb"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper bulb"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_copper_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_door
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper door"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper door"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_grate
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper grate"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper grate"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_copper_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.copper_trapdoor
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "copper trapdoor"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "copper trapdoor"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cut_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cut copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cut_copper_slab
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.cut_copper_stairs
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "cut copper stairs"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "cut copper stairs"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_chiseled_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed chiseled copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed chiseled copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper_bulb
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper bulb"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper bulb"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_copper_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper_door
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper door"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper door"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper_grate
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper grate"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper grate"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_copper_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_copper_trapdoor
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed copper trapdoor"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed copper trapdoor"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_cut_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed cut copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_cut_copper_slab
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_exposed_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.exposed_cut_copper_stairs
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "exposed cut copper stairs"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "exposed cut copper stairs"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_chiseled_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized chiseled copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized chiseled copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper_bulb
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper bulb"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper bulb"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_copper_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper_door
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper door"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper door"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper_grate
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper grate"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper grate"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_copper_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_copper_trapdoor
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized copper trapdoor"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized copper trapdoor"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_cut_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized cut copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_cut_copper_slab
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_oxidized_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.oxidized_cut_copper_stairs
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "oxidized cut copper stairs"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "oxidized cut copper stairs"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_chiseled_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_chiseled_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered chiseled copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered chiseled copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_copper_bulb":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper_bulb
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper bulb"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper bulb"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_copper_door":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper_door
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper door"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper door"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_copper_grate":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper_grate
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper grate"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper grate"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_copper_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_copper_trapdoor
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered copper trapdoor"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered copper trapdoor"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_cut_copper":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_cut_copper
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered cut copper"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered cut copper"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_cut_copper_slab":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_cut_copper_slab
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered cut copper slab"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered cut copper slab"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "waxed_weathered_cut_copper_stairs":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.weathered_cut_copper_stairs
            craftingSlots[4].src = all.honeycomb
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "weathered cut copper stairs"
            craftingSlots[4].parentElement.parentElement.title = "honeycomb"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "weathered cut copper stairs"
            craftingSlots[4].parentElement.parentElement.alt = "honeycomb"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "wayfinder_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "wheat":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.hay_block
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "hay block"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "hay block"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.white_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "white dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "white dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.white_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "white dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "white dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "white_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.bone_meal
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "bone meal"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "bone meal"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "white_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.lily_of_the_valley
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "lily of the valley"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "lily of the valley"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
                case "wild_armor_trim_smithing_template":
                    clickedItem = document.getElementById(itemName)
                    resetCraftingItems()
                    craftedItem.src = clickedItem.src
                    break;
                    
        case "wind_charge":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.breeze_rod
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "breeze rod"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "breeze rod"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "writable_book":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.book
            craftingSlots[1].src = all.ink_sac
            craftingSlots[2].src = all.feather
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.empty
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "book"
            craftingSlots[1].parentElement.parentElement.title = "ink sac"
            craftingSlots[2].parentElement.parentElement.title = "feather"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "empty"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "book"
            craftingSlots[1].parentElement.parentElement.alt = "ink sac"
            craftingSlots[2].parentElement.parentElement.alt = "feather"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "empty"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_candle":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.candle
            craftingSlots[4].src = all.yellow_dye
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "candle"
            craftingSlots[4].parentElement.parentElement.title = "yellow dye"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "candle"
            craftingSlots[4].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_concrete_powder":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.yellow_dye
            craftingSlots[1].src = all.sand
            craftingSlots[2].src = all.sand
            craftingSlots[3].src = all.sand
            craftingSlots[4].src = all.sand
            craftingSlots[5].src = all.gravel
            craftingSlots[6].src = all.gravel
            craftingSlots[7].src = all.gravel
            craftingSlots[8].src = all.gravel
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "yellow dye"
            craftingSlots[1].parentElement.parentElement.title = "sand"
            craftingSlots[2].parentElement.parentElement.title = "sand"
            craftingSlots[3].parentElement.parentElement.title = "sand"
            craftingSlots[4].parentElement.parentElement.title = "sand"
            craftingSlots[5].parentElement.parentElement.title = "gravel"
            craftingSlots[6].parentElement.parentElement.title = "gravel"
            craftingSlots[7].parentElement.parentElement.title = "gravel"
            craftingSlots[8].parentElement.parentElement.title = "gravel"
            
            craftingSlots[0].parentElement.parentElement.alt = "yellow dye"
            craftingSlots[1].parentElement.parentElement.alt = "sand"
            craftingSlots[2].parentElement.parentElement.alt = "sand"
            craftingSlots[3].parentElement.parentElement.alt = "sand"
            craftingSlots[4].parentElement.parentElement.alt = "sand"
            craftingSlots[5].parentElement.parentElement.alt = "gravel"
            craftingSlots[6].parentElement.parentElement.alt = "gravel"
            craftingSlots[7].parentElement.parentElement.alt = "gravel"
            craftingSlots[8].parentElement.parentElement.alt = "gravel"
            break;
            
        case "yellow_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.dandelion
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "dandelion"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "dandelion"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;
            
        case "yellow_dye":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            showCorrectCraftingMethod("crafting")
            craftingSlots[0].src = all.empty
            craftingSlots[1].src = all.empty
            craftingSlots[2].src = all.empty
            craftingSlots[3].src = all.empty
            craftingSlots[4].src = all.sunflower
            craftingSlots[5].src = all.empty
            craftingSlots[6].src = all.empty
            craftingSlots[7].src = all.empty
            craftingSlots[8].src = all.empty
            craftedItem.src = clickedItem.src
            craftedItem.title = clickedItem.title
            craftedItem.alt = clickedItem.alt
            craftingSlots[0].parentElement.parentElement.title = "empty"
            craftingSlots[1].parentElement.parentElement.title = "empty"
            craftingSlots[2].parentElement.parentElement.title = "empty"
            craftingSlots[3].parentElement.parentElement.title = "empty"
            craftingSlots[4].parentElement.parentElement.title = "sunflower"
            craftingSlots[5].parentElement.parentElement.title = "empty"
            craftingSlots[6].parentElement.parentElement.title = "empty"
            craftingSlots[7].parentElement.parentElement.title = "empty"
            craftingSlots[8].parentElement.parentElement.title = "empty"
            
            craftingSlots[0].parentElement.parentElement.alt = "empty"
            craftingSlots[1].parentElement.parentElement.alt = "empty"
            craftingSlots[2].parentElement.parentElement.alt = "empty"
            craftingSlots[3].parentElement.parentElement.alt = "empty"
            craftingSlots[4].parentElement.parentElement.alt = "sunflower"
            craftingSlots[5].parentElement.parentElement.alt = "empty"
            craftingSlots[6].parentElement.parentElement.alt = "empty"
            craftingSlots[7].parentElement.parentElement.alt = "empty"
            craftingSlots[8].parentElement.parentElement.alt = "empty"
            break;

        //#endregion Shapeless

        //#region Smelting

        case "anvil":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "baked_potato":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.potato
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "potato"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "potato"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "bamboo_trapdoor":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "black_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.black_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "black terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "black terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "blue_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.blue_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "blue terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "blue terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "bolt_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "brick":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.clay_ball
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "clay ball"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "clay ball"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "brown_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.brown_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "brown terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "brown terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "charcoal":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.logs_that_burn
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "logs that burn"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "logs that burn"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "coal":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.coal_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "coal ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "coal ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "coal":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_coal_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate coal ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate coal ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "coast_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "cooked_beef":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.beef
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "beef"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "beef"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cooked_chicken":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.chicken
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "chicken"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "chicken"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cooked_cod":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.cod
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "cod"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "cod"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cooked_mutton":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.mutton
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "mutton"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "mutton"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cooked_porkchop":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.porkchop
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "porkchop"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "porkchop"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cooked_rabbit":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.rabbit
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "rabbit"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "rabbit"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cooked_salmon":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.salmon
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "salmon"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "salmon"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "copper_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.copper_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "copper ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "copper ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "copper_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_copper_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate copper ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate copper ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "copper_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.raw_copper
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "raw copper"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "raw copper"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cracked_deepslate_bricks":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_bricks
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate bricks"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate bricks"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cracked_deepslate_tiles":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_tiles
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate tiles"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate tiles"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cracked_nether_bricks":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.nether_bricks
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "nether bricks"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "nether bricks"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cracked_polished_blackstone_bricks":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.polished_blackstone_bricks
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "polished blackstone bricks"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "polished blackstone bricks"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cracked_stone_bricks":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.stone_bricks
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "stone bricks"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "stone bricks"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "cyan_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.cyan_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "cyan terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "cyan terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "daylight_detector":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "deepslate":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.cobbled_deepslate
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "cobbled deepslate"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "cobbled deepslate"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "diamond":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_diamond_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate diamond ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate diamond ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "diamond":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.diamond_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "diamond ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "diamond ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "dried_kelp":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.kelp
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "kelp"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "kelp"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
    case "dune_armor_trim_smithing_template":
        clickedItem = document.getElementById(itemName)
        resetCraftingItems()
        craftedItem.src = clickedItem.src
        break;
            
case "emerald":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_emerald_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate emerald ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate emerald ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "emerald":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.emerald_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "emerald ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "emerald ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "eye_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "fire_charge":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "firework_rocket":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "firework_rocket":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "flow_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "glass":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.sand
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "smelts to glass"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "smelts to glass"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "gold_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_gold_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate gold ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate gold ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "gold_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.gold_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "gold ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "gold ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "gold_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.nether_gold_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "nether gold ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "nether gold ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "gold_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.raw_gold
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "raw gold"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "raw gold"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "gold_nugget":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.golden_pickaxe
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "golden pickaxe"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "golden pickaxe"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "gray_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.gray_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "gray terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "gray terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "green_dye":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.cactus
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "cactus"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "cactus"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "green_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.green_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "green terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "green terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "host_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "iron_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_iron_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate iron ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate iron ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "iron_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.iron_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "iron ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "iron ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "iron_ingot":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.raw_iron
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "raw iron"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "raw iron"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "iron_nugget":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.iron_pickaxe
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "iron pickaxe"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "iron pickaxe"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "lapis_lazuli":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_lapis_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate lapis ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate lapis ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "lapis_lazuli":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.lapis_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "lapis ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "lapis ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "light_blue_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.light_blue_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "light blue terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "light blue terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "light_gray_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.light_gray_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "light gray terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "light gray terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "lime_dye":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.sea_pickle
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "sea pickle"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "sea pickle"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "lime_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.lime_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "lime terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "lime terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "magenta_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.magenta_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "magenta terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "magenta terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "map":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "map":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "nether_brick":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.netherrack
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "netherrack"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "netherrack"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "netherite_scrap":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.ancient_debris
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "ancient debris"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "ancient debris"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "orange_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.orange_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "orange terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "orange terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "pink_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.pink_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "pink terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "pink terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "popped_chorus_fruit":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.chorus_fruit
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "chorus fruit"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "chorus fruit"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "purple_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.purple_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "purple terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "purple terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "quartz":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.nether_quartz_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "nether quartz ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "nether quartz ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "raiser_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "red_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.red_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "red terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "red terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "redstone":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.deepslate_redstone_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "deepslate redstone ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "deepslate redstone ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "redstone":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.redstone_ore
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "redstone ore"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "redstone ore"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "redstone_torch":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "rib_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "sentry_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "shaper_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "shield":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "shulker_box":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "silence_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "smooth_basalt":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.basalt
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "basalt"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "basalt"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "smooth_quartz":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.quartz_block
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "quartz block"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "quartz block"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "smooth_red_sandstone":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.red_sandstone
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "red sandstone"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "red sandstone"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "smooth_sandstone":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.sandstone
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "sandstone"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "sandstone"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "smooth_stone":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.stone
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "stone"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "stone"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "snout_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "spire_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "sponge":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.wet_sponge
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "wet sponge"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "wet sponge"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
case "stone":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.cobblestone
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "cobblestone"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "cobblestone"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "sugar":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.clay
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "clay"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "clay"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "tide_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "tinted_glass":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "vex_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "ward_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
        case "wayfinder_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "white_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.white_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "white terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "white terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;
    
        case "wild_armor_trim_smithing_template":
            clickedItem = document.getElementById(itemName)
            resetCraftingItems()
            craftedItem.src = clickedItem.src
            break;
            
case "yellow_glazed_terracotta":
    clickedItem = document.getElementById(itemName)
    resetCraftingItems()
    showCorrectCraftingMethod("smelt")
    furnaceSlots[0].src = all.yellow_terracotta
    furnaceSlots[1].src = all.coal
    craftedItem.src = clickedItem.src
    craftedItem.title = clickedItem.title
    craftedItem.alt = clickedItem.alt
    furnaceSlots[0].parentElement.parentElement.title = "yellow terracotta"
    furnaceSlots[1].parentElement.parentElement.title = "coal"

    furnaceSlots[0].parentElement.parentElement.alt = "yellow terracotta"
    furnaceSlots[1].parentElement.parentElement.alt = "coal"
    break;

        //#endregion Smelting

        default:
            break;
    }

}

function resetCraftingItems() {
    craftingSlots.forEach(slot =>
        slot.src = all.empty
    )
}

function showCorrectCraftingMethod(method, title = "How to get?", text = "") {
    let craftingRecipeGrid = document.querySelector('.crafting__show--container--grid');
    let craftingText = document.querySelector('.crafting__show--container--text')
    let furnaceGrid = document.querySelector('.crafting__show--container--smelt')

    switch (method) {
        case "crafting":
            craftingRecipeGrid.style.display = 'grid';
            craftingText = 'none'
            furnaceGrid.style.display = 'none'
            break;

        case "text":
            craftingRecipeGrid.style.display = 'none';
            craftingText.style.display = 'block'
            furnaceGrid.style.display = 'none'

            let craftingRecipeTextElement = document.getElementsByClassName('crafting__show--container--text')[0].children[1].innerHTML = text
            break;

        case "smelt":
            craftingRecipeGrid.style.display = 'none';
            craftingText.style.display = 'none'
            furnaceGrid.style.display = 'grid'
            break;

        default:
            break;
    }

}